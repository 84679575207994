<!-- Hero Start -->
<section class="bg-profile d-table w-100 bg-primary" id="home" style="background: url('assets/images/account/bg.png') center center;">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="card public-profile border-0 rounded shadow" style="z-index: 1;">
                    <div class="card-body">
                        <div class="row align-items-center">
                            <div class="col-lg-2 col-md-3 text-md-left text-center">
                                <img src="assets/images/user/user.png" class="avatar avatar-large rounded-circle shadow d-block mx-auto" alt="">
                            </div>
                            <!--end col-->

                            <div class="col-lg-10 col-md-9">
                                <div class="row align-items-end">
                                    <div class="col-md-7 text-md-left text-center mt-4 mt-sm-0">
                                        <h3 class="title mb-0">{{ name }}</h3>
                                        <small class="text-muted h6 mr-2">
                      {{ isadmin }} <span *ngIf="isadmin==1">,</span> {{ isgrower }} <span *ngIf="isbuyer==1">,</span>
                      {{ isbuyer }} <span *ngIf="isvendor==1">,</span> {{ isvendor }}
                    </small>
                                        <!-- <ul class="list-inline mb-0 mt-3">
                        <li class="list-inline-item mr-2"><a href="javascript:void(0)" class="text-muted"
                            title="Instagram">
                            <i-feather name="instagram" class="fea icon-sm mr-2"></i-feather>
                            krista_joseph
                          </a></li>
                        <li class="list-inline-item"><a href="javascript:void(0)" class="text-muted" title="Linkedin">
                            <i-feather name="linkedin" class="fea icon-sm mr-2"></i-feather>
                            Krista Joseph
                          </a></li>
                      </ul> -->
                                    </div>
                                    <!--end col-->
                                    <!-- <div class="col-md-5 text-md-right text-center">
                    <ul class="list-unstyled social-icon social mb-0 mt-4">
                      <li class="list-inline-item"><a href="javascript:void(0)" class="rounded" placement="bottom"
                          ngbTooltip="Add Friend">
                          <i-feather name="user-plus" class="fea icon-sm fea-social"></i-feather>
                        </a></li>
                      <li class="list-inline-item ml-1"><a href="javascript:void(0)" class="rounded" placement="bottom"
                          ngbTooltip="Messages">
                          <i-feather name="message-circle" class="fea icon-sm fea-social"></i-feather>
                        </a></li>
                      <li class="list-inline-item ml-1"><a href="javascript:void(0)" class="rounded" placement="bottom"
                          ngbTooltip="Notifications">
                          <i-feather name="bell" class="fea icon-sm fea-social"></i-feather>
                        </a></li>
                      <li class="list-inline-item ml-1"><a routerLink="/account-setting" class="rounded"
                          placement="bottom" ngbTooltip="Settings">
                          <i-feather name="tool" class="fea icon-sm fea-social"></i-feather>
                        </a></li>
                    </ul>
                   
                  </div> -->

                                </div>

                            </div>

                        </div>

                    </div>
                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row-->
    </div>
    <!--ed container-->
</section>
<!--end section-->
<!-- Hero End -->

<!-- Profile Start -->
<section class="section mt-60">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6 col-12 d-lg-block d-none">
                <div class="sidebar sticky-bar p-4 rounded shadow">
                    <!-- <div class="widget">
              <h5 class="widget-title">Followers :</h5>
              <div class="row mt-4">
                <div class="col-6 text-center">
                  <i-feather name="user-plus" class="fea icon-ex-md text-primary mb-1"></i-feather>
                  <h5 class="mb-0">2588</h5>
                  <p class="text-muted mb-0">Followers</p>
                </div>
                <div class="col-6 text-center">
                  <i-feather name="users" class="fea icon-ex-md text-primary mb-1"></i-feather>
                  <h5 class="mb-0">454</h5>
                  <p class="text-muted mb-0">Following</p>
                </div>
              </div>
            </div> -->

                    <!-- <div class="widget mt-4 pt-2">
              <h5 class="widget-title">Projects :</h5>
              <div class="progress-box mt-4">
                <h6 class="title text-muted">Progress</h6>
                <div class="progress">
                  <div class="progress-bar position-relative bg-primary" style="width:50%;">
                    <div class="progress-value d-block text-muted h6">24 / 48</div>
                  </div>
                </div>
              </div>
   
            </div> -->

                    <div class="widget">
                        <div class="row">
                            <div class="col-6 mt-4 pt-2">
                                <a routerLink="/account-profile" class="accounts active rounded d-block shadow text-center py-3">
                                    <span class="pro-icons h3 text-muted"><i class="uil uil-dashboard"></i></span>
                                    <h6 class="title text-dark h6 my-0">Profile</h6>
                                </a>
                            </div>
                            <!--end col-->

                            <div class="col-6 mt-4 pt-2">
                                <a routerLink="/account-members" class="accounts rounded d-block shadow text-center py-3">
                                    <span class="pro-icons h3 text-muted"><i class="uil uil-users-alt"></i></span>
                                    <h6 class="title text-dark h6 my-0">Buyer</h6>
                                </a>
                            </div>
                            <!--end col-->
                            <!-- 
                <div class="col-6 mt-4 pt-2">
                  <a routerLink="/account-works" class="accounts rounded d-block shadow text-center py-3">
                    <span class="pro-icons h3 text-muted"><i class="uil uil-file"></i></span>
                    <h6 class="title text-dark h6 my-0">Works</h6>
                  </a>
                </div> -->
                            <!--end col-->

                            <div class="col-6 mt-4 pt-2">
                                <a routerLink="/account-messages" class="accounts rounded d-block shadow text-center py-3">
                                    <span class="pro-icons h3 text-muted"><i class="uil uil-envelope-star"></i></span>
                                    <h6 class="title text-dark h6 my-0">Messages</h6>
                                </a>
                            </div>
                            <!--end col-->

                            <div class="col-6 mt-4 pt-2">
                                <a routerLink="/growersubscription" class="accounts rounded d-block shadow text-center py-3">
                                    <span class="pro-icons h3 text-muted"><i class="uil uil-transaction"></i></span>
                                    <h6 class="title text-dark h6 my-0">Subscription</h6>
                                </a>
                            </div>
                            <!--end col-->

                            <div class="col-6 mt-4 pt-2">
                                <a routerLink="/account-setting" class="accounts rounded d-block shadow text-center py-3">
                                    <span class="pro-icons h3 text-muted"><i class="uil uil-setting"></i></span>
                                    <h6 class="title text-dark h6 my-0">Settings</h6>
                                </a>
                            </div>
                            <!--end col-->

                            <div class="col-6 mt-4 pt-2">
                                <a routerLink="/auth-login-three" class="accounts rounded d-block shadow text-center py-3">
                                    <span class="pro-icons h3 text-muted"><i class="uil uil-sign-out-alt"></i></span>
                                    <h6 class="title text-dark h6 my-0">Logout</h6>
                                </a>
                            </div>
                            <!--end col-->
                        </div>
                        <!--end row-->
                    </div>

                    <!-- <div class="widget mt-4 pt-2">
              <h5 class="widget-title">Follow me :</h5>
              <ul class="list-unstyled social-icon mb-0 mt-4">
                <li class="list-inline-item"><a href="javascript:void(0)" class="rounded">
                    <i-feather name="facebook" class="fea icon-sm fea-social"></i-feather>
                  </a></li>
                <li class="list-inline-item ml-1"><a href="javascript:void(0)" class="rounded">
                    <i-feather name="instagram" class="fea icon-sm fea-social"></i-feather>
                  </a></li>
                <li class="list-inline-item ml-1"><a href="javascript:void(0)" class="rounded">
                    <i-feather name="twitter" class="fea icon-sm fea-social"></i-feather>
                  </a></li>
                <li class="list-inline-item ml-1"><a href="javascript:void(0)" class="rounded">
                    <i-feather name="linkedin" class="fea icon-sm fea-social"></i-feather>
                  </a></li>
                <li class="list-inline-item ml-1"><a href="javascript:void(0)" class="rounded">
                    <i-feather name="github" class="fea icon-sm fea-social"></i-feather>
                  </a></li>
                <li class="list-inline-item ml-1"><a href="javascript:void(0)" class="rounded">
                    <i-feather name="youtube" class="fea icon-sm fea-social"></i-feather>
                  </a></li>
                <li class="list-inline-item ml-1"><a href="javascript:void(0)" class="rounded">
                    <i-feather name="gitlab" class="fea icon-sm fea-social"></i-feather>
                  </a></li>
              </ul>
            </div> -->
                </div>
            </div>
            <!--end col-->

            <div class="col-lg-8 col-12">
                <div class="card border-0 rounded shadow">

                    <div class="border-bottom pb-4 card-body">
                        <h5 style="float: left;">Profile Details</h5>
                        <a (click)="isedit(isEdit)" style="float: right;" class="btn btn-primary btn-sm"> Edit Profile </a>

                    </div>
                    <!-- <div class="card-body" *ngIf="isEdit">
            

          </div> -->
                    <div class="card-body" *ngIf="isEdit">

                        <ul ngbNav #nav="ngbNav" [(activeId)]="active" (navChange)="tabChange($event)" class="nav-pills nav-justified">

                            <li [ngbNavItem]="0" [destroyOnHide]="false">
                                <a ngbNavLink class="rounded">
                                    <div class="text-center pt-1 pb-1">
                                        <h4 class="title font-weight-normal mb-0">Personal Detail</h4>
                                    </div>
                                </a>
                                <ng-template ngbNavContent>
                                    <form [formGroup]="registergrowerForm">
                                        <div class="mt-3 text-md-left text-center d-sm-flex">
                                            <img src="assets/images/client/05.jpg" class="avatar float-md-left avatar-medium rounded-circle shadow mr-md-4" alt="">

                                            <div class="mt-md-4 mt-3 mt-sm-0">
                                                <a href="javascript:void(0)" class="btn btn-primary mt-2">Change Picture</a>
                                                <!-- <a href="javascript:void(0)" class="btn btn-outline-primary mt-2 ml-2">Delete</a> -->
                                            </div>
                                        </div>


                                        <div class="row mt-4">
                                            <div class="col-md-6">
                                                <div class="form-group position-relative">
                                                    <label>Name</label>
                                                    <i-feather name="user" class="fea icon-sm icons"></i-feather>
                                                    <input type="text" class="form-control pl-5" placeholder="Name" formControlName="name" [ngClass]="{ 'is-invalid': submitted && f.name.errors }">
                                                    <div *ngIf="submitted && f.name.errors" class="invalid-feedback">
                                                        <div *ngIf="f.name.errors.required">Name is required</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <!--end col-->
                                            <div class="col-md-6">
                                                <div class="form-group position-relative">
                                                    <label>Company Name</label>
                                                    <i-feather name="user-check" class="fea icon-sm icons"></i-feather>
                                                    <input type="text" class="form-control pl-5" placeholder="Company name" formControlName="companyname">
                                                </div>
                                            </div>
                                            <!--end col-->

                                            <div class="col-md-6">
                                                <div class="form-group position-relative">
                                                    <label>State<span class="text-danger">*</span></label>
                                                    <select (change)="getsuburbs($event)" formControlName="stateid" class="form-control custom-select" [ngClass]="{ 'is-invalid': submitted && f.stateid.errors }">
                            <option value="">Select</option>
                            <option *ngFor="let item of statelist" value="{{ item.id }}">{{ item.statename }}</option>
                          </select>
                                                    <div *ngIf="submitted && f.stateid.errors" class="invalid-feedback">
                                                        <div *ngIf="f.stateid.errors.required">State is required</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="form-group position-relative">
                                                    <label>Suburb<span class="text-danger">*</span></label>
                                                    <select formControlName="suburbid" class="form-control custom-select" [ngClass]="{ 'is-invalid': submitted && f.suburbid.errors }">
                            <option value="">Select</option>
                            <option *ngFor="let item of suburbslist" value="{{ item.id }}">{{ item.suburbname }}
                            </option>
                          </select>
                                                    <div *ngIf="submitted && f.suburbid.errors" class="invalid-feedback">
                                                        <div *ngIf="f.suburbid.errors.required">Suburb is required</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="form-group position-relative">
                                                    <label>Post code<span class="text-danger">*</span></label>
                                                    <input type="text" class="form-control" placeholder="Post code" formControlName="postcode" [ngClass]="{ 'is-invalid': submitted && f.postcode.errors }">
                                                    <div *ngIf="submitted && f.postcode.errors" class="invalid-feedback">
                                                        <div *ngIf="f.postcode.errors.required">Post code is required</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="form-group position-relative">
                                                    <label>Email <span class="text-danger">*</span></label>
                                                    <i-feather name="mail" class="fea icon-sm icons"></i-feather>
                                                    <input type="email" class="form-control pl-5" placeholder="Email" formControlName="email" [ngClass]="{ 'is-invalid': submitted && f.email.errors }">
                                                    <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                                                        <div *ngIf="f.email.errors.required">Email is required</div>
                                                        <div *ngIf="f.email.errors.email">Email must be a valid email address</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="form-group position-relative">
                                                    <label>Password <span class="text-danger">*</span></label>
                                                    <i-feather name="key" class="fea icon-sm icons"></i-feather>
                                                    <input type="password" formControlName="password" class="form-control pl-5" placeholder="Password" [ngClass]="{ 'is-invalid': submitted && f.password.errors }">
                                                    <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                                                        <div *ngIf="f.password.errors.required">Password is required</div>
                                                        <div *ngIf="f.password.errors.minlength">Password must be at least 6 characters</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <!--end col-->

                                        </div>
                                        <!--end row-->

                                        <!--end row-->

                                        <!--end form-->
                                    </form>
                                </ng-template>
                            </li>

                            <li [ngbNavItem]="1" [id]="0" [destroyOnHide]="false">
                                <a ngbNavLink class="rounded">
                                    <div class="text-center pt-1 pb-1">
                                        <h4 class="title font-weight-normal mb-0">Crop information</h4>
                                    </div>
                                </a>
                                <ng-template ngbNavContent>

                                    <div class="row">
                                        <div class="col-md-12">
                                            <label style="font-size: 14px; font-weight: 700;">Crop information</label>
                                            <div>
                                                <div id="search-box-div">
                                                    <div id="search-field" class="top-div">
                                                        <input #input type="text" class="form-control" placeholder="Filter by Crop" [(ngModel)]="searchbycroptext">
                                                    </div>
                                                </div>
                                                <div *ngFor="let item of tmpcropdata | filter: searchbycroptext;">
                                                    <ul *ngIf="item.statu==0">
                                                        <p class="leftdata"><strong>{{ item.cropname }}</strong></p>
                                                        <p *ngFor="let pk of packingarray; let i=index;" [ngClass]="(i== 0||i==1) ? 'leftdata' : ''">{{ pk.name }}</p>
                                                        <div *ngIf="item.count>0">
                                                            <div *ngFor="let item1 of cropinfo | filter: searchbybreedtext;">
                                                                <div *ngIf="item.id==item1.id && item1.statu==1">
                                                                    <li>
                                                                        <span class="leftdata">{{ item1.breedname }}</span>
                                                                        <span class="leftdata" *ngFor="let pk of packingarray"> 
                                      <input type="checkbox" (change)="packingselecte($event,pk.id,item.id,item1.breedid)">
                                    </span>

                                                                    </li>
                                                                </div>

                                                            </div>
                                                        </div>
                                                        <div *ngIf="item.count==0">
                                                            <div>
                                                                <li>
                                                                    <span class="leftdata"></span>
                                                                    <span class="leftdata" *ngFor="let pk of packingarray"> 
                                <input type="checkbox" (change)="packingselecte($event,pk.id,item.id,0)">
                                  </span>
                                                                </li>
                                                            </div>
                                                        </div>

                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-6">
                                                <label style="font-size: 14px; font-weight: 700;">Growing environment</label>
                                                <div>
                                                    <label style="margin-right: 5px;" *ngFor="let item of growing_environment">
                            <input type="radio" [(ngModel)]="growing_environment_id" name="growing_environment_id"
                              [value]="item.id" />
                            {{item.name}}
                          </label>
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <label style="font-size: 14px; font-weight: 700;">Growing medium</label>
                                                <div>
                                                    <label style="margin-right: 5px;" *ngFor="let item of growing_medium">
                            <input type="radio" [(ngModel)]="growing_medium_id" name="growing_medium_id"
                              [value]="item.id" />
                            {{item.name}}
                          </label>
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <label style="font-size: 14px; font-weight: 700;">Availability produce</label>
                                                <div>
                                                    <label style="margin-right: 5px;" *ngFor="let item of availability_produce">
                            <input type="radio" [(ngModel)]="availability_produce_id" name="availability_produce_id"
                              [value]="item.id" />
                            {{item.name}}
                          </label>
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="form-group mb-0">
                                                    <input type="checkbox" [(ngModel)]="storage" class="custom-control-input" id="customSwitch1">
                                                    <label class="custom-control-label" for="customSwitch1">Cold storage</label>
                                                </div>
                                            </div>
                                            <div class="col-md-12">
                                                <label style="font-size: 14px; font-weight: 700;">Do you sell interstate at this moment?</label>
                                                <div>
                                                    <label style="margin-right: 5px;">
                            <input type="radio" [(ngModel)]="sell_interstate" name="sell_interstate" [value]="1" />
                            Yes
                          </label>
                                                    <label style="margin-right: 5px;">
                            <input type="radio" [(ngModel)]="sell_interstate" name="sell_interstate" [value]="0" />
                            No
                          </label>
                                                </div>
                                            </div>



                                        </div>

                                    </div>


                                </ng-template>
                            </li>

                            <li [ngbNavItem]="2" [destroyOnHide]="false" [disabled]="disabled">
                                <a ngbNavLink class=" rounded">
                                    <div class="text-center pt-1 pb-1">
                                        <h4 class="title font-weight-normal mb-0">Certificates</h4>
                                    </div>
                                </a>
                                <ng-template ngbNavContent>

                                    <div class="row mb-2" *ngFor="let item of certificatearray">
                                        <div class="col-md-6">
                                            {{ item.name }} <span (click)="reset(item.id)"><img src="../../../assets/images/new/rest.png"
                          class="reset"></span>
                                        </div>
                                        <div class="col-md-6">
                                            <input type='file' name="myFile" id="mcontrol" (change)="upload($event,item.id)">

                                        </div>

                                    </div>
                                    <button (click)="saveimg()">Save</button>

                                </ng-template>
                            </li>

                            <li [ngbNavItem]="3" [destroyOnHide]="false">
                                <a ngbNavLink class=" rounded">
                                    <div class="text-center pt-1 pb-1">
                                        <h4 class="title font-weight-normal mb-0">Subscription</h4>
                                    </div>
                                </a>
                                <ng-template ngbNavContent>
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="form-group position-relative">
                                                <label>Subscription<span class="text-danger">*</span></label>
                                                <select (change)="getsubc($event)" class="form-control custom-select">
                          <option value="">Select</option>
                          <option *ngFor="let item of subscriptionlist;let i=index" value="{{ i }}">{{ item.name }} Months
                          </option>
                        </select>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group position-relative">
                                                <label>Amount</label>
                                                <i-feather name="dollar-sign" class="fea icon-sm icons"></i-feather>
                                                <input name="name" id="last" value="{{ subamount }}" type="text" class="form-control pl-5" placeholder="Amount" readonly>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-12" style="margin-left: -10px;">
                                        <app-strip (IsstripObj)="stripdata($event)"></app-strip>

                                        <div *ngIf="isDatarequired" class="alert alert-danger" role="alert"> {{ errormsg }} </div>

                                        <button (click)='onSubmit()' class="btn btn-primary btn-block">
                      <span style="margin-right: 10px;">Register</span>
                      <div class="spinner-border" *ngIf="isSpinner" role="status">
                        <span class="sr-only">Loading...</span>
                      </div>
                    </button>
                                    </div>

                                </ng-template>
                            </li>

                        </ul>
                        <div [ngbNavOutlet]="nav" class="pt-2"></div>


                    </div>

                    <div class="card-body" *ngIf="!isEdit">
                        <div class="row">
                            <div class="col-md-6 mt-4">
                                <h5>Personal Details :</h5>
                                <div class="mt-4">
                                    <div class="media align-items-center">
                                        <i-feather name="mail" class="fea icon-ex-md text-muted mr-3"></i-feather>
                                        <div class="media-body text-truncate">
                                            <h6 class="text-primary mb-0">Email :</h6>
                                            <a href="javascript:void(0)" class="text-muted">kristajoseph0203@mail.com</a>
                                        </div>
                                    </div>
                                    <div class="media align-items-center mt-3">
                                        <i-feather name="bookmark" class="fea icon-ex-md text-muted mr-3"></i-feather>
                                        <div class="media-body">
                                            <h6 class="text-primary mb-0">Skills :</h6>
                                            <a href="javascript:void(0)" class="text-muted">html</a>, <a href="javascript:void(0)" class="text-muted">css</a>, <a href="javascript:void(0)" class="text-muted">js</a>, <a href="javascript:void(0)" class="text-muted">mysql</a>
                                        </div>
                                    </div>
                                    <div class="media align-items-center mt-3">
                                        <i-feather name="italic" class="fea icon-ex-md text-muted mr-3"></i-feather>
                                        <div class="media-body">
                                            <h6 class="text-primary mb-0">Language :</h6>
                                            <a href="javascript:void(0)" class="text-muted">English</a>, <a href="javascript:void(0)" class="text-muted">Japanese</a>, <a href="javascript:void(0)" class="text-muted">Chinese</a>
                                        </div>
                                    </div>
                                    <div class="media align-items-center mt-3">
                                        <i-feather name="globe" class="fea icon-ex-md text-muted mr-3"></i-feather>
                                        <div class="media-body">
                                            <h6 class="text-primary mb-0">Website :</h6>
                                            <a href="javascript:void(0)" class="text-muted">www.kristajoseph.com</a>
                                        </div>
                                    </div>
                                    <div class="media align-items-center mt-3">
                                        <i-feather name="gift" class="fea icon-ex-md text-muted mr-3"></i-feather>
                                        <div class="media-body">
                                            <h6 class="text-primary mb-0">Birthday :</h6>
                                            <p class="text-muted mb-0">2nd March, 1996</p>
                                        </div>
                                    </div>
                                    <div class="media align-items-center mt-3">
                                        <i-feather name="map-pin" class="fea icon-ex-md text-muted mr-3"></i-feather>
                                        <div class="media-body">
                                            <h6 class="text-primary mb-0">Location :</h6>
                                            <a href="javascript:void(0)" class="text-muted">Beijing, China</a>
                                        </div>
                                    </div>
                                    <div class="media align-items-center mt-3">
                                        <i-feather name="phone" class="fea icon-ex-md text-muted mr-3"></i-feather>
                                        <div class="media-body">
                                            <h6 class="text-primary mb-0">Cell No :</h6>
                                            <a href="javascript:void(0)" class="text-muted">(+12) 1254-56-4896</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!--end col-->

                            <div class="col-md-6 mt-4 pt-2 pt-sm-0">
                                <h5>Experience :</h5>

                                <div class="media key-feature align-items-center p-3 rounded shadow mt-4">
                                    <img src="assets/images/job/Circleci.svg" class="avatar avatar-ex-sm" alt="">
                                    <div class="media-body content ml-3">
                                        <h4 class="title mb-0">Senior Web Developer</h4>
                                        <p class="text-muted mb-0">3 Years Experience</p>
                                        <p class="text-muted mb-0"><a href="javascript:void(0)" class="text-primary">CircleCi</a> @London, UK
                                        </p>
                                    </div>
                                </div>

                                <div class="media key-feature align-items-center p-3 rounded shadow mt-4">
                                    <img src="assets/images/job/Codepen.svg" class="avatar avatar-ex-sm" alt="">
                                    <div class="media-body content ml-3">
                                        <h4 class="title mb-0">Web Designer</h4>
                                        <p class="text-muted mb-0">2 Years Experience</p>
                                        <p class="text-muted mb-0"><a href="javascript:void(0)" class="text-primary">Codepen</a> @Washington D.C, USA</p>
                                    </div>
                                </div>

                                <div class="media key-feature align-items-center p-3 rounded shadow mt-4">
                                    <img src="assets/images/job/Gitlab.svg" class="avatar avatar-ex-sm" alt="">
                                    <div class="media-body content ml-3">
                                        <h4 class="title mb-0">UI Designer</h4>
                                        <p class="text-muted mb-0">2 Years Experience</p>
                                        <p class="text-muted mb-0"><a href="javascript:void(0)" class="text-primary">Gitlab</a> @Perth, Australia
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <!--end col-->
                        </div>
                    </div>
                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row-->
    </div>
    <!--end container-->
</section>
<!--end section-->
<!-- Profile Setting End -->



<!-- Back to top -->
<a href="javascript: void(0);" class="btn btn-icon btn-soft-primary back-to-top" id="back-to-top" [ngxScrollTo]="'#home'">
    <i-feather name="arrow-up" class="icons"></i-feather>
</a>
<!-- Back to top -->
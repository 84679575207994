<!-- Hero Start -->
<section class="bg-profile d-table w-100 bg-primary" id="home" style="background: url('assets/images/account/bg.png') center center;">

    <!--ed container-->
</section>
<!--end section-->
<!-- Hero End -->

<!-- Profile Start -->
<section class="section mainarea mt-60">
    <div class="container">
        <div class="row">

            <!--end col-->

            <div class="col-lg-12 col-12">
                <div class="card border-0 rounded shadow">

                    <div class="border-bottom pb-4 card-body">
                        <h5 class="m-0 pull-left">Certificate List</h5>
                        <a (click)="isedit(isEdit)" style="float: right;" class="btn btn-primary btn-sm"> {{isEdit?' List Certificate':' Add Certificate' }} </a>
                    </div>
                    <div class="card-body" *ngIf="isEdit">
                        <form [formGroup]="certificateForm">
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group position-relative">
                                        <label>Certificate Name</label>
                                        <input name="name" id="first" type="text" class="form-control" placeholder="Certificate Name" formControlName="name" [ngClass]="{ 'is-invalid': submitted && f.name.errors }">
                                        <div *ngIf="submitted && f.name.errors" class="invalid-feedback">
                                            <div *ngIf="f.name.errors.required">Certificate name is required</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6"></div>
                                <div class="col-md-6" *ngIf="isactivecheck">
                                    <div class="form-group position-relative">
                                        <label>Is Active</label>
                                        <select formControlName="isactive" class="form-control custom-select">
                      <option value="1">Active</option>
                      <option value="0">Deactive</option>
                    </select>
                                    </div>
                                </div>
                            </div>
                            <!--end row-->
                            <div class="row">
                                <div class="col-sm-12">
                                    <button (click)='onSubmit()' class="btn btn-primary">
                    <span>Submit</span> 
                    <div class="spinner-border" *ngIf="isSpinner" role="status">
                      <span class="sr-only">Loading...</span>
                    </div></button>
                                    <input type="submit" (click)="isedit(isEdit)" id="submit" class="btn btn-dark ml-2" value="Cancel">
                                </div>
                                <!--end col-->
                            </div>
                            <!--end row-->
                        </form>
                    </div>

                    <div class="card-body" *ngIf="!isEdit">
                        <div class="row" style="overflow: auto;">
                            <div class="col-md-12 mt-4">
                                <!-- <form>
                  <div class="form-group form-inline">
                    Full text search: <input class="form-control ml-2" type="text" [formControl]="filter"/>
                </div>
                </form> -->

                                <table class="table table-striped">
                                    <thead class="thead-light">
                                        <tr>
                                            <th class="text-nowrap" scope="col">No.</th>
                                            <th class="text-nowrap" scope="col">Certificate Name</th>
                                            <!-- <th scope="col">Is Active</th> -->
                                            <th class="text-nowrap" scope="col">Action</th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr class="bg-white" *ngFor="let item of certificatelist; index as i">
                                            <td class="align-middle">{{item.id}}</td>
                                            <td class="align-middle">{{item.name}} </td>
                                            <!-- <td>
                       {{item.isactive}}
                      </td> -->
                                            <td>
                                                <a href="javascript:void(0)" data-toggle="modal" data-target="#LoginForm" class="btn" (click)="open(content,item.id)">
                                                    <img src="../../../assets/images/new/delete.png" width="26"></a>

                                            </td>
                                        </tr>
                                    </tbody>
                                </table>

                                <div class="d-flex justify-content-between p-2">
                                    <!-- <ngb-pagination [collectionSize]="collectionSize" [(page)]="page" [pageSize]="pageSize" (pageChange)="refreshCountries()">
                                    </ngb-pagination> -->
                                    <!-- <ngb-pagination [collectionSize]="7" [(page)]="page"></ngb-pagination> -->
                                    <ngb-pagination [(page)]="page" [pageSize]="pageSize" [collectionSize]="collectionSize" (pageChange)="refreshCountries()" [maxSize]="5" [rotate]="true" [boundaryLinks]="true"></ngb-pagination>
                                    <!-- <ngb-pagination [collectionSize]="70" [(page)]="page" aria-label="Custom pagination">
                                        <ng-template ngbPaginationPrevious>Prev</ng-template>
                                        <ng-template ngbPaginationNext>Next</ng-template>
                                        <ng-template ngbPaginationNumber let-p>{{ getPageSymbol(p) }}</ng-template>
                                    </ngb-pagination> -->
                                    <select class="custom-select" style="width: auto" [(ngModel)]="pageSize" (ngModelChange)="refreshCountries()">
                    <option [ngValue]="2">2 items per page</option>
                    <option [ngValue]="4">4 items per page</option>
                    <option [ngValue]="6">6 items per page</option>
                    <option [ngValue]="10">10 items per page</option>
                  </select>
                                </div>

                            </div>
                            <!--end col-->
                        </div>
                    </div>
                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row-->
    </div>
    <!--end container-->
</section>
<!--end section-->
<!-- Profile Setting End -->
<!-- Modal Content Start -->
<ng-template #content let-modal>
    <div class="modal-header">
        <h5 class="modal-title" id="modal-basic-title">Delete</h5>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
        <div class="bg-white rounded box-shadow">
            <p class="text-muted mb-0">Are you sure you want to delete? </p>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">Cancel</button>
        <button type="button" class="btn btn-outline-danger" (click)="deleteval()">Delete</button>

    </div>
</ng-template>
<!-- Modal Content End -->



<!-- Back to top -->
<a href="javascript: void(0);" class="btn btn-icon btn-soft-primary back-to-top" id="back-to-top" [ngxScrollTo]="'#home'">
    <i-feather name="arrow-up" class="icons"></i-feather>
</a>
<!-- Back to top -->
<!-- Hero Start -->
<section class="bg-half bg-light d-table w-100" id="home">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-12 text-center">
        <div class="page-next-level">
          <h4 class="title"> Return & Refund Policy </h4>
          <div class="page-next">
            <nav aria-label="breadcrumb" class="d-inline-block">
              <ul class="breadcrumb bg-white rounded shadow mb-0">
                <li class="breadcrumb-item"><a routerLink="/index">Homr</a></li>
                <!-- <li class="breadcrumb-item"><a href="#">Page</a></li>
                <li class="breadcrumb-item"><a href="#">Utility</a></li> -->
                <li class="breadcrumb-item active" aria-current="page">Return & Refund Policy</li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- Hero End -->

<!-- Shape Start -->
<div class="position-relative">
  <div class="shape overflow-hidden text-white">
    <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
    </svg>
  </div>
</div>
<!--Shape End-->

<!-- Start Terms & Conditions -->
<section class="section">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-12">
        <div class="card shadow border-0 rounded">
          <div class="card-body">


            <h5 class="card-title">Definitions and key terms :</h5>
            <hr/>
            <p class="text-muted">To help explain things as clearly as possible in this Return & Refund Policy, every
              time any of these terms are referenced, are
              strictly defined as:</p>
            <ul class="list-unstyled text-muted listing-data">
              <li>
                <i-feather name="arrow-right" class="fea icon-sm mr-2"></i-feather>
                Cookie: small amount of data generated by a website and saved by your web browser. It is used to
                identify your browser,
                provide analytics, remember information about you such as your language preference or login information.
              </li>
              <li>
                <i-feather name="arrow-right" class="fea icon-sm mr-2"></i-feather>
                Company: when this policy mentions “Company,” “we,” “us,” or “our,” it refers to OziAgriMarket, that is
                responsible for your
                information under this Return & Refund Policy.
              </li>
              <li>
                <i-feather name="arrow-right" class="fea icon-sm mr-2"></i-feather>
                Customer: refers to the company, organization or person that signs up to use the OziAgriMarket Service
                to manage the
                relationships with your consumers or service users.
              </li>
              <li>
                <i-feather name="arrow-right" class="fea icon-sm mr-2"></i-feather>
                Device: any internet connected device such as a phone, tablet, computer or any other device that can be
                used to visit
                OziAgriMarket and use the services.
              </li>
              <li>
                <i-feather name="arrow-right" class="fea icon-sm mr-2"></i-feather>
                Service: refers to the service provided by OziAgriMarket as described in the relative terms (if
                available) and on this
                platform.
              </li>
              <li>
                <i-feather name="arrow-right" class="fea icon-sm mr-2"></i-feather>
                Website: OziAgriMarket."’s" site, which can be accessed via this URL:
              </li>
              <li>
                <i-feather name="arrow-right" class="fea icon-sm mr-2"></i-feather>
                You: a person or entity that is registered with OziAgriMarket to use the Services.
              </li>
            </ul>

            <h5 class="card-title mt-4">Return & Refund Policy :</h5>
            <hr/>
            <p class="text-muted">To help explain things as clearly as possible in this Return & Refund Policy, every
              time any of these terms are referenced, are
              strictly defined as:</p>
            <p class="text-muted">Thanks for shopping at OziAgriMarket. We appreciate the fact that you like to buy the
              stuff we build. We also want to make sure
              you have a rewarding experience while you’re exploring, evaluating, and purchasing our products.
            </p>
            <p class="text-muted">As with any shopping experience, there are terms and conditions that apply to
              transactions at OziAgriMarket. We’ll be as brief
              as our attorneys will allow. The main thing to remember is that by placing an order or making a purchase
              at OziAgriMarket, you
              agree to the terms set forth below along with Policy.
            </p>
            <p class="text-muted">If there’s something wrong with the you bought, or if you are not happy with it, you
              have 1 month to issue a refund and return
              your .
            </p>
            <p class="text-muted">
              If you would like to return a product, the only way would be if you follow the next guidelines:
            </p>
            <p class="text-muted">The package needs to come back with the original receipt of the purchase.
            </p>
            <h5 class="card-title mt-4">Refunds:</h5>
            <hr/>
            <p class="text-muted">We at ourselves to serving our customers with the best products. Every single product
              that you choose is thoroughly inspected,
              checked for defects and packaged with utmost care. We do this to ensure that you fall in love with our
              products.</p>

            <p class="text-muted">
              Sadly, there are times when we may not have the product(s) that you choose in stock, or may face some
              issues with our
              inventory and quality check. In such cases, we may have to cancel your order. You will be intimated about
              it in advance so that
              you don't have to worry unnecessarily about your order. If you have purchased via Online payment (not Cash
              on Delivery), then
              you will be refunded once our team confirms your request.</p>
            <p class="text-muted">
              We carry out thorough quality check before processing the ordered item. We take utmost care while packing
              the product. At the
              same time we ensure that the packing is good such that the items won’t get damaged during transit. Please
              note that
              OziAgriMarket is not liable for damages that are caused to the items during transit or transportation.</p>
            <p class="text-muted">
              We will revise your returned product as soon as we receive it and if it follows the guidelines addressed
              above, we will proceed to
              issue a refund of your purchase. Your refund may take a couple of days to process but you will be notified
              when you receive
              your money.</p>

            <h5 class="card-title mt-4">Shipping</h5><hr/>

            <p class="text-muted">OziAgriMarket is not responsible for return shipping costs. Every shipping has to be
              paid by the customer, even if the item had
              free shipping in the first place, the customer has to pay for the shipping in return.</p>

            <h5 class="card-title mt-4">Your Consent</h5><hr/>
            <p class="text-muted">By using our app, registering an account, or making a purchase, you hereby consent to
              our Return & Refund Policy and agree
              to its terms.</p>
            <h5 class="card-title mt-4">Changes To Our Return & Refund Policy</h5><hr/>
            <p class="text-muted">Should we update, amend or make any changes to this document so that they accurately
              reflect our Service and policies.
              Unless otherwise required by law, those changes will be prominently posted here. Then, if you continue to
              use the Service, you
              will be bound by the updated Return & Refund Policy. If you do not want to agree to this or any updated
              Return & Refund Policy,
              you can delete your account.</p>
            <h5 class="card-title mt-4">Contact Us</h5><hr/>
            <p class="text-muted">If, for any reason, You are not completely satisfied with any good or service that we
              provide, don't hesitate to contact us and we
              will discuss any of the issues you are going through with our product.</p>
            <p class="text-muted">● Via Email: ozipatel2020@gmail.com</p>
          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- End Terms & Conditions -->

<!-- Back to top -->
<a href="javascript: void(0);" class="btn btn-icon btn-soft-primary back-to-top" id="back-to-top"
  [ngxScrollTo]="'#home'">
  <i-feather name="arrow-up" class="icons"></i-feather>
</a>
<!-- Back to top -->
import { AuthService } from 'src/app/service/auth.service';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { PostService } from 'src/app/service/post.service';

@Component({
  selector: 'app-auth-re-password',
  templateUrl: './auth-re-password.component.html',
  styleUrls: ['./auth-re-password.component.css']
})
export class AuthRePasswordComponent implements OnInit {
  resetPassForm: FormGroup;
  submitted = false;
  isSpinner: boolean=false;
  constructor(private formBuilder: FormBuilder,public authservice: AuthService,private toastr: ToastrService) { }

  ngOnInit(): void {
    this.resetPassForm = this.formBuilder.group({
       email: ['', [Validators.required, Validators.email]]
    });

  }
  get f() { return this.resetPassForm.controls; }

  onSubmit() {
    this.isSpinner = true;
    this.submitted = true;

    // stop here if form is invalid
    if (this.resetPassForm.invalid) {
      return;
    }
   //alert('SUCCESS!! :-)\n\n' + JSON.stringify(this.resetPassForm.value, null, 4));
    // let data = {
    //   email: thi
    // }
    this.authservice.sendrestlink(this.resetPassForm.value)
      .subscribe((data: any) => {
        this.submitted = false;
        this.isSpinner = false;
        if(data.status==true){
          this.toastr.success(data.message);

        }else{
          this.toastr.error(data.message);

        }
        this.resetPassForm.reset();
      });
    // display form values on success
   
    
  }


}

import { Component, OnInit, PipeTransform } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/service/auth.service';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { PostService } from 'src/app/service/post.service';
import { StorageService } from 'src/app/service/storage.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  userid: any;
  isSpinnerlist: boolean = false;
  certificatelist: any = [];
  statewiselist: any = [];
  statewiselist_growlist: any = [];
  growerwisecroplist: any = [];
  growingenvlist: any = [];
  seasonalwhole: any = [];
  growingswlist: any = [];
  crtificateg: any = [];
  vendorlist: any = [];
  vendorlistarray: any = [];
  wholeslist: any = [];
  retailerslist: any = [];
  vendercount: any = 0;
  productscount: any = 0;
  growerscount: any = 0;
  retailerscount: any = 0;
  wholeslistcount: any = 0;
  IsGrower: boolean = false;
  IsStateGrower: boolean = false;
  IsProduce: boolean = false;
  IsGrowing: boolean = false;
  IsAll: boolean = false;
  IsGrowersw: boolean = false;
  IsCertificate: boolean = false;
  IsGrowerRailsWhol: boolean = false;
  IsGrowerRails: boolean = false;
  IsGrowerWhole: boolean = false;
  searchText;
  isadmin = 0;
  isgrower = 0;
  isbuyer = 0;
  isvendor = 0;
  //Table code
  page = 1;
  pageSize = 1;
  wpage = 1;
  wpageSize = 4;
  rpage = 1;
  rpageSize = 4;
  spage = 1;
  spageSize = 4;
  vpage = 1;
  vpageSize = 4;
  bgpage = 1;
  bgpageSize = 4;
  propage = 1;
  propageSize = 4;

  collectionSize = 0;
  collectionSizeenv = 0;
  collectionSizesw = 0;
  collectionSizecertig = 0;
  masterlist: any = [];
  allmasterlist: any = [];
  bgrowerlist: any = [];
  bgrowerlist_all: any = [];
  allmasterproducelist: any = [];
  allmastergroenvlist: any = [];
  allmasterswlist: any = [];
  allmastercertiglist: any = [];
  IsSpinnerall: boolean = true;
  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: false,
    pullDrag: false,
    navSpeed: 700,
    navText: ['', ''],
    responsive: {
      0: {
        items: 1
      },
      600: {
        items: 2
      },
      900: {
        items: 3
      }
    },
    nav: false
  };
  statelist: any = [];
  stateid: any;
  usertype: string = "";
  stateval: string = "";
  ratilscount: number = 0;
  wholecount: number = 0;
  bgrowerlistcount: number = 0;
  reails_whollist: any = [];
  wholesales_whollist: any = [];
  staategrowerscount: number=0;
  constructor(private formBuilder: FormBuilder, private _authService: AuthService, private _router: Router, private toastr: ToastrService, public activatedRoute: ActivatedRoute, public postservice: PostService, public storageService: StorageService, private modalService: NgbModal) {

  }

  refreshComponent(){
    this._router.navigate([this._router.url])
 }
  async ngOnInit() {
    await this.getstatelist();
    await this.getgrowerregister();
    await this.getCertificatewisegrower();
    await this.getgrowerwisecrop();
    await this.getretail_whole();
    //await  this.getstatewisegrower();
    await this.getgrowerlist();
    await this.getgrowingenv();
    await this.getgrowingsea_whol();
    await this.getvendorlist();

    this.storageService.get('logininfo').then(
      (res) => {
        console.log(res);

        this.userid = res.id;
        if (res.id) {
          this.stateid = res.stateid
          this.getstatewisegrower();
          this.userid = res.id;
          this.isadmin = res.admin;
          this.isgrower = res.grower;
          this.isbuyer = res.buyer;
          this.isvendor = res.vendor;


        }
      })
  }

  searchpage() {
    console.log(this.usertype);
    console.log(this.stateval);
    console.log(this.stateval);
    let flag = 0;
    if(this.usertype=="4"){
      flag = 1;
      this.usertype = "2";
    }
    
    if (this.usertype) {
      this._router.navigateByUrl('/search/' + this.usertype  + '/' +flag + '/' + this.stateval);
    } else {
      this.toastr.error("Please select type");

    }
  }

  getgrowerregister() {
    let data = {
      id: 1
    };
    this.postservice.getgrowerregister(data)
      .subscribe((data: any) => {
        console.log(data);
        this.isSpinnerlist = false;
        this.growerscount = data.success.growercount
        //this.certificatelist = data.success;

      });
  }
  getvendorlist() {
    let data = {
      id: 1
    };
    this.postservice.getvendorlist(data)
      .subscribe((data: any) => {
        console.log(data);
        this.IsSpinnerall = false;
        this.isSpinnerlist = false;
        this.vendercount = data.success.length
        this.vendorlist = data.success;
        this.vendorlistarray = data.success;
      });
  }

  refreshvendorlist() {
    this.vendorlistarray = this.vendorlist
      .map((country, i) => ({ id: i + 1, ...country }))
      .slice((this.vpage - 1) * this.vpageSize, (this.vpage - 1) * this.vpageSize + this.vpageSize);
  }

  getCertificatewisegrower() {
    let data = {
      id: 1
    };
    this.postservice.getCertificatewisegrower(data)
      .subscribe((data: any) => {
        console.log(data);
        this.isSpinnerlist = false;
        this.certificatelist = data.success;

      });
  }
  getretail_whole() {
    let data = {
      id: 1
    };
    this.postservice.getretail_whole(data)
      .subscribe((data: any) => {
        console.log(data);
        this.isSpinnerlist = false;
        
//         this.reails_whollist = data.success;
// debugger;
this.retailerslist = [];
this.wholeslist = [];
        
        for (let i = 0; i < data.success.length; i++) {
          const type = data.success[i].retai_whole;
          // const ratail = data.success[i].ratail;
          
          if (type === 0) {
            this.retailerslist.push(data.success[i]);
            this.ratilscount = this.ratilscount + 1;
          } else {
            this.wholeslist.push(data.success[i]);
            this.wholecount = this.wholecount + 1;
          }

        }

        console.log(this.retailerslist);
        console.log(this.wholeslist);
        debugger;
        this.retailerscount = this.retailerslist.length
        this.wholeslistcount = this.wholeslist.length
       this.reails_whollist = this.retailerslist
          .map((country, i) => ({ id: i + 1, ...country }))
          .slice((this.rpage - 1) * this.rpageSize, (this.rpage - 1) * this.rpageSize + this.rpageSize);

        this.wholesales_whollist = this.wholeslist
          .map((country, i) => ({ id: i + 1, ...country }))
          .slice((this.wpage - 1) * this.wpageSize, (this.wpage - 1) * this.wpageSize + this.wpageSize);
     

        // debugger;
        // for (let i = 0; i < data.success.length; i++) {
        //   const type = data.success[i].retai_whole;
        //   // const ratail = data.success[i].ratail;
        //   if (type === 0) {
        //     this.ratilscount = this.ratilscount + 1;
        //   } else {
        //     this.wholecount = this.wholecount + 1;
        //   }

        // }
        //this.certificatelist = data.success;

      });
  }

  refreshgrowerrailslist() {
    this.reails_whollist = this.retailerslist
      .map((country, i) => ({ id: i + 1, ...country }))
      .slice((this.rpage - 1) * this.rpageSize, (this.rpage - 1) * this.rpageSize + this.rpageSize);
  }

  refreshgrowerwholelist() {
    this.wholesales_whollist = this.wholeslist
      .map((country, i) => ({ id: i + 1, ...country }))
      .slice((this.wpage - 1) * this.wpageSize, (this.wpage - 1) * this.wpageSize + this.wpageSize);
  }
  getstatewisegrower() {
    let data = {
      id: this.stateid
    };
    this.postservice.getstatewisegrower(data)
      .subscribe((data: any) => {
        console.log(data);
        debugger;
        this.isSpinnerlist = false;
        this.staategrowerscount =data.success.length;
        this.statewiselist = data.success;
        this.statewiselist_growlist =  data.success;
      });
  }

  refreshstatewiselist() {
    this.statewiselist_growlist = this.statewiselist
      .map((country, i) => ({ id: i + 1, ...country }))
      .slice((this.spage - 1) * this.spageSize, (this.spage - 1) * this.spageSize + this.spageSize);
  }
  getstatelist() {
    this._authService.getstate()
      .subscribe((data: any) => {
        console.log(data);
        this.isSpinnerlist = false;
        this.statelist = data.success;
      });
  }
  getgrowingsea_whol() {
    let data = {
      id: 1
    };
    this.postservice.getgrowingsea_whol(data)
      .subscribe((data: any) => {
        console.log(data);
        this.seasonalwhole = data.success;;
      });
  }
  getgrowerwisecrop() {
    let data = {
      id: 1
    };
    this.postservice.getgrowerwisecrop(data)
      .subscribe((data: any) => {
        console.log(data);
        this.isSpinnerlist = false;
        this.growerwisecroplist = data.success;
        this.productscount = data.success.length
        this.allmasterproducelist = data.success;
        this.growerwisecroplist = data.success
          .map((country, i) => ({ id: i + 1, ...country }))
          .slice((this.propage - 1) * this.propageSize, (this.propage - 1) * this.propageSize + this.propageSize);
      });
  }
  refreshCrop() {
    this.growerwisecroplist = this.allmasterproducelist
      .map((country, i) => ({ id: i + 1, ...country }))
      .slice((this.propage - 1) * this.propageSize, (this.propage - 1) * this.propageSize + this.propageSize);
  }

  //IsGrower
  showhideGrower(val, type) {
    debugger
    if (val == true) {
      this.IsGrower = false;
      this.IsStateGrower = false;
      this.IsProduce = false;
      this.IsGrowing = false;
      this.IsGrowersw = false;
      this.IsGrowerRailsWhol = false;
      this.IsGrowerRails=false;
      this.IsGrowerWhole=true;

    } else {
      this.IsAll = true;
      if (type == 1) {
        this.IsGrower = true;
      } else if (type == 2) {
        this.IsStateGrower = true;
      } else if (type == 3) {
        this.IsProduce = true;
      } else if (type == 4) {
        this.IsGrowing = true;
      } else if (type == 5) {
        this.IsGrowersw = true;
      } else if (type == 6) {
        //Hole
       // debugger
        this.IsGrowerWhole=true;
        this.IsGrowerRails=false;
        this.IsGrowerRailsWhol = true;

      } else if (type == 7) {
        //Retails
        this.IsGrowerWhole=false;
        this.IsGrowerRails=true;
        this.IsGrowerRailsWhol = true;

      } else {

      }
    }
  }

  showhideGrowersw(val, id, type) {

    if (val == true) {
      this.IsGrower = false;
      this.IsStateGrower = false;
      this.IsProduce = false;
      this.IsGrowing = false;
      this.IsGrowersw = false;
      this.IsCertificate = false;
      this.IsGrowerRailsWhol = false;
      this.IsGrowerRails=false;
      this.IsGrowerWhole=true;

    } else {
      this.IsAll = true;
      if (type == 1) {
        this.getsea_wholwisegro(id)
        this.IsGrowersw = true;
      } else if (type == 2) {
        this.getgrowcertificate(id)
        this.IsCertificate = true;
      } else {

      }
    }
  }

  back() {
    this.usertype="";
    this.IsGrowerRails=false;
    this.IsGrowerWhole=true;
    this.IsGrowerRailsWhol = false;
    this.IsCertificate = false;
    this.IsGrowersw = false;
    this.IsGrowing = false;
    this.IsProduce = false;
    this.IsStateGrower = false;
    this.IsGrower = false;
    this.IsAll = false;
  }
  getgrowerlist() {
    let data = {
      userid: 1
    };
    this._authService.getuseralllist(data)
      .subscribe((data: any) => {
        console.log(data);
        debugger;
        this.isSpinnerlist = false;
        //this.certificatelist = data.success;
        this.bgrowerlistcount = data.success.length;
        //this.bgrowerlist = data.success;
        this.bgrowerlist = data.success;
        this.bgrowerlist_all = data.success
          .map((country, i) => ({ id: i + 1, ...country }))
          .slice((this.bgpage - 1) * this.bgpageSize, (this.bgpage - 1) * this.bgpageSize + this.bgpageSize);
      });
  }
  refreshbuyergrowerlist() {
    this.bgrowerlist_all = this.bgrowerlist
      .map((country, i) => ({ id: i + 1, ...country }))
      .slice((this.bgpage - 1) * this.bgpageSize, (this.bgpage - 1) * this.bgpageSize + this.bgpageSize);
  }

  //Growing ENV
  getgrowingenv() {
    let data = {
      userid: 1
    };
    this.postservice.getgrowingenv(data)
      .subscribe((data: any) => {
        console.log(data);
        this.isSpinnerlist = false;
        //this.certificatelist = data.success;
        this.collectionSizeenv = data.success.length;
        this.allmastergroenvlist = data.success;
        this.growingenvlist = data.success
          .map((country, i) => ({ id: i + 1, ...country }))
          .slice((this.page - 1) * this.pageSize, (this.page - 1) * this.pageSize + this.pageSize);
      });
  }
  refreshgenv() {
    this.growingenvlist = this.allmastergroenvlist
      .map((country, i) => ({ id: i + 1, ...country }))
      .slice((this.page - 1) * this.pageSize, (this.page - 1) * this.pageSize + this.pageSize);
  }

  //Growing Seanal and whole year
  //Growing ENV
  getsea_wholwisegro(val) {
    let data = {
      id: val
    };
    this.postservice.getsea_wholwisegro(data)
      .subscribe((data: any) => {
        console.log(data);
        this.isSpinnerlist = false;
        //this.certificatelist = data.success;
        this.collectionSizesw = data.success.length;
        this.allmasterswlist = data.success;
        this.growingswlist = data.success
          .map((country, i) => ({ id: i + 1, ...country }))
          .slice((this.page - 1) * this.pageSize, (this.page - 1) * this.pageSize + this.pageSize);
      });
  }
  refreshseawhol() {
    this.growingswlist = this.allmasterswlist
      .map((country, i) => ({ id: i + 1, ...country }))
      .slice((this.page - 1) * this.pageSize, (this.page - 1) * this.pageSize + this.pageSize);
  }

  //Growing Certifecate
  getgrowcertificate(val) {
    let data = {
      id: val
    };
    this.postservice.getgrowcertificate(data)
      .subscribe((data: any) => {
        console.log(data);
        debugger
        this.isSpinnerlist = false;
        //this.certificatelist = data.success;
        this.collectionSizecertig = data.success.length;
        this.allmastercertiglist = data.success;
        this.crtificateg = data.success
          .map((country, i) => ({ id: i + 1, ...country }))
          .slice((this.page - 1) * this.pageSize, (this.page - 1) * this.pageSize + this.pageSize);
      });
  }
  refreshcerti() {
    this.crtificateg = this.allmastercertiglist
      .map((country, i) => ({ id: i + 1, ...country }))
      .slice((this.page - 1) * this.pageSize, (this.page - 1) * this.pageSize + this.pageSize);
  }

}

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { environment } from '../../environments/environment';
const currentUsertoken = localStorage.getItem('token');

const URL = environment.apibaseUrl;
const httpHeaders = new HttpHeaders({
  Authorization: `Bearer ${currentUsertoken}`,
});
@Injectable({
  providedIn: 'root'
})
export class PostService {

  constructor(private http: HttpClient) { }
  //certificat
  suburbAdd(data) {
    return this.http.post(`${URL}/suburbAdd`, data, {
      headers: httpHeaders
    });
  }
  suburbdestroy(id) {
    return this.http.delete(`${URL}` + "/suburbdestroy/" + id);
  }
  getsuburb() {
    return this.http.post(`${URL}/getsuburb`, {
      headers: httpHeaders
    });
  }
  certificateAdd(data) {
    return this.http.post(`${URL}/certificateAdd`, data, {
      headers: httpHeaders
    });
  }
  getcertificate() {
    return this.http.post(`${URL}/getcertificate`, {
      headers: httpHeaders
    });
  }
  getcertificate_byid(data) {
    return this.http.post(`${URL}/getcertificate_byid`, data, {
      headers: httpHeaders
    });
  }
  getcertificate_buyerid(data) {
    return this.http.post(`${URL}/getcertificate_buyerid`, data, {
      headers: httpHeaders
    });
  }

  certificatedestroy(id) {
    return this.http.delete(`${URL}` + "/certificatedestroy/" + id);

  }
  //Crop
  cropAdd(data) {
    return this.http.post(`${URL}/cropAdd`, data, {
      headers: httpHeaders
    });
  }
  getcrop() {
    return this.http.post(`${URL}/getcrop`, {
      headers: httpHeaders
    });
  }
  cropdestroy(id) {
    return this.http.delete(`${URL}` + "/cropdestroy/" + id);

  }

  //Breed
  breedAdd(data) {
    return this.http.post(`${URL}/breedAdd`, data, {
      headers: httpHeaders
    });
  }
  getbreed() {
    return this.http.post(`${URL}/getbreed`, {
      headers: httpHeaders
    });
  }

  getidcropbreed(data) {
    return this.http.post(`${URL}/getidcropbreed`, data, {
      headers: httpHeaders
    });
  }
  breeddestroy(id) {
    return this.http.delete(`${URL}` + "/breeddestroy/" + id);

  }

  //packing
  packingAdd(data) {
    return this.http.post(`${URL}/packingAdd`, data, {
      headers: httpHeaders
    });
  }
  getpacking() {
    return this.http.post(`${URL}/getpacking`, {
      headers: httpHeaders
    });
  }

  //subscription
  subscriptionAdd(data) {
    return this.http.post(`${URL}/subscriptionAdd`, data, {
      headers: httpHeaders
    });
  }
  getsubscription() {
    return this.http.post(`${URL}/getsubscription`, {
      headers: httpHeaders
    });
  }

  //growing_environment
  growing_environmentAdd(data) {
    return this.http.post(`${URL}/growing_environmentAdd`, data, {
      headers: httpHeaders
    });
  }
  getgrowing_environment() {
    return this.http.post(`${URL}/getgrowing_environment`, {
      headers: httpHeaders
    });
  }
  growing_environmentdestroy(id) {
    return this.http.delete(`${URL}` + "/growing_environmentdestroy/" + id);

  }
  getgrowing_environment_byid(data) {
    return this.http.post(`${URL}/getgrowing_environment_byid`, data, {
      headers: httpHeaders
    });
  }
  //growing_environment
  growing_mediumAdd(data) {
    return this.http.post(`${URL}/growing_mediumAdd`, data, {
      headers: httpHeaders
    });
  }
  getgrowing_medium() {
    return this.http.post(`${URL}/getgrowing_medium`, {
      headers: httpHeaders
    });
  }
  growing_mediumdestroy(id) {
    return this.http.delete(`${URL}` + "/growing_mediumdestroy/" + id);

  }
  getgrowing_medium_byid(data) {
    return this.http.post(`${URL}/getgrowing_medium_byid`, data, {
      headers: httpHeaders
    });
  }
  //availability_produce
  avaiproduceAdd(data) {
    return this.http.post(`${URL}/avaiproduceAdd`, data, {
      headers: httpHeaders
    });
  }
  getavaiproduce() {
    return this.http.post(`${URL}/getavaiproduce`, {
      headers: httpHeaders
    });
  }
  avaiproducedestroy(id) {
    return this.http.delete(`${URL}` + "/avaiproducedestroy/" + id);

  }
  getgrowing_avaiproduce_byid(data) {
    return this.http.post(`${URL}/getgrowing_avaiproduce_byid`, data, {
      headers: httpHeaders
    });
  }
  //getcropinfo
  getcropinfo(data) {
    return this.http.post(`${URL}/getcropinfo`, data, {
      headers: httpHeaders
    });
  }

  //Imgupload
  imgupload(data) {
    return this.http.post(`${URL}/imgupload`, data, {
      headers: httpHeaders
    });
  }
  //Profile Imgupload
  profile_imgupload(data) {
    return this.http.post(`${URL}/profile_imgupload`, data, {
      headers: httpHeaders
    });
  }

  //strip

  sendstriprequest(data) {
    return this.http.post(`${URL}/strippayment`, data, {
      headers: httpHeaders
    });
  }

  growerregistration(data) {
    return this.http.post(`${URL}/growerregistration`, data, {
      headers: httpHeaders
    });
  }

  getactivesubscriptionplan(data) {
    return this.http.post(`${URL}/getactivesubscriptionplan`, data, {
      headers: httpHeaders
    });
  }

  //Save PK Crop
  savecrope(data) {
    return this.http.post(`${URL}/savecrope`, data, {
      headers: httpHeaders
    });
  }

  //Grower Profile
  growers_profile(data) {
    return this.http.post(`${URL}/growers_profile`, data, {
      headers: httpHeaders
    });
  }

  //Buyer insert
  buyerregistration(data) {
    return this.http.post(`${URL}/buyerregistration`, data, {
      headers: httpHeaders
    });
  }
  addbuyercertificate(data) {
    return this.http.post(`${URL}/addbuyercertificate`, data, {
      headers: httpHeaders
    });
  }
  addbuyerstate(data) {
    return this.http.post(`${URL}/stateinsert`, data, {
      headers: httpHeaders
    });
  }
  getsateselected(data) {
    return this.http.post(`${URL}/getsateselected`, data);
  }
  getproductbyid(data) {
    return this.http.post(`${URL}/getproductbyid`, data);
  }
  productAdd(data) {
    return this.http.post(`${URL}/productadd`, data, {
      headers: httpHeaders
    });
  }

  product_imgupload(data) {
    return this.http.post(`${URL}/product_imgupload`, data, {
      headers: httpHeaders
    });
  }
  getproductlist(data) {
    return this.http.post(`${URL}/getproductlist`, data, {
      headers: httpHeaders
    });
  }
  deleteproduct(data) {
    return this.http.post(`${URL}/deleteproduct`, data, {
      headers: httpHeaders
    });
  }

  //Vendor Reg
  vendorregistration(data) {
    return this.http.post(`${URL}/vendorregistration`, data, {
      headers: httpHeaders
    });
  }
  //Category
  getcategory() {
    return this.http.post(`${URL}/getcategory`, {
      headers: httpHeaders
    });
  }

  //UserDelete

  userdelete(id) {
    return this.http.delete(`${URL}` + "/userdestroy/" + id);

  }

  //Dashboard API

  //Buyer

  getCertificatewisegrower(data) {
    return this.http.post(`${URL}/getCertificatewisegrower`,data, {
      headers: httpHeaders
    });
  }
  getgrowerregister(data) {
    return this.http.post(`${URL}/getgrowerregister`,data, {
      headers: httpHeaders
    });
  }
  getstatewisegrower(data) {
    return this.http.post(`${URL}/getstatewisegrower`,data, {
      headers: httpHeaders
    });
  }
  getgrowerwisecrop(data) {
    return this.http.post(`${URL}/getgrowerwisecrop`,data, {
      headers: httpHeaders
    });
  }
  getgrowingenv(data) {
    return this.http.post(`${URL}/getgrowingenv`,data, {
      headers: httpHeaders
    });
  }
  getgrowingsea_whol(data) {
    return this.http.post(`${URL}/getgrowingsea_whol`,data, {
      headers: httpHeaders
    });
  }
  getsea_wholwisegro(data) {
    return this.http.post(`${URL}/getsea_wholwisegro`,data, {
      headers: httpHeaders
    });
  }
  getgrowcertificate(data) {
    return this.http.post(`${URL}/getgrowcertificate`,data, {
      headers: httpHeaders
    });
  }
  getvendorlist(data) {
    return this.http.post(`${URL}/getvendorlist`,data, {
      headers: httpHeaders
    });
  }
 
  //Filter API Call
  
  getstatefilter(data) {
    return this.http.post(`${URL}/getstatefilter`,data, {
      headers: httpHeaders
    });
  }
  getcertificatefilter() {
    return this.http.post(`${URL}/getcertificatefilter`, {
      headers: httpHeaders
    });
  }
  getgmfilter() {
    return this.http.post(`${URL}/getgmfilter`, {
      headers: httpHeaders
    });
  }
  getavipfilter() {
    return this.http.post(`${URL}/getavipfilter`, {
      headers: httpHeaders
    });
  }

  getallfilter(data) {
    return this.http.post(`${URL}/getallfilter`,data, {
      headers: httpHeaders
    });
  }
  getretail_whole(data) {
    return this.http.post(`${URL}/getretail_whole`,data, {
      headers: httpHeaders
    });
  }
  //Send Message
  sendmessage(data) {
    return this.http.post(`${URL}/sendmessage`, data, {
      headers: httpHeaders
    });
  }
  getuserbychat(data) {
    debugger
    return this.http.post(`${URL}/getuserbychat`, data, {
      headers: httpHeaders
    });
  }
  getchat(data) {
    return this.http.post(`${URL}/getchat`, data, {
      headers: httpHeaders
    });
  }
  saveonesiganldata(data) {
    return this.http.post(`${URL}/saveonesiganldata`, data, {
      headers: httpHeaders
    });
  }
  
  //Change Password:
  changePassword(data) {
    return this.http.post(`${URL}/changePassword`, data, {
      headers: httpHeaders
    });
  }
}

<!-- Hero Start -->
<section class="bg-profile d-table w-100 bg-primary" id="home" style="background: url('assets/images/account/bg.png') center center;">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="card public-profile border-0 rounded shadow" style="z-index: 1;">
                    <div class="card-body">
                        <div class="row align-items-center">
                            <div class="col-lg-2 col-md-3 text-md-left text-center">
                                <img src="{{ userpic }}" class="avatar avatar-large rounded-circle shadow d-block mx-auto" alt="">
                            </div>
                            <!--end col-->

                            <div class="col-lg-10 col-md-9">
                                <div class="row align-items-end">
                                    <div class="col-md-7 text-md-left text-center mt-4 mt-sm-0">
                                        <h3 class="title mb-0">{{ name }}</h3>
                                        <small class="text-muted h6 mr-2">
                      {{ isadmin }} <span *ngIf="isadmin==1">,</span> {{ isgrower }} <span *ngIf="isbuyer==1">,</span>
                      {{ isbuyer }} <span *ngIf="isvendor==1">,</span> {{ isvendor }}
                    </small>
                                        <p *ngIf="isEdit">
                                            <input type="file" name="profileimg" id="upload" (change)="uploadprofile($event)" hidden />
                                            <label class="profilepic" for="upload">Choose Picture</label>
                                            <!-- <a href="javascript:void(0)" class="btn btn-primary mt-2 profilepic">    Change Picture</a> -->
                                        </p>

                                        <!-- <ul class="list-inline mb-0 mt-3">
                        <li class="list-inline-item mr-2"><a href="javascript:void(0)" class="text-muted"
                            title="Instagram">
                            <i-feather name="instagram" class="fea icon-sm mr-2"></i-feather>
                            krista_joseph
                          </a></li>
                        <li class="list-inline-item"><a href="javascript:void(0)" class="text-muted" title="Linkedin">
                            <i-feather name="linkedin" class="fea icon-sm mr-2"></i-feather>
                            Krista Joseph
                          </a></li>
                      </ul> -->
                                    </div>
                                    <!--end col-->
                                    <div class="col-md-5 text-md-right text-center" *ngIf="!id">
                                        <ul class="list-unstyled social-icon social mb-0 mt-4">
                                            <li class="list-inline-item">
                                                <a routerLink="/buyerprofile" class="rounded" placement="bottom" ngbTooltip="Profile">
                                                    <i-feather name="user" class="fea icon-sm fea-social"></i-feather>
                                                </a>
                                            </li>
                                            <li class="list-inline-item ml-1">
                                                <a routerLink="/chat" class="rounded" placement="bottom" ngbTooltip="Messages">
                                                    <i-feather name="message-circle" class="fea icon-sm fea-social"></i-feather>
                                                </a>
                                            </li>

                                            <li class="list-inline-item ml-1">
                                                <a routerLink="/growersubscription" class="rounded" placement="bottom" ngbTooltip="Subscription">
                                                    <i-feather name="credit-card" class="fea icon-sm fea-social"></i-feather>
                                                </a>
                                            </li>
                                            <li class="list-inline-item ml-1">
                                                <a routerLink="/buyerprofile" class="rounded" placement="bottom" ngbTooltip="Settings">
                                                    <i-feather name="tool" class="fea icon-sm fea-social"></i-feather>
                                                </a>
                                            </li>
                                        </ul>

                                    </div>

                                </div>

                            </div>

                        </div>

                    </div>
                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row-->
    </div>
    <!--ed container-->
</section>
<!--end section-->
<!-- Hero End -->

<!-- Profile Start -->
<section class="section mt-60">
    <div class="container">
        <div class="row">

            <!--end col-->

            <div class="col-lg-12 col-12">
                <div class="card border-0 rounded shadow">

                    <div class="border-bottom pb-4 card-body">
                        <h5 style="float: left;">Profile Details</h5>
                        <div style="float: right;">
                            <a *ngIf="!id" (click)="isedit(isEdit)" style="float: right;" class="btn btn-primary btn-sm"> Edit Profile
              </a>
                            <a *ngIf="id" (click)="isback()" style="float: right;" class="btn btn-primary btn-sm">Back </a>
                            <a *ngIf="id && isSubscribe" (click)="contact(contactGrower)" style="float: right;" class="btn btn-info btn-sm mr-2">Contact </a>
                            <a *ngIf="id && !isSubscribe" (click)="gosubscribe()" style="float: right;" class="btn btn-success btn-sm mr-2">Subscribe to Message </a>
                        </div>
                    </div>
                    <!-- <div class="card-body" *ngIf="isEdit">
            

          </div> -->
                    <div class="card-body" *ngIf="isEdit">

                        <ul ngbNav #nav="ngbNav" [(activeId)]="active" (navChange)="tabChange($event)" class="nav-pills wizard nav-justified">

                            <li [ngbNavItem]="0" [destroyOnHide]="false" [disabled]="oneli">
                                <a ngbNavLink class="rounded">
                                    <div class="text-center pt-1 pb-1">
                                        <h4 class="title font-weight-normal mb-0">Personal Detail</h4>
                                    </div>
                                </a>
                                <ng-template ngbNavContent>
                                    <div class="spinner-border" *ngIf="userSpinner" role="status">
                                        <span class="sr-only">Loading...</span>
                                    </div>
                                    <form [formGroup]="registergrowerForm">
                                        <div class="row mt-4">
                                            <div class="col-md-6">
                                                <div class="form-group position-relative">
                                                    <label>Name</label>
                                                    <i-feather name="user" class="fea icon-sm icons"></i-feather>
                                                    <input type="text" class="form-control pl-5" placeholder="Name" formControlName="name" [ngClass]="{ 'is-invalid': submitted && f.name.errors }">
                                                    <div *ngIf="submitted && f.name.errors" class="invalid-feedback">
                                                        <div *ngIf="f.name.errors.required">Name is required</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <!--end col-->
                                            <div class="col-md-6">
                                                <div class="form-group position-relative">
                                                    <label>Company Name</label>
                                                    <i-feather name="user-check" class="fea icon-sm icons"></i-feather>
                                                    <input type="text" class="form-control pl-5" placeholder="Company name" formControlName="companyname">
                                                </div>
                                            </div>
                                            <!--end col-->


                                            <div class="col-md-6">
                                                <div class="form-group position-relative">
                                                    <label>State<span class="text-danger">*</span></label>
                                                    <select (change)="getsuburbs($event,0)" formControlName="stateid" class="form-control custom-select" [ngClass]="{ 'is-invalid': submitted && f.stateid.errors }">
                            <option value="">Select</option>
                            <option *ngFor="let item of statelist" value="{{ item.id }}">{{ item.statename }}</option>
                          </select>
                                                    <div *ngIf="submitted && f.stateid.errors" class="invalid-feedback">
                                                        <div *ngIf="f.stateid.errors.required">State is required</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="form-group position-relative">
                                                    <label>City<span class="text-danger">*</span></label>
                                                    <select formControlName="cityid" class="form-control custom-select" [ngClass]="{ 'is-invalid': submitted && f.cityid.errors }">
                            <option value="">Select</option>
                            <option *ngFor="let item of citylist" value="{{ item.id }}">{{ item.cityname }}
                            </option>
                          </select>
                                                    <div *ngIf="submitted && f.cityid.errors" class="invalid-feedback">
                                                        <div *ngIf="f.cityid.errors.required">City is required</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="form-group position-relative">
                                                    <label>Suburb<span class="text-danger">*</span></label>
                                                    <select formControlName="suburbid" class="form-control custom-select" [ngClass]="{ 'is-invalid': submitted && f.suburbid.errors }">
                            <option value="">Select</option>
                            <option *ngFor="let item of suburbslist" value="{{ item.id }}">{{ item.suburbname }}
                            </option>
                          </select>
                                                    <div *ngIf="submitted && f.suburbid.errors" class="invalid-feedback">
                                                        <div *ngIf="f.suburbid.errors.required">Suburb is required</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="form-group position-relative">
                                                    <label>Post code<span class="text-danger">*</span></label>
                                                    <input type="text" class="form-control" placeholder="Post code" formControlName="postcode" [ngClass]="{ 'is-invalid': submitted && f.postcode.errors }">
                                                    <div *ngIf="submitted && f.postcode.errors" class="invalid-feedback">
                                                        <div *ngIf="f.postcode.errors.required">Post code is required</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="form-group position-relative">
                                                    <label>Buyer Type<span class="text-danger">*</span></label>
                                                    <select formControlName="retai_whole" class="form-control custom-select" [ngClass]="{ 'is-invalid': submitted && f.retai_whole.errors }">
                            <option value="Retailars">Retailars</option>
                            <option value="Wholesaller">Wholesaller</option>

                          </select>
                                                    <div *ngIf="submitted && f.retai_whole.errors" class="invalid-feedback">
                                                        <div *ngIf="f.retai_whole.errors.required">Suburb is required</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="form-group position-relative">
                                                    <label>Email <span class="text-danger">*</span></label>
                                                    <i-feather name="mail" class="fea icon-sm icons"></i-feather>
                                                    <input type="email" class="form-control pl-5" placeholder="Email" formControlName="email" [ngClass]="{ 'is-invalid': submitted && f.email.errors }">
                                                    <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                                                        <div *ngIf="f.email.errors.required">Email is required</div>
                                                        <div *ngIf="f.email.errors.email">Email must be a valid email address</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="form-group position-relative">
                                                    <label>Mobile Number<span class="text-danger">*</span></label>
                                                    <input type="text" class="form-control" placeholder="Mobile Number" formControlName="phone" [ngClass]="{ 'is-invalid': submitted && f.phone.errors }">
                                                    <div *ngIf="submitted && f.phone.errors" class="invalid-feedback">
                                                        <div *ngIf="f.phone.errors.required">Post code is required</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <!-- <div class="col-md-6">
                        <div class="form-group position-relative">
                          <label>Password <span class="text-danger">*</span></label>
                          <i-feather name="key" class="fea icon-sm icons"></i-feather>
                          <input type="password" formControlName="password" class="form-control pl-5"
                            placeholder="Password" [ngClass]="{ 'is-invalid': submitted && f.password.errors }">
                          <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                            <div *ngIf="f.password.errors.required">Password is required</div>
                            <div *ngIf="f.password.errors.minlength">Password must be at least 6 characters</div>
                          </div>
                        </div>
                      </div> -->
                                            <!--end col-->

                                        </div>
                                        <!--end row-->

                                        <!--end row-->

                                        <!--end form-->
                                    </form>
                                    <button (click)='tabChange(1)' style="float: right;" class="btn btn-secondary mt-5">
                    <span>Next</span>
                    <!-- <div class="spinner-border" *ngIf="isSpinner" role="status">
                      <span class="sr-only">Loading...</span>
                    </div> -->
                  </button>
                                </ng-template>

                            </li>

                            <li [ngbNavItem]="1" [id]="0" [destroyOnHide]="false" [disabled]="twoli">
                                <a ngbNavLink class="rounded">
                                    <div class="text-center pt-1 pb-1">
                                        <h4 class="title font-weight-normal mb-0">Crop information</h4>
                                    </div>
                                </a>
                                <ng-template ngbNavContent>
                                    <div class="w-100 d-inline-block" *ngIf="!iscrop">
                                        <div class="pull-left pt-3 pb-3">
                                            Select the Crop information
                                        </div>
                                        <div class="pull-right pt-3 pb-3 text-right">
                                            <button (click)='addcrop(iscrop)' class="btn btn-primary ">
                        <span>Add Crop</span>
                      </button>
                                        </div>
                                    </div>

                                    <div *ngIf="iscrop" class="mt-4 list-group">
                                        <div class="row list-group-item list-group-item-action active" style="display: flex; align-items: center; margin: 0;">
                                            <div class="col-lg-6 col-md-6 p-0">
                                                <a href="javascript:void(0)" class="text-white">
                          Add Crop
                        </a>
                                            </div>
                                            <div class="col-lg-6 col-md-6 p-0 text-right">
                                                <input #input type="text" class="form-control" placeholder="Filter by Crop" [(ngModel)]="searchbycroptext">
                                            </div>
                                        </div>
                                        <div class="spinner-border m-auto" *ngIf="isCropSpinner" role="status">
                                            <span class="sr-only">Loading...</span>
                                        </div>
                                        <a href="javascript:void(0)" *ngFor="let item of croplist | filter: searchbycroptext;" class="list-group-item list-group-item-action">{{ item.cropname }}
                      <!-- <span> <a  href="javascript:void(0)" class="btn btn-icon btn-pills btn-outline-primary">{{ checkbreedcount(item.id) }}</a></span> -->
                                        <span class="cropchk">
                        <button type="button" class="btn btn-outline-dark"
                          (click)="modelbreedopen(breedcontent, item.id,$event)">Select </button>
                        <!-- <input (change)="modelbreedopen(breedcontent, item.id,$event)"
                          class="chkcropinput" type="checkbox"> -->
                      </span>
                                        </a>
                                    </div>

                                    <div class="row" *ngIf="!iscrop">
                                        <div class="col-md-12 overflow-auto">
                                            <table class="table">
                                                <tbody *ngFor="let item of tmpcropdata">
                                                    <tr class="thead-light">
                                                        <th scope="col">{{ item.cropname }}</th>
                                                        <th style="text-align: center;" *ngFor="let pk of packingarray ; let i=index;" scope="col">
                                                            {{ pk.name }}
                                                        </th>
                                                    </tr>
                                                    <tr *ngFor="let item1 of cropinfo | filter: searchbybreedtext;">
                                                        <div *ngIf="item.id==item1.id && item1.statu==1;then content"></div>
                                                        <ng-template #content>
                                                            <th scope="row">{{ item1.breedname }}</th>
                                                            <td style="text-align: center;"> <input class="chkcropinput" [disabled]="true" [checked]="item1.Box==1" type="checkbox"></td>
                                                            <td style="text-align: center;"> <input class="chkcropinput" [disabled]="true" [checked]="item1.Loose==1" type="checkbox"></td>
                                                            <td style="text-align: center;"> <input class="chkcropinput" [disabled]="true" [checked]="item1.Punnet==1" type="checkbox"></td>
                                                        </ng-template>

                                                    </tr>

                                                    <div *ngIf="item.count==0;then content1"></div>
                                                    <ng-template #content1>
                                                        <th scope="row">-</th>
                                                        <td style="text-align: center;"> <input class="chkcropinput" type="checkbox"></td>
                                                        <td style="text-align: center;"> <input class="chkcropinput" type="checkbox"></td>
                                                        <td style="text-align: center;"> <input class="chkcropinput" type="checkbox"></td>
                                                    </ng-template>
                                                </tbody>
                                            </table>


                                            <div style="display: none;">
                                                <div id="search-box-div">
                                                    <div id="search-field" class="top-div">
                                                        <input #input type="text" class="form-control" placeholder="Filter by Crop" [(ngModel)]="searchbycroptext">
                                                    </div>
                                                </div>

                                                <div *ngFor="let item of tmpcropdata">
                                                    <ul *ngIf="item.statu==0">
                                                        <p class="leftdata"><strong>{{ item.cropname }}</strong></p>
                                                        <p *ngFor="let pk of packingarray; let i=index;" [ngClass]="(i== 0||i==1) ? 'leftdata' : ''">{{ pk.name }}</p>
                                                        <div *ngIf="item.count>0">
                                                            <div *ngFor="let item1 of cropinfo | filter: searchbybreedtext;">
                                                                <div *ngIf="item.id==item1.id && item1.statu==1">
                                                                    <li>
                                                                        <span class="leftdata">{{ item1.breedname }}</span>
                                                                        <span class="leftdata" *ngFor="let pk of packingarray">
                                      <input type="checkbox"
                                        (change)="packingselect($event,pk.id,item.id,item1.breedid)">
                                    </span>

                                                                    </li>
                                                                </div>

                                                            </div>
                                                        </div>
                                                        <div *ngIf="item.count==0">
                                                            <div>
                                                                <li>
                                                                    <span class="leftdata"></span>
                                                                    <span class="leftdata" *ngFor="let pk of packingarray">
                                    <input type="checkbox" (change)="packingselect($event,pk.id,item.id,0)">
                                  </span>
                                                                </li>
                                                            </div>
                                                        </div>

                                                    </ul>
                                                </div>
                                            </div>
                                        </div>


                                    </div>
                                    <div *ngIf="iscrop">
                                        <button (click)='addcrop(iscrop)' style="float: right;" class="btn btn-primary mt-5">
                      <span>Done</span>
                    </button>
                                    </div>
                                    <div *ngIf="!iscrop">
                                        <button (click)='tabChange(2)' style="float: right;" class="btn btn-secondary mt-5">
                      <span>Next</span>
                    </button>
                                        <button (click)='tabChange(0)' style="float: right;" class="btn btn-info mt-5 mr-2">
                      <span>Previous</span>
                    </button>
                                    </div>


                                </ng-template>
                            </li>
                            <li [ngbNavItem]="2" [destroyOnHide]="false" [disabled]="threeli">
                                <a ngbNavLink class=" rounded">
                                    <div class="text-center pt-1 pb-1">
                                        <h4 class="title font-weight-normal mb-0">Buy Interstate</h4>
                                    </div>
                                </a>
                                <ng-template ngbNavContent>
                                    <div class="profiletablecheckbox mt-4">
                                        <div class="row">
                                            <div class="col-lg-4">
                                                <label style="font-size: 14px; font-weight: 700;">Do you buy interstate at this moment?</label>
                                            </div>
                                            <div class="col-lg-8">
                                                <div class="check">
                                                    <label class="mr-3">
                            <button [ngClass]="sell_interstate==1 ? 'btn-primary' : 'btn-light'" class="btn btn-sm"
                              (click)="modelopen(content,1)"> Yes </button>
                            <!-- <input type="radio" (change)="modelopen(content)" [(ngModel)]="sell_interstate"
                              name="sell_interstate" [value]="1" />
                            Yes -->
                          </label>
                                                    <label class="">
                            <button (click)="modelnoopen(0)"
                              [ngClass]="sell_interstate==0 ? 'btn-primary' : 'btn-light'" class="btn btn-sm"> No
                            </button>
                            <!-- <input type="radio" [(ngModel)]="sell_interstate" name="sell_interstate" [value]="0" />
                            No -->
                          </label>
                                                </div>
                                            </div>
                                        </div>



                                    </div>
                                    <button (click)='tabChange(3)' style="float: right;" class="btn btn-secondary mt-5">
                    <span>Next</span>
                  </button>
                                    <button (click)='tabChange(1)' style="float: right;" class="btn btn-info mt-5 mr-2">
                    <span>Previous</span>
                  </button>
                                </ng-template>
                            </li>

                            <li [ngbNavItem]="3" [destroyOnHide]="false" [disabled]="forli">
                                <a ngbNavLink class=" rounded">
                                    <div class="text-center pt-1 pb-1">
                                        <h4 class="title font-weight-normal mb-0">Certificates</h4>
                                    </div>
                                </a>
                                <ng-template ngbNavContent>

                                    <div class="card certificates mt-4">
                                        <div class="card-body">
                                            <div class="row" *ngFor="let item of certificatearray">
                                                <div class="col">
                                                    {{ item.name }}
                                                </div>
                                                <div class="col text-center">
                                                    <input class="chkcropinput" [checked]="item.checkedval==1" type="checkbox" (change)="certiselect($event,item.id)">
                                                </div>
                                                <div class="col text-right">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- <button (click)="saveimg()">Save</button> -->
                                    <button (click)='onSubmit()' style="float: right;" class="btn btn-primary mt-5">
                    <span>Submit</span>
                    <div class="spinner-border" *ngIf="isSpinner" role="status">
                      <span class="sr-only">Loading...</span>
                    </div>
                  </button>
                                    <button (click)='tabChange(2)' style="float: right;" class="btn btn-info mt-5 mr-2">
                    <span>Previous</span>
                    <!-- <div class="spinner-border" *ngIf="isSpinner" role="status">
                      <span class="sr-only">Loading...</span>
                    </div> -->
                  </button>

                                </ng-template>
                            </li>


                        </ul>
                        <div [ngbNavOutlet]="nav" class="pt-2"></div>


                    </div>

                    <div class="card-body" *ngIf="!isEdit">
                        <h5 class="mt-4">Personal Details :</h5>
                        <div class="row">
                            <div class="col-lg-4 mt-3">
                                <div class="media align-items-center rounded shadow p-3">
                                    <i-feather name="mail" class="fea icon-ex-md text-muted mr-3"></i-feather>
                                    <div class="media-body text-truncate">
                                        <h6 class="text-primary mb-0">Email :</h6>
                                        <a href="javascript:void(0)" class="text-muted">{{ profiledetails.email }}</a>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 mt-3">
                                <div class="media align-items-center rounded shadow p-3">
                                    <i-feather name="users" class="fea icon-ex-md text-muted mr-3"></i-feather>
                                    <div class="media-body">
                                        <h6 class="text-primary mb-0">Name :</h6>
                                        <a href="javascript:void(0)" class="text-muted">{{ profiledetails.name }}</a>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 mt-3">
                                <div class="media align-items-center rounded shadow p-3">
                                    <i-feather name="home" class="fea icon-ex-md text-muted mr-3"></i-feather>
                                    <div class="media-body">
                                        <h6 class="text-primary mb-0">Company Name :</h6>
                                        <a href="javascript:void(0)" class="text-muted">{{ profiledetails.companyname }}</a>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 mt-3">
                                <div class="media align-items-center rounded shadow p-3">
                                    <i-feather name="map" class="fea icon-ex-md text-muted mr-3"></i-feather>
                                    <div class="media-body">
                                        <h6 class="text-primary mb-0">State :</h6>
                                        <a href="javascript:void(0)" class="text-muted">{{ profiledetails.statename }}</a>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 mt-3">
                                <div class="media align-items-center rounded shadow p-3">
                                    <i-feather name="map-pin" class="fea icon-ex-md text-muted mr-3"></i-feather>
                                    <div class="media-body">
                                        <h6 class="text-primary mb-0">City :</h6>
                                        <a href="javascript:void(0)" class="text-muted">{{ profiledetails.cityname }}</a>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 mt-3">
                                <div class="media align-items-center rounded shadow p-3">
                                    <i-feather name="map-pin" class="fea icon-ex-md text-muted mr-3"></i-feather>
                                    <div class="media-body">
                                        <h6 class="text-primary mb-0">Suburb :</h6>
                                        <a href="javascript:void(0)" class="text-muted">{{ profiledetails.suburbname }}</a>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 mt-3">
                                <div class="media align-items-center rounded shadow p-3">
                                    <i-feather name="map-pin" class="fea icon-ex-md text-muted mr-3"></i-feather>
                                    <div class="media-body">
                                        <h6 class="text-primary mb-0">Post code :</h6>
                                        <a href="javascript:void(0)" class="text-muted">{{ profiledetails.postcode }}</a>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 mt-3">
                                <div class="media align-items-center rounded shadow p-3">
                                    <i-feather name="phone" class="fea icon-ex-md text-muted mr-3"></i-feather>
                                    <div class="media-body">
                                        <h6 class="text-primary mb-0">Mobile Number :</h6>
                                        <a href="javascript:void(0)" class="text-muted">{{ profiledetails.phone }}</a>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <h5 class="mt-4" *ngIf="id && tmpcropdata.length>0 && (this.usertype==1 ||this.usertype==2)">Crop information :</h5>

                        <div *ngIf="id && tmpcropdata.length>0 && (this.usertype==1 ||this.usertype==2)" class="row">
                            <div *ngIf="tmpcropdata.length>0 && (this.usertype==1 ||this.usertype==2)" class="col-md-12">
                                <table class="table cropinfo">
                                    <tbody *ngFor="let item of tmpcropdata">
                                        <tr class="thead-light">
                                            <th scope="col">{{ item.cropname }}</th>
                                            <th style="text-align: center;" *ngFor="let pk of packingarray ; let i=index;" scope="col">
                                                {{ pk.name }}
                                            </th>
                                        </tr>
                                        <tr *ngFor="let item1 of cropinfo | filter: searchbybreedtext;">
                                            <div *ngIf="item.id==item1.id && item1.statu==1;then content"></div>
                                            <ng-template #content>
                                                <th scope="row">{{ item1.breedname }}</th>
                                                <td style="text-align: center;"> <input class="chkcropinput" [disabled]="true" [checked]="item1.Box==1" type="checkbox"></td>
                                                <td style="text-align: center;"> <input class="chkcropinput" [disabled]="true" [checked]="item1.Loose==1" type="checkbox"></td>
                                                <td style="text-align: center;"> <input class="chkcropinput" [disabled]="true" [checked]="item1.Punnet==1" type="checkbox"></td>
                                            </ng-template>

                                        </tr>

                                        <div *ngIf="item.count==0;then content1"></div>
                                        <ng-template #content1>
                                            <th scope="row">-</th>
                                            <td style="text-align: center;"> <input class="chkcropinput" type="checkbox"></td>
                                            <td style="text-align: center;"> <input class="chkcropinput" type="checkbox"></td>
                                            <td style="text-align: center;"> <input class="chkcropinput" type="checkbox"></td>
                                        </ng-template>
                                    </tbody>
                                </table>


                                <div style="display: none;">
                                    <div id="search-box-div">
                                        <div id="search-field" class="top-div">
                                            <input #input type="text" class="form-control" placeholder="Filter by Crop" [(ngModel)]="searchbycroptext">
                                        </div>
                                    </div>

                                    <div *ngFor="let item of tmpcropdata">
                                        <ul *ngIf="item.statu==0">
                                            <p class="leftdata"><strong>{{ item.cropname }}</strong></p>
                                            <p *ngFor="let pk of packingarray; let i=index;" [ngClass]="(i== 0||i==1) ? 'leftdata' : ''">{{ pk.name }}</p>
                                            <div *ngIf="item.count>0">
                                                <div *ngFor="let item1 of cropinfo | filter: searchbybreedtext;">
                                                    <div *ngIf="item.id==item1.id && item1.statu==1">
                                                        <li>
                                                            <span class="leftdata">{{ item1.breedname }}</span>
                                                            <span class="leftdata" *ngFor="let pk of packingarray">
                                <input type="checkbox" (change)="packingselect($event,pk.id,item.id,item1.breedid)">
                              </span>

                                                        </li>
                                                    </div>

                                                </div>
                                            </div>
                                            <div *ngIf="item.count==0">
                                                <div>
                                                    <li>
                                                        <span class="leftdata"></span>
                                                        <span class="leftdata" *ngFor="let pk of packingarray">
                              <input type="checkbox" (change)="packingselect($event,pk.id,item.id,0)">
                            </span>
                                                    </li>
                                                </div>
                                            </div>

                                        </ul>
                                    </div>
                                </div>
                            </div>


                        </div>

                        <div *ngIf="this.usertype==1 ||this.usertype==2" class="profiletablecheckbox mt-4">
                            <div class="row">
                                <div class="col-lg-4">
                                    <label style="font-size: 14px; font-weight: 700;">Do you buy interstate at this moment?</label>
                                </div>
                                <div class="col-lg-8">
                                    <div class="check">
                                        <label class="mr-3">
                        <button [ngClass]="sell_interstate==1 ? 'btn-primary' : 'btn-light'" class="btn btn-sm"> Yes </button>
                      </label>
                                        <label class="mr-3">
                        <button 
                          [ngClass]="sell_interstate==0 ? 'btn-primary' : 'btn-light'" class="btn btn-sm"> No
                        </button>
                      </label>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <!-- <h5 class="mt-4" *ngIf="id && certificatearray.length>0 && (this.usertype==1 ||this.usertype==2)">Certificates:</h5>
            <div *ngIf="certificatearray.length>0  && (this.usertype==1 ||this.usertype==2)" class="row Certificate">
              <div class="col-lg-3 col-sm-6 mt-3" *ngFor="let item of certificatearray">
                <a href="javascript: void(0);" *ngIf="item.imageurl" class="text-dark">
                  <div class="key-feature p-3 rounded shadow">
                    <div class="content">
                      <h5 class="mb-0">{{ item.name }}</h5>
                    </div>
                  </div>
                </a>
              </div>
            </div> -->

                        <h5 class="mt-4" *ngIf="id && productlist.length>0 && this.usertype==3">Product:</h5>

                        <div class="row" *ngIf="id && productlist.length>0 && this.usertype==3" style="overflow: auto;">
                            <div class="col-md-12">
                                <table class="table">
                                    <thead class="thead-light">
                                        <tr>
                                            <th scope="col">Name</th>
                                            <th scope="col">Price</th>
                                            <th scope="col">Discount</th>
                                            <th scope="col">Offer</th>
                                            <th scope="col">Wholesale price</th>
                                            <th scope="col">Retail price</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let item of productlist">
                                            <th scope="row">{{ item.name }}</th>
                                            <td>{{ item.price }}</td>
                                            <td>{{ item.discount }}</td>
                                            <td>{{ item.promotional }}</td>
                                            <td>{{ item.wholesaleprice }}</td>
                                            <td>{{ item.retailprice }}</td>

                                        </tr>

                                    </tbody>
                                </table>
                            </div>
                        </div>




                    </div>

                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row-->
    </div>
    <!--end container-->
</section>
<!--end section-->
<!-- Profile Setting End -->



<!-- ModalBreed Content Start -->
<ng-template #breedcontent let-modal>
    <div class="modal-header">
        <h5 class="modal-title" id="modal-basic-title">Breed</h5>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
    </div>
    <div class="modal-body">
        <div class="bg-white rounded box-shadow">
            <div class="spinner-border" *ngIf="isbreedSpinner" role="status">
                <span class="sr-only">Loading...</span>
            </div>
            <table class="table">
                <thead class="thead-light">
                    <tr>
                        <th scope="col">Breed Name</th>
                        <th style="text-align: center;" *ngFor="let pk of packingarray ; let i=index;" scope="col">{{ pk.name }}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <div *ngIf="breedlist.length>0;then content else other_content">here is ignored</div>
                    <ng-template #content>
                        <tr *ngFor="let item of breedlist">
                            <th scope="row">{{ item.breedname }}</th>
                            <td style="text-align: center;"> <input class="chkcropinput" (change)="packingselect($event,item.id,1)" [checked]="item.Box==1" type="checkbox"></td>
                            <td style="text-align: center;"> <input class="chkcropinput" (change)="packingselect($event,item.id,2)" [checked]="item.Loose==1" type="checkbox"></td>
                            <td style="text-align: center;"> <input class="chkcropinput" (change)="packingselect($event,item.id,3)" [checked]="item.Punnet==1" type="checkbox"></td>
                        </tr>
                    </ng-template>
                    <ng-template #other_content>
                        <tr>
                            <th scope="row">-</th>
                            <td style="text-align: center;"> <input class="chkcropinput" (change)="packingselect($event,0,1)" type="checkbox"></td>
                            <td style="text-align: center;"> <input class="chkcropinput" (change)="packingselect($event,0,2)" type="checkbox"></td>
                            <td style="text-align: center;"> <input class="chkcropinput" (change)="packingselect($event,0,3)" type="checkbox"></td>
                        </tr>
                    </ng-template>

                </tbody>
            </table>

        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline-dark" (click)="closebreed()">Cancel</button>
        <button type="button" class="btn btn-outline-primary d-flex align-items-center justify-content-center" (click)="savebreed(iscrop)">Save
      <div class="spinner-border" *ngIf="issavecropSpinner" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </button>

    </div>
</ng-template>
<!-- Modal Content End -->
<!-- Modal Content Start -->
<ng-template #content let-modal>
    <div class="modal-header">
        <h5 class="modal-title" id="modal-basic-title"></h5>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
    </div>
    <div class="modal-body">
        <table class="table">
            <thead class="thead-light">
                <tr>
                    <th scope="col">State Name</th>
                    <th scope="col"></th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of statelist">
                    <td>{{ item.statename }}</td>
                    <td style="text-align: center;"> <input [checked]="item.checkedval==1" (change)="stateselect($event,item.id)" class="chkcropinput" type="checkbox"></td>
                </tr>

            </tbody>
        </table>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">Cancel</button>
        <button type="button" class="btn btn-outline-primary" (click)="modal.dismiss('Cross click')">Save</button>

    </div>
</ng-template>
<!-- Modal Content End -->
<!-- Modal Contact Buyer Start -->
<ng-template #contactGrower let-modal>
    <div class="modal-header">
        <h5 class="modal-title" id="modal-basic-title">Contact to {{ isadmin }} <span *ngIf="isadmin==1">,</span> {{ isgrower }} <span *ngIf="isbuyer==1">,</span> {{ isbuyer }} <span *ngIf="isvendor==1">,</span> {{ isvendor }}</h5>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
    </div>
    <div class="modal-body">
        <textarea class="form-control" maxlength="50" placeholder="Type your message...(Max. 50 chars)" style="min-width: 100%" rows="3" [(ngModel)]="messagetxt"></textarea>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-dark" (click)="modal.close('Save click')">Cancel</button>
        <button type="button" [disabled]="!messagetxt" class="btn btn-primary" (click)="modelmsgsend()">Send</button>

    </div>
</ng-template>
<!-- Modal Content End -->
<!-- Back to top -->
<a href="javascript: void(0);" class="btn btn-icon btn-soft-primary back-to-top" id="back-to-top" [ngxScrollTo]="'#home'">
    <i-feather name="arrow-up" class="icons"></i-feather>
</a>
<!-- Back to top -->
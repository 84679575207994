import { Platform } from '@angular/cdk/platform';
import { Component, HostListener } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
declare let window: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'landrick-angular';
  showButton: boolean = false;
  deferredPrompt: any;
  isAndroid: boolean;
  isIOS: boolean;
  isFooter: string;
  appid = 'da9f1209-c5c2-46a7-9e43-244224d3e364';

  constructor(private router: Router, private platform: Platform) {

   // this.onesiganl()
    /**
     * Unicons icon refreshed on route change.
     */
    router.events.forEach((event) => {
      if (event instanceof NavigationEnd) {
        window['Unicons']['refresh']();
      }
    });
  }
  // @HostListener('window:beforeinstallprompt', ['$event'])
  // onbeforeinstallprompt(e) {
  //   console.log(e);
  //   // Prevent Chrome 67 and earlier from automatically showing the prompt
  //   e.preventDefault();
  //   // Stash the event so it can be triggered later.
  //   this.deferredPrompt = e;
  //   this.showButton = true;
  //   let displayMode = 'browser';
  //   const mqStandAlone = '(display-mode: standalone)';

  // }
  // onesiganl() {
  //   var OneSignal = window['OneSignal'] || [];

  //   console.log("Init OneSignal");

  //   OneSignal.push(["init", {

  //     appId: this.appid,
  //     autoRegister: false,
  //     allowLocalhostAsSecureOrigin: true,
  //     notifyButton: {
  //       enable: false
  //     }
  //   }]);

  //   console.log('OneSignal Initialized');

  //   OneSignal.push(function () {

  //     console.log('Register For Push');
  //     OneSignal.push(["registerForPushNotifications"])

  //     OneSignal.getUserId().then((userId: any) => {
  //       console.log('4. User ID is', userId);
  //       //_oneSignalId = userId;
  //       //_isSubscribed = true;
  //     });

  //   });

  //   OneSignal.push(function () {
  //     // Occurs when the user's subscription changes to a new value.
  //     OneSignal.on('subscriptionChange', (isSubscribed: any) => {
  //       console.log("The user's subscription state is now:", isSubscribed);
  //       //_isSubscribed = isSubscribed;
  //       OneSignal.getUserId().then((userId: any) => {
  //         console.log("User ID is", userId);
  //        // _oneSignalId = userId;
  //       });
  //     });
  //   });

  // }

  // public initPwaPrompt() {
  //   if (this.platform.ANDROID || this.platform.isBrowser || this.platform.FIREFOX || this.platform.EDGE || this.platform.BLINK) {
  //     this.isAndroid = true;
  //     this.isIOS = false
  //   }
  //   if (this.platform.IOS || this.platform.SAFARI) {
  //     this.isAndroid = false;
  //     this.isIOS = true;
  //   }
  // }
  // addToHomeScreen() {
  //   // hide our user interface that shows our A2HS button
  //   this.showButton = false;
  //   // Show the prompt
  //   this.deferredPrompt.prompt();
  //   // Wait for the user to respond to the prompt
  //   this.deferredPrompt.userChoice
  //     .then((choiceResult) => {
  //       if (choiceResult.outcome === 'accepted') {
  //         console.log('User accepted the A2HS prompt');
  //         this.close();
  //       } else {
  //         console.log('User dismissed the A2HS prompt');
  //       }
  //       this.deferredPrompt = null;

  //     });
  // }
  // close() {
  //   this.isFooter = '0';
  //   localStorage.setItem('footer', '0');
  // }

}

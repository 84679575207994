
<!-- <app-switcher></app-switcher> -->
<div *ngIf="isAndroid">
    <div class="footer-nav" *ngIf="isFooter=='1'">
        <p (click)="addToHomeScreen()" id="img_homescreen"></p> <span (click)="close()"><i class="fas fa-times" aria-hidden="true"></i></span>
    </div>
</div>
<div *ngIf="isIOS">
    <div class="footer-nav" *ngIf="isFooter=='1'">
        <p  id="img_homescreenIOS"></p> <span (click)="close()"><i class="fas fa-times" aria-hidden="true"></i></span>
    </div>
</div>
<router-outlet></router-outlet>

<modal-outlet></modal-outlet>

<app-carousel></app-carousel>  


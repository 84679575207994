import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../service/auth.service';
import { PostService } from '../service/post.service';
import { StorageService } from '../service/storage.service';

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss']
})
export class ChatComponent implements OnInit {

  IsDisplaymsg: boolean = false
  userlist: any = [];
  chatlist: any = [];
  userid: any;
  username: any;
  sendmessage: string;
  id: any;
  isSpinnerMsg:boolean=false;
  conversionId: any;
  searchbycroptext:string;
  isSubscribe: boolean;
  isadmin: string;
  admin: any=0;
  constructor(public postservice: PostService, public storageService: StorageService,private cdr: ChangeDetectorRef,private _authService:AuthService,private _router:Router) { }

  ngOnInit() {
    this.storageService.get('logininfo').then(
      (res) => {
        console.log(res);
        this.userid = res.id;
        if (res.id) {
          this.isadmin = res.admin;
          this.modelmsgsend();
          this.getsubscriptionbyuser();
          // this.name = res.username;
         
          // this.isgrower = res.grower == 1 ? 'Grower' : '';
          // this.isbuyer = res.buyer == 1 ? 'Buyer' : '';
          // this.isvendor = res.vendor == 1 ? 'Vender' : '';

        }
      }
    );
  }
  getsubscriptionbyuser() {
    let data = {
      id: this.userid
    };
    this._authService.getsubscriptionbyuser(data)
      .subscribe((data: any) => {
        console.log(data.success);
        
        if(data.success.length>0){
          this.isSubscribe = true;
        }
        
        
      });
  }
  gosubscribe(){
    this._router.navigateByUrl('/growersubscription');
  }
  back(val){
    if (val == true) {
      this.IsDisplaymsg = false;
    } else {
      this.IsDisplaymsg = true;
    }
  }
  IsDisplay(val, item) {
    
    if (val == true) {
      this.IsDisplaymsg = false;
    } else {
      this.IsDisplaymsg = true;
    }
    this.getchat(item)
    this.username = item.name;
  }
 
  modelmsgsend() {
    this.isSpinnerMsg=true;
    let data = {
      userid: this.userid,
      type:this.isadmin
    }
    this.postservice.getuserbychat(data)
      .subscribe((data: any) => {
        console.log(data);
         
        this.isSpinnerMsg=false;
        let id= data.success[0].fromid==this.userid?data.success[0].toid:data.success[0].fromid
        
        this.id = data.success[0].toid;
        this.conversionId = data.success[0].conversionId
        this.username = data.success[0].name;
        this.userlist = data.success;
        
        let val={
          conversionId:this.conversionId,
          id:id
        }
        this.getchat(val)
        this.cdr.detectChanges();

      });
  }
  getchat(item) {
    
    this.conversionId = item.conversionId
    this.admin=item.admin?1:0
    if(item.admin==1){
      this.id=item.fromid;
    }else{
      this.id= item.toid ?item.toid:item.id?item.id:item.userdid;

    }
    let data = {
      conversionId: this.conversionId,
    }
    this.postservice.getchat(data)
      .subscribe((data: any) => {
        console.log(data);
        // 
        this.isSpinnerMsg=false;
        this.chatlist = data.success;
      });
  }
  sendmsg() {
    this.isSpinnerMsg=true;
    let data = {
      message: this.sendmessage,
      fromid: this.userid,
      toid: this.id,
      conversionId: this.conversionId,
      status: 0,
    }
    
    this.postservice.sendmessage(data)
      .subscribe((data: any) => {
        console.log(data);
        this.sendmessage="";
        this.isSpinnerMsg=false;
        // 
        let val={
          conversionId:this.conversionId?this.conversionId:data['success'][0].conversionId,
          id:this.id
        }
        this.getchat(val)
      });
  }

}

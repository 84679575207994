<!-- Company Detail Start -->
<section class="section">
  <div class="container">
    <div class="messaging">
      <div class="inbox_msg">
        <div class="inbox_people">
          <div class="headind_srch">
            <div class="recent_heading dashtop">
              <h4>{{ username?username:"All" }}</h4>
            </div>
            <div class="recent_heading mobile">
              <h4 (click)="back(IsDisplaymsg)"> <i *ngIf="IsDisplaymsg" class="uil uil-arrow-left"></i> {{ username }}
              </h4>
            </div>
            <div class="srch_bar">
              <div class="stylish-input-group">
                <input type="text" [(ngModel)]="searchbycroptext" class="search-bar" placeholder="Search">
                <span class="input-group-addon">
                  <button type="button"> <i class="fa fa-search" aria-hidden="true"></i> </button>
                </span>
              </div>
            </div>
          </div>
          <div class="inbox_chat dashtop">
            <!--For active-active_chat-->
            <div *ngIf="( userlist | filter : searchbycroptext) as result">
              <div class="chat_list" *ngFor="let item of userlist | filter: searchbycroptext;">
                <div class="chat_people" (click)="IsDisplay(IsDisplaymsg,item)">
                  <div class="chat_img"> <img src="{{ item.image }}"
                      onerror="this.onerror=null;this.src='assets/images/user/user.png';" alt="sunil"> </div>
                  <div class="chat_ib">
                    <h5>{{ item.name }} <span class="chat_date">{{ updated_at | date: 'dd/MM/yyyy'}}</span></h5>
                    <!-- <p>Test</p> -->
                  </div>
                </div>
              </div>
              <p class="mt-2" *ngIf="result.length === 0" style="text-align: center;">No User Available</p>
            </div>

          </div>
          <div *ngIf="!IsDisplaymsg" class="inbox_chat mobile">
            <!--For active-active_chat-->

            <div *ngIf="( userlist | filter : searchbycroptext) as result">
              <div class="chat_list" *ngFor="let item of userlist | filter: searchbycroptext;">
                <div class="chat_people" (click)="IsDisplay(IsDisplaymsg,item)">
                  <div class="chat_img"> <img src="{{ item.image }}"
                      onerror="this.onerror=null;this.src='assets/images/user/user.png';" alt="sunil"> </div>
                  <div class="chat_ib">
                    <h5>{{ item.name }} <span class="chat_date">{{ updated_at | date: 'dd/MM/yyyy'}}</span></h5>
                    <p>Test</p>
                  </div>
                </div>
              </div>
              <p class="mt-2" *ngIf="result.length === 0" style="text-align: center;">No User Available</p>
            </div>

          </div>
        </div>
        <div [ngClass]="IsDisplaymsg ? 'd-block' : ''" class="mesgs">
          <div class="msg_history" #window [scrollTop]="window.scrollHeight">
            <div class="spinner-border" *ngIf="isSpinnerMsg" role="status">
              <span class="sr-only">Loading...</span>
            </div>
            <div *ngFor="let item of chatlist">
              <div class="incoming_msg" *ngIf="item.fromid!=userid">
                <div class="incoming_msg_img"> <img src="https://ptetutorials.com/images/user-profile.png" alt="sunil">
                </div>
                <div class="received_msg">
                  <div class="received_withd_msg">
                    <p>{{ item.message }}</p>
                    <span class="time_date">{{ item.created_at| date:'dd/MM/yyyy HH:mm' }}</span>
                  </div>
                </div>
              </div>
              <div class="outgoing_msg" *ngIf="item.fromid==userid">
                <div class="sent_msg">
                  <p>{{ item.message }}</p>
                  <span class="time_date"> {{ item.created_at| date:'dd/MM/yyyy HH:mm' }}</span>
                </div>
              </div>

            </div>
          </div>
          <div class="type_msg">
      
            <div class="input_msg_write" *ngIf="(isSubscribe || isadmin) || admin">
              <input type="text" maxlength="50" [(ngModel)]="sendmessage" class="write_msg"
                placeholder="Type a message...(Max. 50 chars)" />
              <button class="msg_send_btn" (click)="sendmsg()" type="button"><i class="uil uil-message"
                  aria-hidden="true"></i> 
                <div class="spinner-border" *ngIf="isSpinnerMsg" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
              </button>
            </div>
            <div class="input_msg_write" *ngIf="!isSubscribe && !isadmin && admin==0">
              <a (click)="gosubscribe()"  class="btn btn-success btn-sm mr-2 mt-2">Subscribe to Message </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>




</section>

<!-- Back to top -->
<a href="javascript: void(0);" class="btn btn-icon btn-soft-primary back-to-top" id="back-to-top"
  [ngxScrollTo]="'#home'">
  <i-feather name="arrow-up" class="icons"></i-feather>
</a>
<!-- Back to top -->
<div class="back-to-home rounded d-none d-sm-block">
  <a routerLink="/index" class="btn btn-icon btn-soft-primary">
    <i-feather name="home" class="icons"></i-feather>
  </a>
</div>
<!-- Hero Start -->
<section class="bg-home d-flex align-items-center p-0" style="height: 100vh;">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-lg-7 col-md-6">
        <div class="mr-lg-5 loginlogo">
          <img src="assets/images/user/logo-dark.png" class="img-fluid d-block mx-auto mb-2 mt-2" alt="">
        </div>
      </div>
      <div class="col-lg-5 col-md-6">
        <div class="card login-page bg-white shadow rounded border-0">
          <div class="card-body">
            <h4 class="card-title text-center">Login</h4>
            <form [formGroup]="loginForm"  class="login-form mt-4">
              <div class="row">
                <div class="col-lg-12">
                  <div class="form-group position-relative">
                    <label>Your Email <span class="text-danger">*</span></label>
                    <i-feather name="user" class="fea icon-sm icons"></i-feather>
                    <input type="email" class="form-control pl-5" placeholder="Email" formControlName="email"  [ngClass]="{ 'is-invalid': submitted && f.email.errors }">
                    <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                      <div *ngIf="f.email.errors.required">Email is required</div>
                      <div *ngIf="f.email.errors.email">Email must be a valid email address</div>
                    </div>
                  </div>
                </div>

                <div class="col-lg-12">
                  <div class="form-group position-relative">
                    <label>Password <span class="text-danger">*</span></label>
                    <i-feather name="key" class="fea icon-sm icons"></i-feather>
                    <input type="password" formControlName="password" class="form-control pl-5" placeholder="Password" [ngClass]="{ 'is-invalid': submitted && f.password.errors }">
                    <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                      <div *ngIf="f.password.errors.required">Password is required</div>
                      <div *ngIf="f.password.errors.minlength">Password must be at least 6 characters</div>
                    </div>
                  </div>
                </div>

                <div class="col-lg-12">
                  <div class="d-flex justify-content-between">
                    <div class="form-group">
                      <div class="custom-control custom-checkbox">
                        <input type="checkbox" class="custom-control-input" id="customCheck1">
                        <label class="custom-control-label" for="customCheck1">Remember me</label>
                      </div>
                    </div>
                    <p class="forgot-pass mb-0"><a routerLink="/auth-re-password"
                        class="text-dark font-weight-bold">Forgot
                        password ?</a></p>
                  </div>
                </div>
                <div class="col-lg-12 loginbtn mb-0">
                  <button (click)="onSubmit()" class="btn btn-primary btn-block d-flex align-items-center justify-content-center">                   
                    <span style="margin-right: 10px;"> Sign in</span> 
                    <div class="spinner-border" *ngIf="isSpinner" role="status">
                      <span class="sr-only">Loading...</span>
                    </div>
                  </button>
                </div>
                <!-- <div class="col-lg-12 mt-4 text-center">
                  <h6>Or Login With</h6>
                  <ul class="list-unstyled social-icon mb-0 mt-3">
                    <li class="list-inline-item"><a href="javascript:void(0)" class="rounded">
                        <i-feather name="facebook" class="fea icon-sm fea-social"></i-feather>
                      </a></li>
                    <li class="list-inline-item ml-1"><a href="javascript:void(0)" class="rounded">
                        <i-feather name="github" class="fea icon-sm fea-social"></i-feather>
                      </a></li>
                    <li class="list-inline-item ml-1"><a href="javascript:void(0)" class="rounded">
                        <i-feather name="twitter" class="fea icon-sm fea-social"></i-feather>
                      </a></li>
                    <li class="list-inline-item ml-1"><a href="javascript:void(0)" class="rounded">
                        <i-feather name="gitlab" class="fea icon-sm fea-social"></i-feather>
                      </a></li>
                  </ul>
                 
                </div> -->
                <div class="col-12 text-center">
                  <p class="mb-0 mt-3"><small class="text-dark mr-2">Don't have an account ?</small> <a
                      routerLink="/auth-signup" class="text-dark font-weight-bold">Sign Up</a></p>
                </div>
              </div>
            </form>
          </div>
        </div>
        <!---->
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- Hero End -->

import { StorageService } from './../../service/storage.service';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/service/auth.service';
import { ToastrService } from 'ngx-toastr';
import { PostService } from 'src/app/service/post.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
//Wizard
@Component({
  selector: 'app-vendorprofile',
  templateUrl: './vendorprofile.component.html',
  styleUrls: ['./vendorprofile.component.scss']
})
export class VendorprofileComponent implements OnInit {
  navClass = 'nav-light';
  id: any;
  profiledetails={ 
    name: "",
    companyname: "",
    email: "",
    phone: "",
    postcode: "",
    statename:"",
    cityname:"",
    suburbname: "",
	  retai_whole:""
	}
  searchbycroptext: string;
  searchbybreedtext: string;
  registergrowerForm: FormGroup;
  productForm: FormGroup;
  submitted = false;
  isSpinner = false;
  isEdit = false;
  subamount = 0;
  urls = [];
  subscriptionlist = [];
  name: any;
  isadmin: any;
  isgrower: any;
  isbuyer: any;
  isvendor: any;
  certificatearray: any = [];
  growing_environment: any = [];
  growing_medium: any = [];
  availability_produce: any = [];
  statelist: any = [];
  suburbslist: any = [];
  citylist: any = [];
  userid: any;
  cropinfo: any = [];
  tmpcropdata: any = [];
  growercertificate: any = [];
  tmppackingarray: any = [];
  packingarray: any = [{ id: 1, name: 'Box' }, { id: 2, name: 'Loose' }, { id: 3, name: 'Punnet' }];
  //Filed
  growing_environment_id: any;
  growing_medium_id: any;
  availability_produce_id: any;
  sell_interstate: any;
  storage: any;

  imageurl: [''];
  subscriptioid: number;
  striptoken: any;
  isDatarequired = false;
  errormsg: string;
  active = 0;
  disabled = false;
  FileuploadformData: FormData;
  ProductuploadformData: FormData;
  oneli = false;
  twoli = true;
  threeli = true;
  forli = true;
  croplist: any = [];
  breedlist: any = [];
  stateselectedarray: any = [];
  isbreedSpinner = true;
  cropid: any;
  breedcount: number = 0;
  iscrop = false;
  isCropSpinner = true;
  issavecropSpinner: boolean = false;
  userpic = "assets/images/user/user.png";
  userSpinner = false;
  categorylist: any = [];
  productfile: any;
  lastid: any;
  produfile: any = [];
  productlist: any = [];
  deleteid: any;
  ispresence:any;
  iswholesaler:any;
  constructor(private formBuilder: FormBuilder, private _authService: AuthService, private _router: Router, private toastr: ToastrService, public activatedRoute: ActivatedRoute, public storageService: StorageService, public postservice: PostService, private modalService: NgbModal, private cdr: ChangeDetectorRef) {
    // var urlval = this.activatedRoute.snapshot.paramMap.get('id');
    // var decodeval = atob(urlval);
    // this.id = decodeval;
    //alert(this.id)
  }


  ngOnInit(): void {
    this.registergrowerForm = this.formBuilder.group({
      name: ['', Validators.required],
      companyname: [''],
      stateid: ['', Validators.required],
      cityid: ['', Validators.required],
      suburbid: ['', Validators.required],
      postcode: ['', Validators.required],
      phone: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      //password: ['', [Validators.required, Validators.minLength(6)]],

    });
    this.productForm = this.formBuilder.group({
      id: [''],
      userid: ['', Validators.required],
      categoryid: ['', Validators.required],
      name: ['', Validators.required],
      description: [''],
      price: ['', Validators.required],
      imgurl: [''],
      discount: ['', Validators.required],
      promotional: ['', Validators.required],
      wholesaleprice: ['', Validators.required],
      retailprice: ['', Validators.required],

    });


    this.getcroplist();
    this.getcategorylist();

    this.storageService.get('logininfo').then(
      (res) => {
        console.log(res);
        this.userid = res.id;
        if (res.id) {
          this.getuserdata();
          this.getstate();
          this.growers_profile();
          //this.getcropinfos();
          // this.getcertificate();
          this.getproduct()
          this.name = res.username;
          this.isadmin = res.admin == 1 ? 'Admin' : '';
          this.isgrower = res.grower == 1 ? 'Grower' : '';
          this.isbuyer = res.buyer == 1 ? 'Buyer' : '';
          this.isvendor = res.vendor == 1 ? 'Vender' : '';

        }
      }
    );

  }
  get f() { return this.registergrowerForm.controls; }
  get p() { return this.productForm.controls; }

  isedit(val) {
    if (val == true) {
      this.isEdit = false;
    } else {
      this.isEdit = true;
    }

  }
  getsubc(event, d) {

    const newVal = event.target.value;
    const adddata = this.subscriptionlist[Number(newVal)];
    this.subscriptioid = adddata.id;
    this.subamount = adddata.amount;
  }
  getuserdata() {
    let data = {
      userid: this.userid
    };
    this.userSpinner = true
    this._authService.getuserdata(data)
      .subscribe((data: any) => {
        console.log(data);
        this.userSpinner = false;

        this.setFormvalue(data);

      });
  }
  setFormvalue(data) {
    this.profiledetails=data
    this.userpic = data.image ? data.image : 'assets/images/user/user.png';
    this.getcity(data.stateid, 1);
    this.getsuburbs(data.stateid, 1);
    this.registergrowerForm.patchValue({
      name: data.name,
      companyname: data.companyname,
      email: data.email,
      postcode: data.postcode,
      phone: data.phone,
      stateid: data.stateid,
      cityid: data.cityid,
      suburbid: data.suburbid,
      iswholesaler: data.iswholesaler,
      ispresence: data.ispresence
    });
  }
  editproduct(id) {
    let data = {
      id: id
    }
    this.postservice.getproductbyid(data)
      .subscribe((data: any) => {
        console.log(data);
        this.productForm.patchValue({
          id:data.success.id,
          categoryid: data.success.categoryid,
          name: data.success.name,
          description: data.success.description,
          price: data.success.price,
          discount: data.success.discount,
          promotional: data.success.promotional,
          wholesaleprice: data.success.wholesaleprice,
          retailprice: data.success.retailprice,

        });
        this.iscrop = true;
      });

  }
  getcategorylist() {
    this.postservice.getcategory()
      .subscribe((data: any) => {
        console.log(data);

        this.categorylist = data.success;
        this.cdr.detectChanges();
      });
  }
  getcroplist() {
    this.postservice.getcrop()
      .subscribe((data: any) => {
        console.log(data);
        this.isCropSpinner = false;
        this.croplist = data.success;
        this.cdr.detectChanges();
      });
  }
  getstate() {
    let data = {
      id: this.userid
    }
    this.postservice.getsateselected(data)
      .subscribe((data: any) => {
        console.log(data);
        this.statelist = data.success;
      });

  }
  getcity(event, type) {
    let newVal1
    if (type == 0) {
      newVal1 = event.target.value;
    } else {
      newVal1 = event;
    }

    const newVal = event;
    let data = {
      state_id: newVal1
    };
    this._authService.getcity(data)
      .subscribe((data: any) => {
        console.log(data);

        this.citylist = data.success;
      });
  }



  getsuburbs(event, type) {
    let newVal
    if (type == 0) {
      newVal = event.target.value;
    } else {
      newVal = event;
    }

    let data = {
      state_id: newVal
    };
    this._authService.getsuburbs(data)
      .subscribe((data: any) => {
        console.log(data);

        this.suburbslist = data.success;
      });
  }

  getcertificate() {
    let data = {
      id: this.userid
    }
    this.postservice.getcertificate_buyerid(data)
      .subscribe((data: any) => {
        console.log(data);
        this.certificatearray = data.success;
      });
  }

  getproduct() {
    let data = {
      id: this.userid
    }
    this.postservice.getproductlist(data)
      .subscribe((data: any) => {
        console.log(data);
        this.productlist = data.success;
      });
  }
  

  growers_profile() {
    let data = {
      id: this.userid
    }
    this.postservice.growers_profile(data)
      .subscribe((data: any) => {
        console.log(data);
        const storage = data.success.storage;
        const iswholesaler = data.success.iswholesaler;
        const ispresence = data.success.ispresence;
        if (storage != 0) {
          this.storage = storage
        }
        this.iswholesaler = iswholesaler
        this.ispresence = ispresence



      });
  }
  getcropinfos() {
    //let tmpcropdata = [];
    this.tmpcropdata = [];
    let data = {
      id: this.userid
    }
    this.postservice.getcropinfo(data)
      .subscribe((data: any) => {
        console.log(data);
        this.cropinfo = data.success;
        for (let i = 0; i < this.cropinfo.length; i++) {
          const element = this.cropinfo[i];
          const statu = this.cropinfo[i].statu;
          if (statu == 0) {
            let data = {
              id: this.cropinfo[i].id,
              cropname: this.cropinfo[i].cropname,
              breedname: this.cropinfo[i].breedname,
              statu: this.cropinfo[i].statu,
              count: 0
            };
            this.tmpcropdata.push(data);
          }
        }
        if (this.tmpcropdata.length > 0) {
          for (let j = 0; j < this.tmpcropdata.length; j++) {
            const elementj = this.tmpcropdata[j];
            let count = 0;

            for (let z = 0; z < this.cropinfo.length; z++) {
              const elementz = this.cropinfo[z];
              if (elementj.id == elementz.id) {
                count++;
              }
            }
            elementj.count = count - 1;
          }
        }
        console.log(this.tmpcropdata);
        ;
      });
  }



  stateselect(event, id) {
    if (event.target.checked == true) {
      let data = {
        stateid: id,
        userid: this.userid,
        chkvalue: 1
      };
      let removeIndex = this.stateselectedarray.findIndex(itm => itm.stateid === id);
      if (removeIndex !== -1) {
        this.stateselectedarray.splice(removeIndex, 1);
      }
      this.stateselectedarray.push(data);
      console.log(this.stateselectedarray);
      ;
    } else {
      let data = {
        stateid: id,
        userid: this.userid,
        chkvalue: 0
      };
      let removeIndex = this.stateselectedarray.findIndex(itm => itm.stateid === id);
      if (removeIndex !== -1) {
        this.stateselectedarray.splice(removeIndex, 1);
      }
      this.stateselectedarray.push(data);

    }
    console.log(this.stateselectedarray);

  }



  uploadprofile(event) {
    if (event.target.files.length > 0) {
      this.FileuploadformData = new FormData();
      const file = event.target.files[0];

      this.FileuploadformData.append("growerid", this.userid);
      this.FileuploadformData.append("profilepic", file);
      this.postservice.profile_imgupload(this.FileuploadformData)
        .subscribe((data: any) => {
          console.log(data);
          ;
        });

    }
  }

  packingselect(values: any, breedid, pkid) {

    console.log(this.tmppackingarray);
    //;
    if (values.currentTarget.checked == true) {
      if (this.tmppackingarray.length > 0) {
        let objIndex = this.tmppackingarray.findIndex(itm => Number(itm.breedid) == Number(breedid));
        if (objIndex !== -1) {
          //Log object to Console.
          console.log("Before update: ", this.tmppackingarray[objIndex])
          // 
          //Update object's name property.
          if (pkid == 1) {
            this.tmppackingarray[objIndex].box = 1
          }
          if (pkid == 2) {
            this.tmppackingarray[objIndex].loos = 1
          }
          if (pkid == 3) {
            this.tmppackingarray[objIndex].punnet = 1
          }


          //Log object to console again.
          console.log("After update: ", this.tmppackingarray[objIndex])
          //


        } else {
          let cropList =
          {
            growerid: this.userid,
            cropid: this.cropid,
            breedid: Number(breedid) != 0 ? Number(breedid) : 0,
            box: pkid === 1 ? 1 : 0,
            loos: pkid === 2 ? 1 : 0,
            punnet: pkid === 3 ? 1 : 0,
          };

          this.tmppackingarray.push(cropList);
        }
      } else {
        let cropList =
        {
          growerid: this.userid,
          cropid: this.cropid,
          breedid: Number(breedid) != 0 ? Number(breedid) : 0,
          box: pkid === 1 ? 1 : 0,
          loos: pkid === 2 ? 1 : 0,
          punnet: pkid === 3 ? 1 : 0,
        };

        this.tmppackingarray.push(cropList);

      }
    } else {

      let removeIndex = this.tmppackingarray.findIndex(itm => Number(itm.breedid) == Number(breedid));
      if (removeIndex !== -1) {
        if ((this.tmppackingarray[removeIndex].box == 0 && this.tmppackingarray[removeIndex].loos == 0) ||
          (this.tmppackingarray[removeIndex].box == 0 && this.tmppackingarray[removeIndex].punnet == 0) ||
          (this.tmppackingarray[removeIndex].loos == 0 && this.tmppackingarray[removeIndex].punnet == 0) ||
          (this.tmppackingarray[removeIndex].loos == 0 && this.tmppackingarray[removeIndex].box == 0) ||
          (this.tmppackingarray[removeIndex].punnet == 0 && this.tmppackingarray[removeIndex].box == 0) ||
          (this.tmppackingarray[removeIndex].punnet == 0 && this.tmppackingarray[removeIndex].loos == 0)) {
          //this.tmppackingarray.splice(removeIndex, 1);
          if (pkid == 1) {
            this.tmppackingarray[removeIndex].box = 0
          }
          if (pkid == 2) {
            this.tmppackingarray[removeIndex].loos = 0
          }
          if (pkid == 3) {
            this.tmppackingarray[removeIndex].punnet = 0
          }
          //
        } else {
          if (pkid == 1) {
            this.tmppackingarray[removeIndex].box = 0
          }
          if (pkid == 2) {
            this.tmppackingarray[removeIndex].loos = 0
          }
          if (pkid == 3) {
            this.tmppackingarray[removeIndex].punnet = 0
          }


        }

      }

      //  let removeIndex = this.tmppackingarray.findIndex(itm => Number(itm.breedid) != 0 ? Number(itm.breedid) : this.cropid === (Number(breedid) != 0) ? Number(breedid) : this.cropid);
      // if (removeIndex !== -1)
      // this.tmppackingarray.splice(removeIndex, 1);
      console.log(this.tmppackingarray);
      ;
    }


  }

  tabChange(evn) {
    let nextId = evn.nextId;
    let activeId = evn.activeId;
    let data = {
      growerid: this.userid,
      name: this.registergrowerForm.value.name,
      companyname: this.registergrowerForm.value.companyname,
      email: this.registergrowerForm.value.email,
      phone: this.registergrowerForm.value.phone,
      postcode: this.registergrowerForm.value.postcode,
      stateid: this.registergrowerForm.value.stateid,
      suburbid: this.registergrowerForm.value.suburbid,
      growing_environment_id: this.growing_environment_id,//v
      growing_medium_id: this.growing_medium_id,//v
      availability_produce_id: this.availability_produce_id,//v
      sell_interstate: this.sell_interstate,//v
      storage: this.storage,//v
      croparray: this.tmppackingarray,//v
      certificatearray: '',
    };

    // this.twoli = true;
    // this.threeli = true;
    ;
    if (evn == 1) {
      if (this.registergrowerForm.invalid) {
        this.toastr.error('All filed are required.');
        return;
      }
      this.active = 1;
    } else if (evn == 2) {
      // if (data.croparray == 0) {
      //   this.toastr.error('Select Crop information');
      //   return;
      // }

      this.active = 2;
    } else if (evn == 3) {

      if (data.sell_interstate === 0 || data.sell_interstate === 1) {

      } else {
        this.toastr.error('Select filed');
        return;
      }
      this.active = 3;
    } else if (evn == 0) {
      this.active = 0;
    }

    // if (!data.name || !data.email || !data.postcode || !data.stateid || !data.suburbid) {
    //   this.toastr.error('All filed are required.');
    // }else{
    //  // this.twoli=false;
    // }

  }


  modelopen(content, val) {
    this.sell_interstate = val

    this.modalService.open(content, { centered: true });

  }
  modelnoopen(val) {
    this.sell_interstate = val

  }

  openmodeldelete(content,id) {
    this.deleteid =id
    this.modalService.open(content, { centered: true });
  }


  addcrop(iscrop) {
    if (iscrop == true) {
      this.iscrop = false;
      this.getproduct();
    } else {
      this.iscrop = true;

    }

  }

  modelbreedopen(content, id, $event) {
    this.tmppackingarray = [];
    let checked = $event.target.checked;
    this.cropid = id

    let data = {
      id: id,
      growerid: this.userid
    };
    this.postservice.getidcropbreed(data)
      .subscribe((data: any) => {
        console.log(data);
        if (data.success.length > 0) {

          for (let i = 0; i < data.success.length; i++) {
            const growerpkid = data.success[i].growerpkid;
            if (growerpkid != 0) {
              let cropList =
              {
                growerid: data.success[i].growerid,
                cropid: data.success[i].growerpkid,
                breedid: data.success[i].id,
                box: Number(data.success[i].Box),
                loos: Number(data.success[i].Loose),
                punnet: Number(data.success[i].Punnet),
              };
              this.tmppackingarray.push(cropList);
            }

          }

        }

        this.breedlist = data.success;
        this.isbreedSpinner = false;
        this.cdr.detectChanges();

      });
    this.modalService.open(content, { centered: true });


  }

  checkedval(id, pkid) {

    if (pkid == 1) {
      let Index = this.tmppackingarray.findIndex(itm => Number(itm.breedid) == id && Number(itm.boxid) == pkid);
      if (Index !== -1) {
        return true
      }
    } else if (pkid == 1) {
      let Index = this.tmppackingarray.findIndex(itm => Number(itm.breedid) == id && Number(itm.loos) == pkid);
      if (Index !== -1) {
        return true
      }

    } else if (pkid == 1) {
      let Index = this.tmppackingarray.findIndex(itm => Number(itm.breedid) == id && Number(itm.punnet) == pkid);
      if (Index !== -1) {
        return true
      }

    }



    // for (let i = 0; i < this.tmppackingarray.length; i++) {
    //   const breedid = this.tmppackingarray[i].breedid;
    //   const boxid = this.tmppackingarray[i].box;
    //   const loosid = this.tmppackingarray[i].loos;
    //   const punnetid = this.tmppackingarray[i].punnet;
    //   let box = pkid === 1 ? pkid : 0;
    //   let loos = pkid === 2 ? pkid : 0;
    //   let punnet = pkid === 3 ? pkid : 0;

    //   if (breedid == id && pkid == boxid) {
    //     return true
    //   } else if (breedid == id && pkid == loosid) {
    //     return true
    //   } else if (breedid == id && pkid == punnetid) {
    //     return true
    //   } else {
    //     return false
    //   }

    // }

  }

  savebreed(iscrop) {
    this.issavecropSpinner = true;
    this.tmppackingarray
    let data = {
      croparray: this.tmppackingarray
    }
    this.postservice.savecrope(data)
      .subscribe((data: any) => {
        console.log(data);

        this.issavecropSpinner = false;
        this.modalService.dismissAll();
        this.cdr.detectChanges();
      });

  }

  closebreed() {
    this.modalService.dismissAll();

    // let removeIndex = this.tmppackingarray.findIndex(itm => Number(itm.cropid) == this.cropid);
    // if (removeIndex !== -1)
    //   this.tmppackingarray.splice(removeIndex, 1);

    // console.log(this.tmppackingarray)
    //
  }




  async onSubmit() {
    this.isSpinner = true;
    let data = {
      userid: this.userid,
      name: this.registergrowerForm.value.name,
      companyname: this.registergrowerForm.value.companyname,
      email: this.registergrowerForm.value.email,
      phone: this.registergrowerForm.value.phone,
      postcode: this.registergrowerForm.value.postcode,
      stateid: this.registergrowerForm.value.stateid,
      cityid: this.registergrowerForm.value.cityid,
      suburbid: this.registergrowerForm.value.suburbid,
      iswholesaler: this.iswholesaler,
      ispresence: this.ispresence
    };

    console.log(data);
    //

    await this.postservice.vendorregistration(data)
      .subscribe((data: any) => {
        console.log(data);
        this.isSpinner = false;
        if (data.status == true) {
          this.isEdit = false;
          this.active = 0;
          this.ngOnInit();
          this.toastr.success(data.message);
        }
      });


  }
  submitproduct() {

    this.submitted = true;
    
    this.productForm.controls.userid.setValue(this.userid);

    console.log(this.productForm.value);
   
    if (this.productForm.invalid) {
      return;
    }
    this.isSpinner = true;
    this.postservice.productAdd(this.productForm.value)
      .subscribe((data: any) => {
        console.log(data);

        this.lastid = data.lastid
        this.savefileimg(data.lastid)
        this.isSpinner = false;
        if (data.status == true) {
          this.submitted = false;
          this.productForm.reset();
          this.toastr.success('Product', data.message);
        } else {
          this.toastr.error('Product', data.message);

        }
      }, error => {
        this.isSpinner = false;
        this.toastr.error('Product', 'Please try again later.');
      })
  }
  productimages(event) {
    if (event.target.files.length > 0) {
      this.produfile = event.target.files[0];
    }
  }
  savefileimg(id) {
    console.log(this.produfile)
    
    if (this.produfile) {
      this.FileuploadformData = new FormData();
      this.FileuploadformData.append("id", id);
      this.FileuploadformData.append("imgurl", this.produfile);
      this.postservice.product_imgupload(this.FileuploadformData)
        .subscribe((data: any) => {
          console.log(data);
          
        });
    }
  }
  deleteval(){
    let data = {
      id: this.deleteid
    }
    this.postservice.deleteproduct(data)
      .subscribe((data: any) => {
        console.log(data);
        this.modalService.dismissAll()
        this.getproduct()
      });
  }
  


}

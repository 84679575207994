import { Component, OnInit, PipeTransform } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/service/auth.service';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { DecimalPipe } from '@angular/common';

import { map, startWith } from 'rxjs/operators';
import { PostService } from 'src/app/service/post.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-suburb',
  providers: [DecimalPipe],
  templateUrl: './suburb.component.html',
  styleUrls: ['./suburb.component.css']
})

export class SuburbComponent implements OnInit {
  //page = 4;

  getPageSymbol(current: number) {
    return ['A', 'B', 'C', 'D', 'E', 'F', 'G'][current - 1];
  }

  navClass = 'nav-light';
  id: any;
  //Form code
  suburbForm: FormGroup;
  submitted = false;
  isSpinner = false;
  isEdit = false;
  isactivecheck:false;
  //Table code
  page = 1;
  pageSize = 10;
  collectionSize = 0;
  certificatelist:any=[];
  allcertificatelist:any=[];
  deletid: any;
  statelist: any = [];
  //end
  constructor(private formBuilder: FormBuilder, private _authService: AuthService, private _router: Router, private toastr: ToastrService, public activatedRoute: ActivatedRoute, public pipe: DecimalPipe, public postservice: PostService,private modalService: NgbModal) {
    
  }
  refreshCountries() {
    this.certificatelist =  this.allcertificatelist
        .map((country, i) => ({ id: i + 1, ...country }))
        .slice((this.page - 1) * this.pageSize, (this.page - 1) * this.pageSize + this.pageSize);
  }

  ngOnInit(): void {
    this.suburbForm = this.formBuilder.group({
      state_id: ['', Validators.required],
      suburbname: ['', Validators.required]
    });
    this.getstate();
    this.getsuburb();
  }
  get f() { return this.suburbForm.controls; }

  getstate() {
    this._authService.getstate()
      .subscribe((data: any) => {
        console.log(data);
        this.statelist = data.success;
      });

  }

  //Delete Code
  open(content, id) {
    this.deletid = id;
    this.modalService.open(content, { centered: true });
  }
  deleteval() {
    this.modalService.dismissAll();
    this.postservice.suburbdestroy(this.deletid)
    .subscribe((data: any) => {
      console.log(data);
      this.getsuburb();
      this.toastr.success('Suburb deleted successfully', data.message);
    })
  }
  //End

  isedit(val) {
    if (val == true) {
      this.isEdit = false;
    } else {
      this.isEdit = true;
    }

  }
  getsuburb() {
    this.postservice.getsuburb()
      .subscribe((data: any) => {
        console.log(data);
        //this.certificatelist = data.success;
        this.collectionSize =data.success.length;
        this.allcertificatelist = data.success;
        this.certificatelist =  data.success
        .map((country, i) => ({ id: i + 1, ...country }))
        .slice((this.page - 1) * this.pageSize, (this.page - 1) * this.pageSize + this.pageSize);
      });
  }

 
  onSubmit() {
    // stop here if form is invalid
    this.submitted = true;
    if (this.suburbForm.invalid) {
      return;
    }
   // return false;
    this.isSpinner = true;
    this.postservice.suburbAdd(this.suburbForm.value)
      .subscribe((data: any) => {
        console.log(data);
        debugger
        this.isSpinner = false;
        if (data.status == true) {
          this.submitted = false;
          this.ngOnInit();
          this.toastr.success('Suburb', data.message);
        } else {
          this.toastr.error('Suburb', data.message);

        }
      }, error => {
        this.isSpinner = false;
        this.toastr.error('Suburb', 'Please try again later.');
      })

    // display form values on success


  }
}

import { StorageService } from './../../service/storage.service';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/service/auth.service';
import { ToastrService } from 'ngx-toastr';
import { PostService } from 'src/app/service/post.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
//Wizard


@Component({
  selector: 'app-growerregistration',
  templateUrl: './growerregistration.component.html',
  styleUrls: ['./growerregistration.component.scss']
})
export class GrowerregistrationComponent implements OnInit {
  navClass = 'nav-light';
  id: any;
  profiledetails = {
    name: "",
    companyname: "",
    email: "",
    phone: "",
    postcode: "",
    statename: "",
    cityname: "",
    suburbname: ""
  }
  searchbycroptext: string;
  searchbybreedtext: string;
  registergrowerForm: FormGroup;
  submitted = false;
  isSpinner = false;
  isEdit = false;
  subamount = 0;
  urls = [];
  subscriptionlist = [];
  name: any;
  isadmin: any;
  isgrower: any;
  isbuyer: any;
  isvendor: any;
  certificatearray: any = [];
  growing_environment: any = [];
  growing_medium: any = [];
  availability_produce: any = [];
  stateselectedarray: any = [];
  statelist: any = [];
  suburbslist: any = [];
  citylist: any = [];
  userid: any;
  cropinfo: any = [];
  tmpcropdata: any = [];
  growercertificate: any = [];
  tmppackingarray: any = [];
  packingarray: any = [{ id: 1, name: 'Box' }, { id: 2, name: 'Loose' }, { id: 3, name: 'Punnet' }];
  //Filed
  growing_environment_id: any;
  growing_medium_id: any;
  availability_produce_id: any;
  sell_interstate: any;
  storage: any;

  imageurl: [''];
  subscriptioid: number;
  striptoken: any;
  isDatarequired = false;
  errormsg: string;
  active = 0;
  disabled = false;
  FileuploadformData: FormData;
  oneli = false;
  twoli = true;
  threeli = true;
  forli = true;
  croplist: any = [];
  breedlist: any = [];
  isbreedSpinner = true;
  cropid: any;
  breedcount: number = 0;
  iscrop = false;
  isCropSpinner = true;
  issavecropSpinner: boolean = false;
  userpic = "assets/images/user/user.png";
  userSpinner = false;
  usertype: string;
  growing_environment_name: any;
  growing_medium_name: any;
  availability_produce_name: any;
  stateid: string;
  messagetxt: any;
  isSubscribe: boolean = false;
  isCerti: boolean = false;
  constructor(private formBuilder: FormBuilder, private _authService: AuthService, private _router: Router, private toastr: ToastrService, public activatedRoute: ActivatedRoute, public storageService: StorageService, public postservice: PostService, private modalService: NgbModal, private cdr: ChangeDetectorRef) {
    var urlval = this.activatedRoute.snapshot.paramMap.get('id');
    this.usertype = this.activatedRoute.snapshot.paramMap.get('type');
    this.stateid = this.activatedRoute.snapshot.paramMap.get('stateid');

    if (this.usertype == null) {
      this.usertype = '1'
    }
    this.id = urlval
    // var urlval = this.activatedRoute.snapshot.paramMap.get('id');
    // this.activatedRoute.paramMap.subscribe( params => {
    //   this.id = params.get('id');    
    // });
    // var decodeval = atob(urlval);
    // this.id = decodeval;
    // alert(this.id)
  }


  ngOnInit(): void {
    this.registergrowerForm = this.formBuilder.group({
      name: ['', Validators.required],
      companyname: [''],
      stateid: ['', Validators.required],
      cityid: ['', Validators.required],
      suburbid: ['', Validators.required],
      postcode: ['', Validators.required],
      phone: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      //password: ['', [Validators.required, Validators.minLength(6)]],

    });

    this.getcroplist();
    this.getsubscription();
    this.storageService.get('logininfo').then(
      (res) => {
        console.log(res);
        this.userid = res.id;
        if (res.id) {
          this.getuserdata();
          this.getstate();
          this.getgrowing_environment();
          this.getgrowing_medium();
          this.getavaiproduce();
          this.growers_profile();
          this.getcropinfos();
          this.getcertificate();
          this.getsubscriptionbyuser();
          // this.name = res.username;
          // this.isadmin = res.admin == 1 ? 'Admin' : '';
          // this.isgrower = res.grower == 1 ? 'Grower' : '';
          // this.isbuyer = res.buyer == 1 ? 'Buyer' : '';
          // this.isvendor = res.vendor == 1 ? 'Vender' : '';

        }
      }
    );

  }
  get f() { return this.registergrowerForm.controls; }

  isedit(val) {
    if (val == true) {
      this.isEdit = false;
    } else {
      this.isEdit = true;
    }

  }
  getsubc(event, d) {

    const newVal = event.target.value;
    const adddata = this.subscriptionlist[Number(newVal)];
    this.subscriptioid = adddata.id;
    this.subamount = adddata.amount;
  }
  getuserdata() {
    let data = {
      userid: this.id ? this.id : this.userid
    };
    this.userSpinner = true
    this._authService.getuserdata(data)
      .subscribe((data: any) => {
        console.log(data);
        this.userSpinner = false;

        this.setFormvalue(data);

      });
  }
  setFormvalue(data) {
    this.profiledetails = data
    this.userpic = data.image ? data.image : 'assets/images/user/user.png';
    this.getcity(data.stateid, 1);
    this.getsuburbs(data.stateid, 1);

    this.name = data.name;
    this.isadmin = data.admin == 1 ? 'Admin' : '';
    this.isgrower = data.grower == 1 ? 'Grower' : '';
    this.isbuyer = data.buyer == 1 ? 'Buyer' : '';
    this.isvendor = data.vendor == 1 ? 'Vender' : '';
    this.registergrowerForm.patchValue({
      name: data.name,
      companyname: data.companyname,
      email: data.email,
      postcode: data.postcode,
      stateid: data.stateid,
      cityid: data.cityid,
      phone: data.phone,
      suburbid: data.suburbid
    });
  }
  getcroplist() {
    this.postservice.getcrop()
      .subscribe((data: any) => {
        console.log(data);
        this.isCropSpinner = false;
        this.croplist = data.success;
        this.cdr.detectChanges();
      });
  }
  getstate() {
    let data = {
      id: this.userid
    }
    this.postservice.getsateselected(data)
      .subscribe((data: any) => {
        console.log(data);
        this.statelist = data.success;
      });


  }
  getcity(event, type) {
    let newVal1
    if (type == 0) {
      newVal1 = event.target.value;
    } else {
      newVal1 = event;
    }

    const newVal = event;
    let data = {
      state_id: newVal1
    };
    this._authService.getcity(data)
      .subscribe((data: any) => {
        console.log(data);
        debugger
        this.citylist = data.success;
      });
  }



  getsuburbs(event, type) {
    let newVal
    if (type == 0) {
      newVal = event.target.value;
    } else {
      newVal = event;
    }

    let data = {
      state_id: newVal
    };
    this._authService.getsuburbs(data)
      .subscribe((data: any) => {
        console.log(data);
        debugger
        this.suburbslist = data.success;
      });
  }
  getsubscription() {

    this.postservice.getsubscription()
      .subscribe((data: any) => {
        console.log(data);
        this.subscriptionlist = data.success;
      });
  }
  getcertificate() {
    let data = {
      id: this.id ? this.id : this.userid
    }
    this.postservice.getcertificate_byid(data)
      .subscribe((data: any) => {
        console.log(data);
        this.certificatearray = data.success;

        for (let i = 0; i < this.certificatearray.length; i++) {
          const element = this.certificatearray[i].imageurl;
          if (element) {
            this.isCerti = true;
          }

        }
      });
  }
  getgrowing_environment() {
    let data = {
      id: this.id ? this.id : this.userid
    }
    this.postservice.getgrowing_environment_byid(data)
      .subscribe((data: any) => {
        console.log(data);
        debugger
        for (let i = 0; i < data.success.length; i++) {
          const element = data.success[i].selected;
          const name = data.success[i].name;
          if (element != 0) {
            this.growing_environment_id = element
            this.growing_environment_name = name
          }
        }

        //debugger
        this.growing_environment = data.success;
      });
  }

  getgrowing_medium() {
    let data = {
      id: this.id ? this.id : this.userid
    }
    this.postservice.getgrowing_medium_byid(data)
      .subscribe((data: any) => {
        console.log(data);
        for (let i = 0; i < data.success.length; i++) {
          const element = data.success[i].selected;
          const name = data.success[i].name;
          if (element != 0) {
            this.growing_medium_id = element
            this.growing_medium_name = name
          }
        }

        //debugger
        this.growing_medium = data.success;
      });

    // this.postservice.getgrowing_medium()
    //   .subscribe((data: any) => {
    //     console.log(data);
    //     this.growing_medium = data.success;
    //   });
  }

  getavaiproduce() {
    let data = {
      id: this.id ? this.id : this.userid
    }
    this.postservice.getgrowing_avaiproduce_byid(data)
      .subscribe((data: any) => {
        console.log(data);
        for (let i = 0; i < data.success.length; i++) {
          const element = data.success[i].selected;
          const name = data.success[i].name;

          if (element != 0) {
            this.availability_produce_id = element
            this.availability_produce_name = name

          }
        }
        //debugger
        this.availability_produce = data.success;
      });

  }
  growers_profile() {
    let data = {
      id: this.id ? this.id : this.userid
    }
    this.postservice.growers_profile(data)
      .subscribe((data: any) => {
        console.log(data);
        debugger
        const storage = data.success.storage;
        const sell_interstate = data.success.sell_interstate;
        if (storage != 0) {
          this.storage = storage
        }
        this.sell_interstate = sell_interstate



      });
  }
  getcropinfos() {
    //let tmpcropdata = [];
    this.tmpcropdata = [];
    let data = {
      id: this.id ? this.id : this.userid
    }
    this.postservice.getcropinfo(data)
      .subscribe((data: any) => {
        console.log(data);
        debugger
        this.cropinfo = data.success;
        for (let i = 0; i < this.cropinfo.length; i++) {
          const element = this.cropinfo[i];
          const statu = this.cropinfo[i].statu;
          if (statu == 0) {
            let data = {
              id: this.cropinfo[i].id,
              cropname: this.cropinfo[i].cropname,
              breedname: this.cropinfo[i].breedname,
              statu: this.cropinfo[i].statu,
              count: 0
            };
            this.tmpcropdata.push(data);
          }
        }
        if (this.tmpcropdata.length > 0) {
          for (let j = 0; j < this.tmpcropdata.length; j++) {
            const elementj = this.tmpcropdata[j];
            let count = 0;

            for (let z = 0; z < this.cropinfo.length; z++) {
              const elementz = this.cropinfo[z];
              if (elementj.id == elementz.id) {
                count++;
              }
            }
            elementj.count = count - 1;
          }
        }
        console.log(this.tmpcropdata);
        debugger;
      });
  }
  onSelectFile(event, id) {
    if (event.target.files && event.target.files[0]) {
      var filesAmount = event.target.files.length;
      for (let i = 0; i < filesAmount; i++) {
        var reader = new FileReader();

        reader.onload = (event: any) => {
          console.log(event.target.result);
          this.urls.push(event.target.result);
        };

        reader.readAsDataURL(event.target.files[i]);
      }
      console.log(this.urls);

    }
  }

  upload(event, id) {
    if (event.target.files.length > 0) {
      this.FileuploadformData = new FormData();
      const file = event.target.files[0];
      let data = {
        id: id,
        file: file,
        userid: this.userid
      };
      let removeIndex = this.growercertificate.findIndex(itm => itm.id === id);
      debugger;
      if (removeIndex !== -1) {
        this.growercertificate.splice(removeIndex, 1);
      }
      this.growercertificate.push(data);

      console.log(this.growercertificate);
      debugger;

    }
  }
  uploadprofile(event) {
    if (event.target.files.length > 0) {
      this.FileuploadformData = new FormData();
      const file = event.target.files[0];
      debugger
      this.FileuploadformData.append("growerid", this.userid);
      this.FileuploadformData.append("profilepic", file);
      this.postservice.profile_imgupload(this.FileuploadformData)
        .subscribe((data: any) => {
          console.log(data);
          debugger;
        });



    }
  }


  upload1(file: File, id) {
    console.log(id);
    console.log(file);
    let data = {
      growerid: this.userid,
      certificateid: id,
      imageurl: file
    };
    if (file) {
      // Pushing the object into array
      this.growercertificate.push(data);
    } else {
      let removeIndex = this.growercertificate.findIndex(itm => itm.certificateid === id);
      if (removeIndex !== -1)
        this.growercertificate.splice(removeIndex, 1);
    }
    console.log(this.growercertificate);

    let dataval = {
      growercertificate: this.growercertificate
    };

    this.postservice.imgupload(dataval)
      .subscribe((data: any) => {
        console.log(data);
        debugger;
      });

    //pick from one of the 4 styles of file uploads below
  }

  reset(id) {

    var control = document.getElementById("mcontrol");


    let removeIndex = this.growercertificate.findIndex(itm => itm.certificateid === id);
    if (removeIndex !== -1)
      this.growercertificate.splice(removeIndex, 1);
    console.log(this.growercertificate);
    debugger;
  }


  stripdata($event) {
    console.log($event);
    debugger;
    this.striptoken = $event;

    // this.postservice.sendstriprequest(data)
    // .subscribe((data: any) => {
    //   console.log(data);
    //   debugger  
    // })
  }

  packingselect(values: any, breedid, pkid) {

    console.log(this.tmppackingarray);
    //debugger;
    if (values.currentTarget.checked == true) {
      if (this.tmppackingarray.length > 0) {
        let objIndex = this.tmppackingarray.findIndex(itm => Number(itm.breedid) == Number(breedid));
        if (objIndex !== -1) {
          //Log object to Console.
          console.log("Before update: ", this.tmppackingarray[objIndex])
          // debugger
          //Update object's name property.
          if (pkid == 1) {
            this.tmppackingarray[objIndex].box = 1
          }
          if (pkid == 2) {
            this.tmppackingarray[objIndex].loos = 1
          }
          if (pkid == 3) {
            this.tmppackingarray[objIndex].punnet = 1
          }


          //Log object to console again.
          console.log("After update: ", this.tmppackingarray[objIndex])
          //debugger


        } else {
          let cropList =
          {
            growerid: this.userid,
            cropid: this.cropid,
            breedid: Number(breedid) != 0 ? Number(breedid) : 0,
            box: pkid === 1 ? 1 : 0,
            loos: pkid === 2 ? 1 : 0,
            punnet: pkid === 3 ? 1 : 0,
          };
          debugger
          this.tmppackingarray.push(cropList);
        }
      } else {
        let cropList =
        {
          growerid: this.userid,
          cropid: this.cropid,
          breedid: Number(breedid) != 0 ? Number(breedid) : 0,
          box: pkid === 1 ? 1 : 0,
          loos: pkid === 2 ? 1 : 0,
          punnet: pkid === 3 ? 1 : 0,
        };
        debugger
        this.tmppackingarray.push(cropList);

      }
    } else {

      let removeIndex = this.tmppackingarray.findIndex(itm => Number(itm.breedid) == Number(breedid));
      if (removeIndex !== -1) {
        if ((this.tmppackingarray[removeIndex].box == 0 && this.tmppackingarray[removeIndex].loos == 0) ||
          (this.tmppackingarray[removeIndex].box == 0 && this.tmppackingarray[removeIndex].punnet == 0) ||
          (this.tmppackingarray[removeIndex].loos == 0 && this.tmppackingarray[removeIndex].punnet == 0) ||
          (this.tmppackingarray[removeIndex].loos == 0 && this.tmppackingarray[removeIndex].box == 0) ||
          (this.tmppackingarray[removeIndex].punnet == 0 && this.tmppackingarray[removeIndex].box == 0) ||
          (this.tmppackingarray[removeIndex].punnet == 0 && this.tmppackingarray[removeIndex].loos == 0)) {
          //this.tmppackingarray.splice(removeIndex, 1);
          if (pkid == 1) {
            this.tmppackingarray[removeIndex].box = 0
          }
          if (pkid == 2) {
            this.tmppackingarray[removeIndex].loos = 0
          }
          if (pkid == 3) {
            this.tmppackingarray[removeIndex].punnet = 0
          }
          //debugger
        } else {
          if (pkid == 1) {
            this.tmppackingarray[removeIndex].box = 0
          }
          if (pkid == 2) {
            this.tmppackingarray[removeIndex].loos = 0
          }
          if (pkid == 3) {
            this.tmppackingarray[removeIndex].punnet = 0
          }


        }

      }

      //  let removeIndex = this.tmppackingarray.findIndex(itm => Number(itm.breedid) != 0 ? Number(itm.breedid) : this.cropid === (Number(breedid) != 0) ? Number(breedid) : this.cropid);
      // if (removeIndex !== -1)
      // this.tmppackingarray.splice(removeIndex, 1);
      console.log(this.tmppackingarray);
      debugger;
    }


  }

  tabChange(evn) {
    let nextId = evn.nextId;
    let activeId = evn.activeId;
    let data = {
      growerid: this.userid,
      name: this.registergrowerForm.value.name,
      companyname: this.registergrowerForm.value.companyname,
      email: this.registergrowerForm.value.email,
      postcode: this.registergrowerForm.value.postcode,
      phone: this.registergrowerForm.value.phone,
      stateid: this.registergrowerForm.value.stateid,
      suburbid: this.registergrowerForm.value.suburbid,
      growing_environment_id: this.growing_environment_id,//v
      growing_medium_id: this.growing_medium_id,//v
      availability_produce_id: this.availability_produce_id,//v
      sell_interstate: this.sell_interstate,//v
      storage: this.storage,//v
      croparray: this.tmppackingarray,//v
      certificatearray: '',
    };

    // this.twoli = true;
    // this.threeli = true;
    debugger;
    // return false
    if (evn == 1) {
      if (this.registergrowerForm.invalid) {
        this.toastr.error('All filed are required.');
        return;
      }
      this.active = 1;
    } else if (evn == 2) {
      // if (data.croparray == 0) {
      //   this.toastr.error('Select Crop information');
      //   return;
      // }

      this.active = 2;
    } else if (evn == 3) {
      if (!data.growing_environment_id) {
        this.toastr.error('Select Growing environment');
        return;
      }
      if (!data.growing_medium_id) {
        this.toastr.error('Select Growing medium');
        return;
      }
      if (!data.availability_produce_id) {
        this.toastr.error('Select Availability produce');
        return;
      }
      this.active = 3;
    } else if (evn == 0) {
      this.active = 0;
    }

    // if (!data.name || !data.email || !data.postcode || !data.stateid || !data.suburbid) {
    //   this.toastr.error('All filed are required.');
    // }else{
    //  // this.twoli=false;
    // }

  }

  getgrwval(type) {
    debugger
    if (type == 1) {
      let val = this.growing_environment.find(itm => itm.id === this.growing_environment_id);
      debugger

    }

  }

  modelopen(content, val) {
    this.sell_interstate = val
    this.modalService.open(content, { centered: true });

  }
  modelnoopen(val) {
    this.sell_interstate = val
    debugger
  }
  openmodelbreed(content) {
    this.modalService.open(content, { centered: true });
  }
  stateselect(event, id) {
    if (event.target.checked == true) {
      let data = {
        stateid: id,
        userid: this.userid,
        chkvalue: 1
      };
      let removeIndex = this.stateselectedarray.findIndex(itm => itm.stateid === id);
      if (removeIndex !== -1) {
        this.stateselectedarray.splice(removeIndex, 1);
      }
      this.stateselectedarray.push(data);
      console.log(this.stateselectedarray);
      debugger;
    } else {
      let data = {
        stateid: id,
        userid: this.userid,
        chkvalue: 0
      };
      let removeIndex = this.stateselectedarray.findIndex(itm => itm.stateid === id);
      if (removeIndex !== -1) {
        this.stateselectedarray.splice(removeIndex, 1);
      }
      this.stateselectedarray.push(data);

    }
    console.log(this.stateselectedarray);
    debugger
  }

  addcrop(iscrop) {
    if (iscrop == true) {
      this.iscrop = false;
      this.getcropinfos();
    } else {
      this.iscrop = true;

    }

  }

  modelbreedopen(content, id, $event) {
    this.tmppackingarray = [];
    let checked = $event.target.checked;
    this.cropid = id

    let data = {
      id: id,
      growerid: this.userid
    };
    this.postservice.getidcropbreed(data)
      .subscribe((data: any) => {
        console.log(data);
        if (data.success.length > 0) {

          for (let i = 0; i < data.success.length; i++) {
            const growerpkid = data.success[i].growerpkid;
            if (growerpkid != 0) {
              let cropList =
              {
                growerid: data.success[i].growerid,
                cropid: data.success[i].growerpkid,
                breedid: data.success[i].id,
                box: Number(data.success[i].Box),
                loos: Number(data.success[i].Loose),
                punnet: Number(data.success[i].Punnet),
              };
              this.tmppackingarray.push(cropList);
            }

          }

        }

        this.breedlist = data.success;
        this.isbreedSpinner = false;
        this.cdr.detectChanges();

      });
    this.modalService.open(content, { centered: true });


  }

  checkedval(id, pkid) {
    debugger
    if (pkid == 1) {
      let Index = this.tmppackingarray.findIndex(itm => Number(itm.breedid) == id && Number(itm.boxid) == pkid);
      if (Index !== -1) {
        return true
      }
    } else if (pkid == 1) {
      let Index = this.tmppackingarray.findIndex(itm => Number(itm.breedid) == id && Number(itm.loos) == pkid);
      if (Index !== -1) {
        return true
      }

    } else if (pkid == 1) {
      let Index = this.tmppackingarray.findIndex(itm => Number(itm.breedid) == id && Number(itm.punnet) == pkid);
      if (Index !== -1) {
        return true
      }

    }



    // for (let i = 0; i < this.tmppackingarray.length; i++) {
    //   const breedid = this.tmppackingarray[i].breedid;
    //   const boxid = this.tmppackingarray[i].box;
    //   const loosid = this.tmppackingarray[i].loos;
    //   const punnetid = this.tmppackingarray[i].punnet;
    //   let box = pkid === 1 ? pkid : 0;
    //   let loos = pkid === 2 ? pkid : 0;
    //   let punnet = pkid === 3 ? pkid : 0;

    //   if (breedid == id && pkid == boxid) {
    //     return true
    //   } else if (breedid == id && pkid == loosid) {
    //     return true
    //   } else if (breedid == id && pkid == punnetid) {
    //     return true
    //   } else {
    //     return false
    //   }

    // }

  }

  savebreed(iscrop) {
    this.issavecropSpinner = true;
    this.tmppackingarray
    let data = {
      croparray: this.tmppackingarray
    }
    this.postservice.savecrope(data)
      .subscribe((data: any) => {
        console.log(data);
        debugger
        this.issavecropSpinner = false;
        this.modalService.dismissAll();
        this.cdr.detectChanges();
      });

  }

  closebreed() {
    this.modalService.dismissAll();

    // let removeIndex = this.tmppackingarray.findIndex(itm => Number(itm.cropid) == this.cropid);
    // if (removeIndex !== -1)
    //   this.tmppackingarray.splice(removeIndex, 1);

    // console.log(this.tmppackingarray)
    //debugger
  }

  checkbreedcount(id) {

    let removeIndex = this.tmppackingarray.find(itm => Number(itm.cropid) == id);
    // debugger

    if (this.tmppackingarray.length > 0) {
      let tmparray = [];
      for (let i = 0; i < this.tmppackingarray.length; i++) {
        const element = this.tmppackingarray[i].cropid;
        const breedid = this.tmppackingarray[i].breedid;
        if (element === id) {
          tmparray.push(breedid)
        }
      }
      let unique = tmparray.filter((item, i, ar) => ar.indexOf(item) === i);
      console.log(unique);
      return unique.length;
    } else {
      return 0;
    }
    let a = ["1", "1", "2", "3", "3", "1"];

    return 0;
    if (removeIndex !== -1) {

    }
  }

  gm($event) {
    let value = $event.target.value;
    let checked = $event.target.checked;
    this.growing_medium_id = value;
  }
  async onSubmit() {
    this.isSpinner = true;
    let data = {
      growerid: this.userid,
      name: this.registergrowerForm.value.name,
      companyname: this.registergrowerForm.value.companyname,
      email: this.registergrowerForm.value.email,
      phone: this.registergrowerForm.value.phone,
      postcode: this.registergrowerForm.value.postcode,
      stateid: this.registergrowerForm.value.stateid,
      suburbid: this.registergrowerForm.value.suburbid,
      growing_environment_id: this.growing_environment_id,//v     
      growing_medium_id: this.growing_medium_id,//v
      availability_produce_id: this.availability_produce_id,//v
      sell_interstate: this.sell_interstate ? this.sell_interstate : 0,//v
      storage: this.storage?this.storage:0,//v
    };

    console.log(data);
    debugger
   // return false
    await this.growerrestateadd();
    await this.saveimg();
    await this.postservice.growerregistration(data)
      .subscribe((data: any) => {
        console.log(data);
        this.isSpinner = false;
        if (data.status == true) {
          this.isEdit = false;
          this.active = 0;
          this.ngOnInit();
          this.toastr.success(data.message);
        }
      });


  }
  saveimg() {
    this.isSpinner = true;
    for (var i = 0; i < this.growercertificate.length; i++) {
      this.FileuploadformData.append("growerid", this.userid);
      this.FileuploadformData.append("certificateid", this.growercertificate[i]['id']);
      this.FileuploadformData.append("myfile", this.growercertificate[i]['file']);
      this.postservice.imgupload(this.FileuploadformData)
        .subscribe((data: any) => {
          console.log(data);
          this.isSpinner = false;
          this.active = 0;
          this.ngOnInit();
          //debugger;
        });
    }

  }
  growerrestateadd() {
    // this.isSpinner = true;
    for (var i = 0; i < this.stateselectedarray.length; i++) {
      let data = {
        userid: this.userid,
        stateid: this.stateselectedarray[i]['stateid'],
        chkvalue: this.stateselectedarray[i]['chkvalue']
      }
      this.postservice.addbuyerstate(data)
        .subscribe((data: any) => {
          console.log(data);
          // this.isSpinner = false;
          //this.active = 0;
          //this.ngOnInit();
          // debugger;
        });
    }
  }
  isback(): void {
    //usertype
    this._router.navigateByUrl('/search/' + this.usertype + '/' + this.stateid);

    //  this.location.back()
  }
  contact(contactGrower) {
    this.modalService.open(contactGrower, { centered: true });
  }
  modelmsgsend() {
    if (this.messagetxt) {
      let data = {
        message: this.messagetxt,
        fromid: this.userid,
        toid: this.id,
        status: 0,
      }
      this.postservice.sendmessage(data)
        .subscribe((data: any) => {
          console.log(data);
          debugger
          this.modalService.dismissAll();
          this.cdr.detectChanges();
        });
    }
  }
  getsubscriptionbyuser() {
    let data = {
      id: this.userid
    };
    this.userSpinner = true
    this._authService.getsubscriptionbyuser(data)
      .subscribe((data: any) => {
        console.log(data.success);
        debugger
        if (data.success.length > 0) {
          this.isSubscribe = true;
        }


      });
  }
  gosubscribe() {
    this._router.navigateByUrl('/growersubscription');
  }

}
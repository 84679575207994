import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { environment } from '../../environments/environment';
//const API_USERS_URL = `${environment.apibaseUrl}`;
const URL = environment.apibaseUrl;

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private http: HttpClient) { }

  userRegister(data) {
   // debugger
    return this.http.post(`${URL}/register`, data);
  }

  login(data) {
    return this.http.post(`${URL}/login`, data);
  }
  getstate() {
    return this.http.post(`${URL}/state`,'');
  }
  
  getsuburbs(data) {
    return this.http.post(`${URL}/suburbs`, data);
  }
  getcity(data) {
    return this.http.post(`${URL}/getcity`, data);
  }
  getuserdata(data) {
    return this.http.post(`${URL}/userlist`, data);
  }
  getuseralllist(data) {
    return this.http.post(`${URL}/useralllist`, data);
  }
  getsubscriptionbyuser(data) {
    return this.http.post(`${URL}/getsubscriptionbyuser`, data);
  }
  sendrestlink(data) {
    return this.http.post(`${URL}/sendrestlink`, data);
  }
 


}

<div class="back-to-home rounded d-none d-sm-block">
    <a routerLink="/index" class="btn btn-icon btn-soft-primary">
        <i-feather name="home" class="icons"></i-feather>
    </a>
</div>

<!-- Hero Start -->
<section class="bg-home d-flex align-items-center pt-4 pb-4">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-7 col-md-6">
                <div class="mr-lg-5 loginlogo">
                    <img src="assets/images/user/logo-dark.png" class="img-fluid d-block mx-auto mb-2" alt="">
                </div>
            </div>


            <div class="col-lg-5 col-md-6">
                <div class="card login-page shadow rounded border-0">
                    <div class="card-body">
                        <h4 class="card-title text-center">Signup</h4>

                        <ul ngbNav #nav="ngbNav" class="nav-pills nav-justified">
                            <li ngbNavItem>
                                <a ngbNavLink class=" rounded">
                                    <div class="text-center pt-1 pb-1">
                                        <h4 class="title font-weight-normal mb-0">Grower</h4>
                                    </div>
                                </a>
                                <ng-template ngbNavContent>
                                    <form [formGroup]="registergrowerForm" class="login-form mt-4">

                                        <div class="row">
                                            <div class="col-md-6">
                                                <div class="form-group position-relative">
                                                    <label>Name <span class="text-danger">*</span></label>
                                                    <i-feather name="user-check" class="fea icon-sm icons"></i-feather>
                                                    <input type="text" class="form-control pl-5" placeholder="Name" formControlName="name" [ngClass]="{ 'is-invalid': submitted && f.name.errors }">
                                                    <div *ngIf="submitted && f.name.errors" class="invalid-feedback">
                                                        <div *ngIf="f.name.errors.required">Name is required</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="form-group position-relative">
                                                    <label>Company name</label>
                                                    <i-feather name="user-check" class="fea icon-sm icons"></i-feather>
                                                    <input type="text" class="form-control pl-5" placeholder="Company name" formControlName="companyname">
                                                </div>
                                            </div>

                                            <div class="col-md-6">
                                                <div class="form-group position-relative">
                                                    <label>State<span class="text-danger">*</span></label>
                                                    <select (change)="getcity($event)" formControlName="stateid" class="form-control custom-select" [ngClass]="{ 'is-invalid': submitted && f.stateid.errors }">
                            <option value="">Select</option>
                            <option *ngFor="let item of statelist" value="{{ item.id }}">{{ item.statename }}</option>                          
                          </select>
                                                    <div *ngIf="submitted && f.stateid.errors" class="invalid-feedback">
                                                        <div *ngIf="f.stateid.errors.required">State is required</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="form-group position-relative">
                                                    <label>City<span class="text-danger">*</span></label>
                                                    <select formControlName="cityid" class="form-control custom-select" [ngClass]="{ 'is-invalid': submitted && f.cityid.errors }">
                            <option value="">Select</option>
                            <option *ngFor="let item of citylist" value="{{ item.id }}">{{ item.cityname }}</option>     
                          </select>
                                                    <div *ngIf="submitted && f.cityid.errors" class="invalid-feedback">
                                                        <div *ngIf="f.cityid.errors.required">City is required</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="form-group position-relative">
                                                    <label>Suburb<span class="text-danger">*</span></label>
                                                    <select formControlName="suburbid" class="form-control custom-select" [ngClass]="{ 'is-invalid': submitted && f.suburbid.errors }">
                            <option value="">Select</option>
                            <option *ngFor="let item of suburbslist" value="{{ item.id }}">{{ item.suburbname }}</option>     
                          </select>
                                                    <div *ngIf="submitted && f.suburbid.errors" class="invalid-feedback">
                                                        <div *ngIf="f.suburbid.errors.required">Suburb is required</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="form-group position-relative">
                                                    <label>Post code<span class="text-danger">*</span></label>
                                                    <input type="text" class="form-control" placeholder="Post code" formControlName="postcode" [ngClass]="{ 'is-invalid': submitted && f.postcode.errors }">
                                                    <div *ngIf="submitted && f.postcode.errors" class="invalid-feedback">
                                                        <div *ngIf="f.postcode.errors.required">Post code is required</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-12">
                                                <div class="form-group position-relative">
                                                    <label>Mobile Number<span class="text-danger">*</span></label>
                                                    <input type="text" class="form-control" placeholder="Mobile Number" formControlName="phone" [ngClass]="{ 'is-invalid': submitted && f.phone.errors }">
                                                    <div *ngIf="submitted && f.phone.errors" class="invalid-feedback">
                                                        <div *ngIf="f.phone.errors.required">Mobile number is required</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-12">
                                                <div class="form-group position-relative">
                                                    <label>Email <span class="text-danger">*</span></label>
                                                    <i-feather name="mail" class="fea icon-sm icons"></i-feather>
                                                    <input type="email" class="form-control pl-5" placeholder="Email" formControlName="email" [ngClass]="{ 'is-invalid': submitted && f.email.errors }">
                                                    <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                                                        <div *ngIf="f.email.errors.required">Email is required</div>
                                                        <div *ngIf="f.email.errors.email">Email must be a valid email address</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-12">
                                                <div class="form-group position-relative">
                                                    <label>Password <span class="text-danger">*</span></label>
                                                    <i-feather name="key" class="fea icon-sm icons"></i-feather>
                                                    <input type="password" formControlName="password" class="form-control pl-5" placeholder="Password" [ngClass]="{ 'is-invalid': submitted && f.password.errors }">
                                                    <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                                                        <div *ngIf="f.password.errors.required">Password is required</div>
                                                        <div *ngIf="f.password.errors.minlength">Password must be at least 6 characters</div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-md-12 loginbtn">

                                                <button (click)='onSubmit("Grower")' class="btn btn-primary btn-block d-flex align-items-center justify-content-center">
                          <span style="margin-right: 10px;">Register</span> 
                          <div class="spinner-border" *ngIf="isSpinner" role="status">
                            <span class="sr-only">Loading...</span>
                          </div>
                        </button>
                                            </div>

                                            <div class="mx-auto">
                                                <p class="mb-0 mt-3"><small class="text-dark mr-2">Already have an account ?</small> <a routerLink="/auth-login" class="text-dark font-weight-bold">Sign in</a></p>
                                            </div>
                                        </div>
                                    </form>
                                </ng-template>
                            </li>
                            <li ngbNavItem>
                                <a ngbNavLink class="rounded">
                                    <div class="text-center pt-1 pb-1">
                                        <h4 class="title font-weight-normal mb-0">Buyer</h4>
                                    </div>
                                </a>
                                <ng-template ngbNavContent>
                                    <form [formGroup]="registergrowerForm" class="login-form mt-4">
                                        <div class="row">
                                            <div class="col-md-6">
                                                <div class="form-group position-relative">
                                                    <label>Name <span class="text-danger">*</span></label>
                                                    <i-feather name="user-check" class="fea icon-sm icons"></i-feather>
                                                    <input type="text" class="form-control pl-5" placeholder="Name" formControlName="name" [ngClass]="{ 'is-invalid': submitted && f.name.errors }">
                                                    <div *ngIf="submitted && f.name.errors" class="invalid-feedback">
                                                        <div *ngIf="f.name.errors.required">Name is required</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="form-group position-relative">
                                                    <label>Company name</label>
                                                    <i-feather name="user-check" class="fea icon-sm icons"></i-feather>
                                                    <input type="text" class="form-control pl-5" placeholder="Company name" formControlName="companyname">
                                                </div>
                                            </div>

                                            <div class="col-md-6">
                                                <div class="form-group position-relative">
                                                    <label>State<span class="text-danger">*</span></label>
                                                    <select (change)="getcity($event)" formControlName="stateid" class="form-control custom-select" [ngClass]="{ 'is-invalid': submitted && f.stateid.errors }">
                            <option value="">Select</option>
                            <option *ngFor="let item of statelist" value="{{ item.id }}">{{ item.statename }}</option> 
                          </select>
                                                    <div *ngIf="submitted && f.stateid.errors" class="invalid-feedback">
                                                        <div *ngIf="f.stateid.errors.required">State is required</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="form-group position-relative">
                                                    <label>City<span class="text-danger">*</span></label>
                                                    <select formControlName="cityid" class="form-control custom-select" [ngClass]="{ 'is-invalid': submitted && f.cityid.errors }">
                            <option value="">Select</option>
                            <option *ngFor="let item of citylist" value="{{ item.id }}">{{ item.cityname }}</option>     
                          </select>
                                                    <div *ngIf="submitted && f.cityid.errors" class="invalid-feedback">
                                                        <div *ngIf="f.cityid.errors.required">City is required</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="form-group position-relative">
                                                    <label>Suburb<span class="text-danger">*</span></label>
                                                    <select formControlName="suburbid" class="form-control custom-select" [ngClass]="{ 'is-invalid': submitted && f.suburbid.errors }">
                            <option value="">Select</option>
                            <option *ngFor="let item of suburbslist" value="{{ item.id }}">{{ item.suburbname }}</option>     
                          </select>
                                                    <div *ngIf="submitted && f.suburbid.errors" class="invalid-feedback">
                                                        <div *ngIf="f.suburbid.errors.required">Suburb is required</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="form-group position-relative">
                                                    <label>Post code<span class="text-danger">*</span></label>
                                                    <input type="text" class="form-control" placeholder="Post code" formControlName="postcode" [ngClass]="{ 'is-invalid': submitted && f.postcode.errors }">
                                                    <div *ngIf="submitted && f.postcode.errors" class="invalid-feedback">
                                                        <div *ngIf="f.postcode.errors.required">Post code is required</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="form-group position-relative">
                                                    <label>Buyer Type<span class="text-danger">*</span></label>
                                                    <select formControlName="retai_whole" class="form-control custom-select" [ngClass]="{ 'is-invalid': submitted && f.retai_whole.errors }">
                            <option value="0">Retailars</option>
                            <option value="1">Wholesaller</option>
                            
                          </select>
                                                    <div *ngIf="submitted && f.retai_whole.errors" class="invalid-feedback">
                                                        <div *ngIf="f.retai_whole.errors.required">Suburb is required</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="form-group position-relative">
                                                    <label>Mobile Number<span class="text-danger">*</span></label>
                                                    <input type="text" class="form-control" placeholder="Mobile Number" formControlName="phone" [ngClass]="{ 'is-invalid': submitted && f.phone.errors }">
                                                    <div *ngIf="submitted && f.phone.errors" class="invalid-feedback">
                                                        <div *ngIf="f.phone.errors.required">Mobile number is required</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <!-- <div class="col-md-12">
                        <div class="form-group position-relative">
                          <div class="custom-control custom-radio custom-control-inline">
                            <div class="form-group mb-0">
                   
                              <input type="radio" formControlName="retai_whole" id="customRadio1"  value="Retailars"
                                class="custom-control-input">
                              <label class="custom-control-label" for="customRadio1">Retailars</label>
                            </div>
                          </div>
                          <div class="custom-control custom-radio custom-control-inline">
                            <div class="form-group mb-0">
                            
                              <input type="radio" id="customRadio2" name="wholesaller" value="Wholesaller"
                                formControlName="retai_whole" class="custom-control-input">
                              <label class="custom-control-label" for="customRadio2">Wholesaller</label>
                            </div>
                          </div>
                        </div>
                      </div> -->

                                            <div class="col-md-12">
                                                <div class="form-group position-relative">
                                                    <label>Email <span class="text-danger">*</span></label>
                                                    <i-feather name="mail" class="fea icon-sm icons"></i-feather>
                                                    <input type="email" class="form-control pl-5" placeholder="Email" formControlName="email" [ngClass]="{ 'is-invalid': submitted && f.email.errors }">
                                                    <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                                                        <div *ngIf="f.email.errors.required">Email is required</div>
                                                        <div *ngIf="f.email.errors.email">Email must be a valid email address</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-12">
                                                <div class="form-group position-relative">
                                                    <label>Password <span class="text-danger">*</span></label>
                                                    <i-feather name="key" class="fea icon-sm icons"></i-feather>
                                                    <input type="password" formControlName="password" class="form-control pl-5" placeholder="Password" [ngClass]="{ 'is-invalid': submitted && f.password.errors }">
                                                    <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                                                        <div *ngIf="f.password.errors.required">Password is required</div>
                                                        <div *ngIf="f.password.errors.minlength">Password must be at least 6 characters</div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-md-12">
                                                <button (click)='onSubmit("Buyer")' class="btn btn-primary btn-block d-flex align-items-center justify-content-center">
                         <span>Register</span> 
                          <div class="spinner-border" *ngIf="isSpinner" role="status">
                            <span class="sr-only">Loading...</span>
                          </div>
                        </button>
                                            </div>

                                            <div class="mx-auto">
                                                <p class="mb-0 mt-3"><small class="text-dark mr-2">Already have an account ?</small> <a routerLink="/auth-login" class="text-dark font-weight-bold">Sign in</a></p>
                                            </div>
                                        </div>
                                    </form>
                                </ng-template>
                            </li>
                            <li ngbNavItem>
                                <a ngbNavLink class=" rounded">
                                    <div class="text-center pt-1 pb-1">
                                        <h4 class="title font-weight-normal mb-0">Vendor</h4>
                                    </div>
                                </a>
                                <ng-template ngbNavContent>
                                    <form [formGroup]="registergrowerForm" class="login-form mt-4">
                                        <div class="row">
                                            <div class="col-md-6">
                                                <div class="form-group position-relative">
                                                    <label>Name <span class="text-danger">*</span></label>
                                                    <i-feather name="user-check" class="fea icon-sm icons"></i-feather>
                                                    <input type="text" class="form-control pl-5" placeholder="Name" formControlName="name" [ngClass]="{ 'is-invalid': submitted && f.name.errors }">
                                                    <div *ngIf="submitted && f.name.errors" class="invalid-feedback">
                                                        <div *ngIf="f.name.errors.required">Name is required</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="form-group position-relative">
                                                    <label>Company name</label>
                                                    <i-feather name="user-check" class="fea icon-sm icons"></i-feather>
                                                    <input type="text" class="form-control pl-5" placeholder="Company name" formControlName="companyname">
                                                </div>
                                            </div>

                                            <div class="col-md-6">
                                                <div class="form-group position-relative">
                                                    <label>State<span class="text-danger">*</span></label>
                                                    <select (change)="getcity($event)" formControlName="stateid" class="form-control custom-select" [ngClass]="{ 'is-invalid': submitted && f.state.errors }">
                            <option value="">Select</option>
                            <option *ngFor="let item of statelist" value="{{ item.id }}">{{ item.statename }}</option>
                          </select>
                                                    <div *ngIf="submitted && f.stateid.errors" class="invalid-feedback">
                                                        <div *ngIf="f.stateid.errors.required">State is required</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="form-group position-relative">
                                                    <label>City<span class="text-danger">*</span></label>
                                                    <select formControlName="cityid" class="form-control custom-select" [ngClass]="{ 'is-invalid': submitted && f.cityid.errors }">
                            <option value="">Select</option>
                            <option *ngFor="let item of citylist" value="{{ item.id }}">{{ item.cityname }}</option>     
                          </select>
                                                    <div *ngIf="submitted && f.cityid.errors" class="invalid-feedback">
                                                        <div *ngIf="f.cityid.errors.required">City is required</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="form-group position-relative">
                                                    <label>Suburb<span class="text-danger">*</span></label>
                                                    <select formControlName="suburbid" class="form-control custom-select" [ngClass]="{ 'is-invalid': submitted && f.suburb.errors }">
                            <option value="">Select</option>
                            <option *ngFor="let item of suburbslist" value="{{ item.id }}">{{ item.suburbname }}</option>     
                          </select>
                                                    <div *ngIf="submitted && f.suburbid.errors" class="invalid-feedback">
                                                        <div *ngIf="f.suburbid.errors.required">Suburb is required</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="form-group position-relative">
                                                    <label>Post code<span class="text-danger">*</span></label>
                                                    <input type="text" class="form-control" placeholder="Post code" formControlName="postcode" [ngClass]="{ 'is-invalid': submitted && f.postcode.errors }">
                                                    <div *ngIf="submitted && f.postcode.errors" class="invalid-feedback">
                                                        <div *ngIf="f.postcode.errors.required">Post code is required</div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-md-12">
                                                <div class="form-group position-relative">
                                                    <label>Mobile Number<span class="text-danger">*</span></label>
                                                    <input type="text" class="form-control" placeholder="Mobile Number" formControlName="phone" [ngClass]="{ 'is-invalid': submitted && f.phone.errors }">
                                                    <div *ngIf="submitted && f.phone.errors" class="invalid-feedback">
                                                        <div *ngIf="f.phone.errors.required">Mobile number is required</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-12">
                                                <div class="form-group position-relative">
                                                    <label>Email <span class="text-danger">*</span></label>
                                                    <i-feather name="mail" class="fea icon-sm icons"></i-feather>
                                                    <input type="email" class="form-control pl-5" placeholder="Email" formControlName="email" [ngClass]="{ 'is-invalid': submitted && f.email.errors }">
                                                    <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                                                        <div *ngIf="f.email.errors.required">Email is required</div>
                                                        <div *ngIf="f.email.errors.email">Email must be a valid email address</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-12">
                                                <div class="form-group position-relative">
                                                    <label>Password <span class="text-danger">*</span></label>
                                                    <i-feather name="key" class="fea icon-sm icons"></i-feather>
                                                    <input type="password" formControlName="password" class="form-control pl-5" placeholder="Password" [ngClass]="{ 'is-invalid': submitted && f.password.errors }">
                                                    <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                                                        <div *ngIf="f.password.errors.required">Password is required</div>
                                                        <div *ngIf="f.password.errors.minlength">Password must be at least 6 characters</div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-md-12">
                                                <button (click)='onSubmit("Vendor")' class="btn btn-primary btn-block d-flex align-items-center justify-content-center">
                          <span>Register</span> 
                          <div class="spinner-border" *ngIf="isSpinner" role="status">
                            <span class="sr-only">Loading...</span>
                          </div></button>
                                            </div>

                                            <div class="mx-auto">
                                                <p class="mb-0 mt-3"><small class="text-dark mr-2">Already have an account ?</small> <a routerLink="/auth-login" class="text-dark font-weight-bold">Sign in</a></p>
                                            </div>
                                        </div>
                                    </form>
                                </ng-template>
                            </li>
                        </ul>
                        <div [ngbNavOutlet]="nav" class="pt-2"></div>

                    </div>
                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row-->
    </div>
    <!--end container-->
</section>
<!--end section-->
<!-- Hero End -->
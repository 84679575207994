import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/service/auth.service';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-auth-signup',
  templateUrl: './auth-signup.component.html',
  styleUrls: ['./auth-signup.component.css']
})
export class AuthSignupComponent implements OnInit {
  registergrowerForm: FormGroup;
  submitted = false;
  isSpinner = false;
  statelist: any = [];
  suburbslist: any = [];
  citylist: any = [];
  constructor(private formBuilder: FormBuilder, private _authService: AuthService, private _router: Router, private toastr: ToastrService) { }
 // retai_whole:any;
  ngOnInit(): void {
    this.registergrowerForm = this.formBuilder.group({
      name: ['', Validators.required],
      companyname: [''],
      stateid: ['', Validators.required],
      cityid: ['', Validators.required],
      suburbid: ['', Validators.required],
      postcode: ['', Validators.required],
      retai_whole: ['Retailars', Validators.required],
      phone: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.minLength(6)]],
      grower: [0],
      buyer: [0],
      vendor: [0],
      admin: [0],
    });
    this.getstate();
  }
  get f() { return this.registergrowerForm.controls; }

  getstate() {
    this._authService.getstate()
      .subscribe((data: any) => {
        console.log(data);
        this.statelist = data.success;
      });

  }
  getcity(event) {
    const newVal = event.target.value;
    let data = {
      state_id: newVal
    }
    this._authService.getcity(data)
      .subscribe((data: any) => {
        console.log(data);
        this.citylist = data.success;
      });
    this.getsuburbs(event)
  }

  getsuburbs(event) {
    const newVal = event.target.value;
    let data = {
      state_id: newVal
    }
    this._authService.getsuburbs(data)
      .subscribe((data: any) => {
        console.log(data);
        this.suburbslist = data.success;
      });
  }


  onSubmit(val) {
    if (val === 'Grower') {
      this.registergrowerForm.controls.grower.setValue(1);
    } else if (val === 'Buyer') {
      this.registergrowerForm.controls.buyer.setValue(1);
    } else if (val === 'Vendor') {
      this.registergrowerForm.controls.vendor.setValue(1);
    }
    console.log(this.registergrowerForm.value);
    //  debugger
    // return false;
    //  this.registergrowerForm.controls.type.setValue(val);
    // stop here if form is invalid
    this.submitted = true;
    if (this.registergrowerForm.invalid) {
      return;
    }
    this.isSpinner = true;
    this._authService.userRegister(this.registergrowerForm.value)
      .subscribe((data: any) => {
        console.log(data);
        this.isSpinner = false;
        if (data.status == true) {
          this.submitted = false;
          // this.registergrowerForm.reset();
          this.ngOnInit();
          this.toastr.success('Registration', data.message);
          this._router.navigateByUrl('/join-now');
        } else {
          this.toastr.error('Registration', data.message);

        }
      }, error => {
        this.isSpinner = false;
        this.toastr.error('Registration', 'Please try again later.');
      })

    // display form values on success


  }

}

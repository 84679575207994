import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthCoverLoginComponent } from './auth/auth-cover-login/auth-cover-login.component';
import { AuthCoverRePasswordComponent } from './auth/auth-cover-re-password/auth-cover-re-password.component';
import { AuthCoverSignupComponent } from './auth/auth-cover-signup/auth-cover-signup.component';
import { AuthLoginThreeComponent } from './auth/auth-login-three/auth-login-three.component';
import { AuthLoginComponent } from './auth/auth-login/auth-login.component';
import { AuthRePasswordThreeComponent } from './auth/auth-re-password-three/auth-re-password-three.component';
import { AuthRePasswordComponent } from './auth/auth-re-password/auth-re-password.component';
import { AuthSignupThreeComponent } from './auth/auth-signup-three/auth-signup-three.component';
import { AuthSignupComponent } from './auth/auth-signup/auth-signup.component';
import { FullregistrationComponent } from './auth/fullregistration/fullregistration.component';
import { BuyerlistComponent } from './buyer/buyerlist/buyerlist.component';
import { BuyerregistrationComponent } from './buyer/buyerregistration/buyerregistration.component';
import { ChatComponent } from './chat/chat.component';

import { IndexServicesComponent } from './core/components/index-services/index-services.component';
import { MasterPageComponent } from './core/components/master-page/master-page.component';
import { PageAboutusComponent } from './core/components/page-aboutus/page-aboutus.component';
import { PageComingsoonComponent } from './core/components/page-comingsoon/page-comingsoon.component';

import { PageContactOneComponent } from './core/components/page-contact-one/page-contact-one.component';
import { PageErrorComponent } from './core/components/page-error/page-error.component';
import { PageMaintenanceComponent } from './core/components/page-maintenance/page-maintenance.component';
import { PagePrivacyComponent } from './core/components/page-privacy/page-privacy.component';
import { PageTermsComponent } from './core/components/page-terms/page-terms.component';
import { SearchComponent } from './core/components/search/search.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { EmailPasswordResetComponent } from './email/email-password-reset/email-password-reset.component';
import { GrowerlistComponent } from './grower/growerlist/growerlist.component';
import { GrowerregistrationComponent } from './grower/growerregistration/growerregistration.component';
import { GrowersubscriptionComponent } from './grower/growersubscription/growersubscription.component';
import { Availability_produceComponent } from './master/availability_produce/availability_produce.component';
import { BreedComponent } from './master/breed/breed.component';
import { CertificateComponent } from './master/certificate/certificate.component';
import { CropComponent } from './master/crop/crop.component';
import { Growing_environmentComponent } from './master/growing_environment/growing_environment.component';
import { Growing_mediumComponent } from './master/growing_medium/growing_medium.component';
import { PackingComponent } from './master/packing/packing.component';
import { StripComponent } from './master/strip/strip.component';
import { SubscriptionComponent } from './master/subscription/subscription.component';
import { SuburbComponent } from './master/suburb/suburb.component';
import { SerarchprofileComponent } from './serarchprofile/serarchprofile.component';
import { AuthGuard } from './service/auth.guard';
import { SwitcherComponent } from './shared/switcher/switcher.component';
import { VendorlistComponent } from './vendor/vendorlist/vendorlist.component';
import { VendorprofileComponent } from './vendor/vendorprofile/vendorprofile.component';

const routes: Routes = [
  {
    path: '',
    component: MasterPageComponent,
    children: [
      { path: '', component: IndexServicesComponent },
      { path: 'index', component: IndexServicesComponent },
      { path: 'index-services', component: IndexServicesComponent },
      { path: 'aboutus', component: PageAboutusComponent },
      { path: 'contactus', component: PageContactOneComponent },
     
      { 
        path: 'profile1', 
        canActivate: [AuthGuard],
        component: FullregistrationComponent 
      },
      
      {
         path: 'search/:id/:flagid/:stateid',
         canActivate: [AuthGuard],
         component: SearchComponent 
      },
      {
        path: 'certificate',
        canActivate: [AuthGuard],
        component: CertificateComponent 
        
      },
      {
        path: 'suburb',
        canActivate: [AuthGuard],
        component: SuburbComponent 
        
      },
      {
        path: 'crop',
        canActivate: [AuthGuard],
        component: CropComponent         
      },
      {
        path: 'breed',
        canActivate: [AuthGuard],
        component: BreedComponent         
      },
      {
        path: 'packing',
        canActivate: [AuthGuard],
        component: PackingComponent         
      },
      {
        path: 'subscription',
        canActivate: [AuthGuard],
        component: SubscriptionComponent         
      },
      {
        path: 'growing_environment',
        canActivate: [AuthGuard],
        component: Growing_environmentComponent         
      },
      {
        path: 'growing_medium',
        canActivate: [AuthGuard],
        component: Growing_mediumComponent         
      },
       {
        path: 'availability_produce',
        canActivate: [AuthGuard],
        component: Availability_produceComponent         
      },
       {
        path: 'strip',
        canActivate: [AuthGuard],
        component: StripComponent         
      },
      {
        path: 'profile',
        canActivate: [AuthGuard],
        component: GrowerregistrationComponent         
      },
      {
        path: 'profile/:id/:type/:stateid',
        canActivate: [AuthGuard],
        component: GrowerregistrationComponent         
      },
      {
        path: 'growersubscription',
        canActivate: [AuthGuard],
        component: GrowersubscriptionComponent         
      },
      {
        path: 'buyerprofile/:id/:type/:stateid',
        canActivate: [AuthGuard],
        component: BuyerregistrationComponent         
      },
      {
        path: 'buyerprofile',
        canActivate: [AuthGuard],
        component: BuyerregistrationComponent         
      },
      {
        path: 'vendorprofile',
        canActivate: [AuthGuard],
        component: VendorprofileComponent         
      },
      {
        path: 'vendorlist',
        canActivate: [AuthGuard],
        component: VendorlistComponent         
      },
      {
        path: 'buyerlist',
        canActivate: [AuthGuard],
        component: BuyerlistComponent         
      },
      {
        path: 'growerlist',
        canActivate: [AuthGuard],
        component: GrowerlistComponent         
      },
      {
        path: 'dashboard',
        canActivate: [AuthGuard],
        component: DashboardComponent         
      },
      {
        path: 'searchprofile',
        canActivate: [AuthGuard],
        component: SerarchprofileComponent         
      },
      {
        path: 'chat',
        canActivate: [AuthGuard],
        component: ChatComponent         
      },
      {
        path: 'privacypolicy',
        component: PagePrivacyComponent         
      },
      {
        path: 'returnrefundpolicy',
        component: PageTermsComponent         
      },
      { path: '#', component: SwitcherComponent },
    ]
  },

  { path: 'auth-login', component: AuthLoginComponent },
  { path: 'join-now', component: AuthLoginComponent },
  { path: 'auth-cover-re-password', component: AuthCoverRePasswordComponent },
  { path: 'auth-cover-signup', component: AuthCoverSignupComponent },
  { path: 'auth-re-password', component: AuthRePasswordComponent },
  { path: 'auth-signup', component: AuthSignupComponent },
  { path: 'page-comingsoon', component: PageComingsoonComponent },
  { path: 'page-error', component: PageErrorComponent },
  { path: 'page-maintenance', component: PageMaintenanceComponent },
  { path: 'email-password-reset', component: EmailPasswordResetComponent },

];

@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload', scrollPositionRestoration: 'top', relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }

import { Component, OnInit, PipeTransform } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/service/auth.service';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { PostService } from 'src/app/service/post.service';
import { StorageService } from 'src/app/service/storage.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { OwlOptions } from 'ngx-owl-carousel-o';
//import * as $ from 'jquery';
declare var $: any;


@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit {
  searchText;
  isSpinnerlist: boolean;
  page = 1;
  pageSize = 30;
  collectionSize = 0;
  collectionSizeenv = 0;
  collectionSizesw = 0;
  collectionSizecertig = 0;
  masterlist: any = [];
  allmasterlist: any = [];
  IsFilter: boolean = true
  isadmin = 0;
  isgrower = 0;
  isbuyer = 0;
  isvendor = 0;
  //List
  statelist: any = [];
  filterstatelist: any = [];//Filter
  certilist: any = [];
  filtercertilist: any = []; //Filter
  gmlist: any = [];
  filtergmlist: any = []; //Filter
  avplist: any = [];
  filteravplist: any = []; //Filter
  userid: any;
  IsSpinnerall: boolean = true;
  urlid: string;
  urlstateid: string;
  urlflagid: string;
  croplist: any = [];
  breedlist: any = [];
  tmppackingarray: any = [];
  packingarray: any = [{ id: 1, name: 'Box' }, { id: 2, name: 'Loose' }, { id: 3, name: 'Punnet' }];
  cropid: any;
  retai_whole: any = [];
  whole: number = 2;
  retail: number = 2;
  ratailcount: number=0;
  wholecount: number=0;
  allfilterflag: number = 0;
  wholesalercheckflag: number = 0;
  retailercheckflag: number = 0;
  userpic = "assets/images/user/user.png";
  wholesallerchecked: boolean = false;
  constructor(private formBuilder: FormBuilder, private _authService: AuthService, private _router: Router, private toastr: ToastrService, public activatedRoute: ActivatedRoute, public postservice: PostService, public storageService: StorageService, private modalService: NgbModal) {
    this.urlid = this.activatedRoute.snapshot.paramMap.get('id');//usertype
    this.urlstateid = this.activatedRoute.snapshot.paramMap.get('stateid');//stateid
    this.urlflagid = this.activatedRoute.snapshot.paramMap.get('flagid');//flagid
    // alert(this.urlid)
    // alert(this.urlstateid)
    // alert(this.urlflagid)
    
    
    if (this.urlstateid) {
      //state_
      //document.getElementById('state_'+this.urlstateid).setAttribute('checked', 'checked');
      //document.querySelector("#state_"+this.urlstateid);
      //document.getElementById('state_'+this.urlstateid).checked = true;
      //document.getElementById("myCheckBoxID").checked = true;
      //document.getElementById(`state_${this.urlstateid}`).checked = true;
      setTimeout(() => {
        $("#state_"+this.urlstateid).prop('checked', true);
      }, 5000);
      
      this.filterstatelist.push(Number(this.activatedRoute.snapshot.paramMap.get('stateid')))
    }

  }


  ngOnInit(): void {
    this.getstatelist();
    this.getallfilter();
    //this.getgrowerlist();
    this.getcroplist();
    
    this.getcertificatefilter();
    this.getgmlist();
    this.getavipfilter();
    this.getretail_whole()
    this.storageService.get('logininfo').then(
      (res) => {
        console.log(res);
        if (res.id) {
          this.userid = res.id;
          this.isadmin = res.admin;
          this.isgrower = res.grower;
          this.isbuyer = res.buyer;
          this.isvendor = res.vendor;


        }
      })
  }
  filterhide(val) {
    if (val == true) {
      this.IsFilter = false;
    } else {
      this.IsFilter = true;
    }
  }
  hideall() {
    this.IsFilter = true

  }
  getgrowerlist() {
    let data = {
      userid: 1
    };
    this._authService.getuseralllist(data)
      .subscribe((data: any) => {
        console.log(data);
        this.IsSpinnerall = false;
        //this.certificatelist = data.success;
        this.collectionSize = data.success.length;
        this.allmasterlist = data.success;
        this.masterlist = data.success

      });
  }
  goprofilepage(id) {

    if (this.urlid == '1') {
      this._router.navigateByUrl('/profile/' + id + '/' + this.urlid + '/' + this.urlstateid);
    } else if (this.urlid == '2') {
      this._router.navigateByUrl('/buyerprofile/' + id + '/' + this.urlid + '/' + this.urlstateid);
    } else {
      this._router.navigateByUrl('/buyerprofile/' + id + '/' + this.urlid + '/' + this.urlstateid);
    }

  }
  isback(){
    this._router.navigateByUrl('/dashboard');

  }

  filternew(e, id, type) {
    console.log(e);
    debugger;
  }
  filter(e, id, type) {
    //alert("aaaa");
    //console.log(e);
    //console.log(e.target.checked);
    //debugger;
    if (type == 1) {
      //States
      let checkarray = e.target.checked;
      //debugger
      if (checkarray == true) {
        // Pushing the object into array
        this.filterstatelist.push(id);
        
        
      } else {
        let removeIndex = this.filterstatelist.findIndex(itm => itm === id);
        if (removeIndex !== -1)
          this.filterstatelist.splice(removeIndex, 1);
          console.log(this.filterstatelist);
          debugger;
      }
      this.ratailcount = 0;
      this.wholecount = 0;
      console.log(this.filterstatelist);
       debugger
    } else if (type == 2) {
      //Crtificates
      let checkarray = e.target.checked;
      //debugger
      if (checkarray == true) {
        // Pushing the object into array
        this.filtercertilist.push(id);
      } else {
        let removeIndex = this.filtercertilist.findIndex(itm => itm === id);
        if (removeIndex !== -1)
          this.filtercertilist.splice(removeIndex, 1);
      }
      console.log(this.filtercertilist);
      // debugger
    } else if (type == 3) {
      //Growing Types
      let checkarray = e.target.checked;
      //debugger
      if (checkarray == true) {
        // Pushing the object into array
        let data = {
          id: id.id,
          tabletype: id.tabletype,
          type: Number(id.type)
        }
        this.filtergmlist.push(data);
      } else {
        let removeIndex = this.filtergmlist.findIndex(itm => itm.id === id.id && itm.type === id.type);
        if (removeIndex !== -1)
          this.filtergmlist.splice(removeIndex, 1);
      }
      console.log(this.filtergmlist);
      // debugger
    } else if (type == 4) {
      //Availability produce
      let checkarray = e.target.checked;
      //debugger
      if (checkarray == true) {
        // Pushing the object into array
        this.filteravplist.push(id);
      } else {
        let removeIndex = this.filteravplist.findIndex(itm => itm === id);
        if (removeIndex !== -1)
          this.filteravplist.splice(removeIndex, 1);
      }
      console.log(this.filteravplist);
      //debugger
    } else if (type == 5) {
      //0-Retailars //1-Wholesaller
      // this.retai_whole = id
      let checkarray = false;
      if(e=="checked"){
        checkarray = true;  
      }else{
        checkarray = e.target.checked;
      }
      
      //alert("aaaa");
      //alert(checkarray);
      //debugger
      if (checkarray == true) {
        if (id == 0) {
          this.retail = 0
        } else {
          this.whole = 1
        }
      } else {
        if (id == 0) {
          this.retail = 2
        } else {
          this.whole = 2
        }
      }

    }
    if (this.IsFilter) {
      this.getallfilter()
    }


  }

  //API Call
  getretail_whole() {
    
      // let data = {
      //   id: 1
      // }
      // this.postservice.getretail_whole(data)
      //   .subscribe((data: any) => {
      //     console.log(data);
      //     alert(this.allfilterflag);
      //     alert("W");
      //     if(this.allfilterflag==0){
      //       let j = 1;
      //       let m = 1;
      //       for (let i = 0; i < data.success.length; i++) {
      //         const retai_whole = data.success[i].retai_whole;
      //         if (retai_whole == 0) {
      //           this.ratailcount = j++;
      //         } else {
      //           this.wholecount = m++;
      //         }
      //       }
      //     }
      //   });
   
    
  }
  getstatelist() {
    let data = {
      isgrower: this.urlid == '1' ? 1 : 0,
      isbuyer: this.urlid == '2' ? 1 : 0,
      isvendor: this.urlid == '3' ? 1 : 0,
    }
    this.postservice.getstatefilter(data)
      .subscribe((data: any) => {
        console.log(data);
        this.isSpinnerlist = false;
        this.statelist = data.success;
        //$("#state_"+this.urlstateid).prop('checked', true);
      });
  }
  getcertificatefilter() {
    this.postservice.getcertificatefilter()
      .subscribe((data: any) => {
        console.log(data);
        this.isSpinnerlist = false;
        this.certilist = data.success;
      });
  }
  getgmlist() {
    this.postservice.getgmfilter()
      .subscribe((data: any) => {
        console.log(data);
        this.isSpinnerlist = false;
        this.gmlist = data.success;
      });
  }
  getavipfilter() {
    this.postservice.getavipfilter()
      .subscribe((data: any) => {
        console.log(data);
        this.isSpinnerlist = false;
        this.avplist = data.success;
      });
  }
  getcroplist() {
    this.postservice.getcrop()
      .subscribe((data: any) => {
        console.log(data);
        this.croplist = data.success;
      });
  }
  modelbreedopen(content, id, $event) {
    let checked = $event.target.checked;
    if (checked == true) {
      this.cropid = id
      let data = {
        id: id,
        growerid: this.userid
      };
      this.postservice.getidcropbreed(data)
        .subscribe((data: any) => {
          console.log(data);
          if (data.success.length > 0) {
            this.breedlist = data.success;
          }
        });
      this.modalService.open(content, { centered: true });
    } else {
      for (let i = 0; i < this.tmppackingarray.length; i++) {

        let removeIndex = this.tmppackingarray.findIndex(itm => itm.cropid === id);
        if (removeIndex !== -1) {
          this.tmppackingarray.splice(removeIndex, 1);
        }

      }

      this.getallfilter()

    }
  }

  savebreed(iscrop) {
    console.log(this.tmppackingarray);

    this.getallfilter();
    this.modalService.dismissAll();

  }
  packingselect(values: any, breedid, pkid) {

    console.log(this.tmppackingarray);
    //debugger;
    if (values.currentTarget.checked == true) {
      if (this.tmppackingarray.length > 0) {
        let objIndex = this.tmppackingarray.findIndex(itm => Number(itm.breedid) == Number(breedid));
        if (objIndex !== -1) {
          //Log object to Console.
          console.log("Before update: ", this.tmppackingarray[objIndex])
          // debugger
          //Update object's name property.
          if (pkid == 1) {
            this.tmppackingarray[objIndex].box = 1
          }
          if (pkid == 2) {
            this.tmppackingarray[objIndex].loos = 1
          }
          if (pkid == 3) {
            this.tmppackingarray[objIndex].punnet = 1
          }


          //Log object to console again.
          console.log("After update: ", this.tmppackingarray[objIndex])
          //debugger


        } else {
          let cropList =
          {
            growerid: this.userid,
            cropid: this.cropid,
            breedid: Number(breedid) != 0 ? Number(breedid) : 0,
            box: pkid === 1 ? 1 : 0,
            loos: pkid === 2 ? 1 : 0,
            punnet: pkid === 3 ? 1 : 0,
          };

          this.tmppackingarray.push(cropList);
        }
      } else {
        let cropList =
        {
          growerid: this.userid,
          cropid: this.cropid,
          breedid: Number(breedid) != 0 ? Number(breedid) : 0,
          box: pkid === 1 ? 1 : 0,
          loos: pkid === 2 ? 1 : 0,
          punnet: pkid === 3 ? 1 : 0,
        };
        debugger
        this.tmppackingarray.push(cropList);

      }
    } else {

      let removeIndex = this.tmppackingarray.findIndex(itm => Number(itm.breedid) == Number(breedid));
      if (removeIndex !== -1) {
        if ((this.tmppackingarray[removeIndex].box == 0 && this.tmppackingarray[removeIndex].loos == 0) ||
          (this.tmppackingarray[removeIndex].box == 0 && this.tmppackingarray[removeIndex].punnet == 0) ||
          (this.tmppackingarray[removeIndex].loos == 0 && this.tmppackingarray[removeIndex].punnet == 0) ||
          (this.tmppackingarray[removeIndex].loos == 0 && this.tmppackingarray[removeIndex].box == 0) ||
          (this.tmppackingarray[removeIndex].punnet == 0 && this.tmppackingarray[removeIndex].box == 0) ||
          (this.tmppackingarray[removeIndex].punnet == 0 && this.tmppackingarray[removeIndex].loos == 0)) {
          //this.tmppackingarray.splice(removeIndex, 1);
          if (pkid == 1) {
            this.tmppackingarray[removeIndex].box = 0
          }
          if (pkid == 2) {
            this.tmppackingarray[removeIndex].loos = 0
          }
          if (pkid == 3) {
            this.tmppackingarray[removeIndex].punnet = 0
          }
          //debugger
        } else {
          if (pkid == 1) {
            this.tmppackingarray[removeIndex].box = 0
          }
          if (pkid == 2) {
            this.tmppackingarray[removeIndex].loos = 0
          }
          if (pkid == 3) {
            this.tmppackingarray[removeIndex].punnet = 0
          }


        }

      }

      //  let removeIndex = this.tmppackingarray.findIndex(itm => Number(itm.breedid) != 0 ? Number(itm.breedid) : this.cropid === (Number(breedid) != 0) ? Number(breedid) : this.cropid);
      // if (removeIndex !== -1)
      // this.tmppackingarray.splice(removeIndex, 1);
      console.log(this.tmppackingarray);
      debugger;
    }


  }

  closebreed() {
    this.modalService.dismissAll();
  }


  //GetAll Filter() 
  getallfilter() {
    this.IsSpinnerall = true;
    let tmpenv = [];
    let tmpgromed = [];
    this.IsFilter = true;
    for (let i = 0; i < this.filtergmlist.length; i++) {
      const id = this.filtergmlist[i].id;
      const tabletype = this.filtergmlist[i].tabletype;
      const type = this.filtergmlist[i].type;
      if (type == 1) {
        tmpenv.push(id)
      } else {
        tmpgromed.push(id)
      }


    }

    
    
    let data = {
      isgrower: this.urlid == '1' ? 1 : 0,
      isbuyer: this.urlid == '2' ? 1 : 0,
      isvendor: this.urlid == '3' ? 1 : 0,
      statelist: this.filterstatelist.join(),
      statecount: this.filterstatelist.length > 0 ? 1 : 0,
      certificate: this.filtercertilist.join(),
      certificatecount: this.filtercertilist.length > 0 ? 1 : 0,
      tmpenv: tmpenv.join(),
      tmpenvcount: tmpenv.length > 0 ? 1 : 0,
      tmpgromed: tmpgromed.join(),
      tmpgromedcount: tmpgromed.length > 0 ? 1 : 0,
      aviprodu: this.filteravplist.join(),
      aviproducount: this.filteravplist.length > 0 ? 1 : 0,
      produce: this.tmppackingarray,
      retail: this.retail,
      whole: this.whole,
      flagid: this.urlflagid
    }
    console.log(data)
    debugger
    this.postservice.getallfilter(data)
      .subscribe((data: any) => {
        console.log(data);
        //debugger
        
        this.IsSpinnerall = false;
        if (data.newArray.length > 0) {
          //const uniqueArray = [...new Set(data.newArray.map(item => item.id))]; // [ 'A', 'B']
          const key = 'id';
          const uniqueArray = [...new Map(data.newArray.map(item =>
            [item[key], item])).values()];

          // var uniqueArray = [...new Set( data.newArray)]
          this.allfilterflag = 1;
          if(this.wholesalercheckflag!=1 && this.retailercheckflag!=1){
            this.ratailcount = 0;
            this.wholecount = 0;
          }
          let j = 1;
          let m = 1;
          for (let i = 0; i < data.success.length; i++) {
            const retai_whole = data.success[i].retai_whole;
            if (retai_whole == 0) {
              this.ratailcount = j++;
            } else {
              this.wholecount = m++;
            }
          }

          this.collectionSize = uniqueArray.length;
          this.allmasterlist = uniqueArray;
          this.masterlist = uniqueArray
        } else {
          this.allfilterflag = 1;
          if(this.wholesalercheckflag!=1 && this.retailercheckflag!=1){

            //if(this.urlstateid!=""){
              this.ratailcount = 0;
              this.wholecount = 0;
           // }
            
          }
          
          let j = 1;
          let m = 1;
          //alert("All");
          for (let i = 0; i < data.success.length; i++) {
            const retai_whole = data.success[i].retai_whole;
            if (retai_whole == 0) {
              this.ratailcount = j++;
            } else {
              this.wholecount = m++;
            }
          }

          this.collectionSize = data.success.length;
          this.allmasterlist = data.success;
          this.masterlist = data.success
        }

        if(this.urlflagid=="1"){
          this.wholesallerchecked = true;
         // this.whole = 1;
         // this.IsFilter = true;
          //this.getallfilter();
          //wholesellerId
          if(this.wholesalercheckflag==0){
            this.filter("checked",1,5);
            this.wholesalercheckflag = 1;
          }
          //this.filter("checked",1,5);
          
        }
        
        this.isSpinnerlist = false;
      });
  }


  retailecheck(vl)
  {
    this.retailercheckflag = 1;
  }

  wholesalercheck(vl)
  {
    this.wholesalercheckflag = 1;
  }
}
import { PostService } from 'src/app/service/post.service';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/service/auth.service';
import { StorageService } from 'src/app/service/storage.service';

@Component({
  selector: 'app-auth-login',
  templateUrl: './auth-login.component.html',
  styleUrls: ['./auth-login.component.css']
})
export class AuthLoginComponent implements OnInit {
  loginForm: FormGroup;
  submitted = false;
  isSpinner = false;
  appid = 'da9f1209-c5c2-46a7-9e43-244224d3e364';
  userid: any;

  constructor(private formBuilder: FormBuilder, public storageService: StorageService, private _authService: AuthService, private toastr: ToastrService, private _router: Router, public postsrv: PostService) { }



  ngOnInit(): void {
    this.loginForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.minLength(6)]]
    });

  }
  get f() { return this.loginForm.controls; }

  onSubmit1() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.loginForm.invalid) {
      return;
    }

    // display form values on success
    //alert('SUCCESS!! :-)\n\n' + JSON.stringify(this.loginForm.value, null, 4));
    localStorage.setItem('logininfo', this.loginForm.value)
    this.storageService.store('logininfo', this.loginForm.value);
    this.submitted = false;
    this.loginForm.reset();
    // this.storageService.get('logininfo').then(
    //   (res) => {
    //     console.log(res);
    //   }
    // )
  }

  onSubmit() {
    this.submitted = true;
    if (this.loginForm.invalid) {
      return;
    }
    this.isSpinner = true;
    this._authService.login(this.loginForm.value)
      .subscribe((data: any) => {
        console.log(data);
        this.userid = data.result.id;
        this.isSpinner = false;
        if (data.status == true) {
          this.submitted = false;
          this.storageService.store('token', data.result.accessToken);
          this.storageService.store('logininfo', data.result);
          this.toastr.success('Login', data.message);
          if (data.result.isSubscribed == 0 || data.result.isSubscribed == null) {
            // setTimeout(() => {

            // }, 300);
            this.onesiganl(data.result.oneSignalId);
            this._router.navigateByUrl('/');

          } else {
            this._router.navigateByUrl('/');
          }

        } else {
          this.toastr.error('Login', data.message);
        }
      }, error => {
        this.isSpinner = false;
        this.toastr.error('Login', 'Please try again later.');
      })

  }
  onesiganl(valone) {
    debugger
    var newdata = this;
    var OneSignal = window['OneSignal'] || [];
    var newoneid = "";
    console.log("Init OneSignal");

    OneSignal.push(["init", {

      appId: this.appid,
      autoRegister: true,
      allowLocalhostAsSecureOrigin: true,
      notifyButton: {
        enable: false
      }
    }]);

    console.log('OneSignal Initialized');

    OneSignal.push(function () {

      console.log('Register For Push');
      OneSignal.push(["registerForPushNotifications"])

      OneSignal.getUserId().then((userId: any) => {
        console.log('4. User ID is', userId);
        newoneid = userId;

        //_oneSignalId = userId;
        //_isSubscribed = true;
        setTimeout(() => {
          newdata.saveonesignaldata(newoneid);
        }, 2000);

      });


    });

    // if (valone) {
    //   debugger
    //   if (valone == newoneid) {
    //     OneSignal.push(function () {
    //       // Occurs when the user's subscription changes to a new value.
    //       OneSignal.on('subscriptionChange', (isSubscribed: any) => {
    //         console.log("The user's subscription state is now:", isSubscribed);
    //         //_isSubscribed = isSubscribed;
    //         OneSignal.getUserId().then((userId: any) => {
    //           console.log("User ID is", userId);
    //           // this.saveonesignaldata(userId)
    //           // _oneSignalId = userId;
    //         });
    //       });
    //     });
    //   }
    // }
  }

  saveonesignaldata(val) {
    let param = {
      userid: this.userid,
      oneSignalId: val,
      isSubscribed: 1
    }
    this.postsrv.saveonesiganldata(param)
      .subscribe((data: any) => {
        {
          //alert("ok")
          console.log(data);
          debugger

        }
      })
  }
}

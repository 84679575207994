<!-- Hero Start -->
<section class="bg-profile d-table w-100 bg-primary" id="home" style="background: url('assets/images/account/bg.png') center center;">

    <!--ed container-->
</section>
<!--end section-->
<!-- Hero End -->

<!-- Profile Start -->
<section class="section mt-60">
    <div class="container">
        <div class="row">

            <!--end col-->

            <div class="col-lg-12 col-12">
                <div class="card border-0 rounded shadow">

                    <div class="border-bottom pb-4 card-body">
                        <h5 style="float: left;">Packing List</h5>
                        <a (click)="isedit(isEdit)" style="float: right;" class="btn btn-primary btn-sm"> {{isEdit?' List Packing':'
              Add Packing' }} </a>
                    </div>
                    <div class="card-body" *ngIf="isEdit">
                        <form [formGroup]="breedForm">
                            <div class="row mt-4">
                                <div class="col-md-6">
                                    <div class="form-group position-relative">
                                        <label>Packing Name</label>
                                        <input name="name" id="first" type="text" class="form-control" placeholder="Packing Name" formControlName="name" [ngClass]="{ 'is-invalid': submitted && f.breedname.errors }">
                                        <div *ngIf="submitted && f.name.errors" class="invalid-feedback">
                                            <div *ngIf="f.name.errors.required">Breed name is required</div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-6" *ngIf="isactivecheck">
                                    <div class="form-group position-relative">
                                        <label>Is Active</label>
                                        <select formControlName="isactive" class="form-control custom-select">
                      <option value="1">Active</option>
                      <option value="0">Deactive</option>
                    </select>
                                    </div>
                                </div>
                            </div>
                            <!--end row-->
                            <div class="row">
                                <div class="col-sm-12">
                                    <button (click)='onSubmit()' class="btn btn-primary">
                    <span>Submit</span>
                    <div class="spinner-border" *ngIf="isSpinner" role="status">
                      <span class="sr-only">Loading...</span>
                    </div>
                  </button>
                                    <input type="submit" (click)="isedit(isEdit)" id="submit" class="btn btn-dark ml-2" value="Cancel">
                                </div>
                                <!--end col-->
                            </div>
                            <!--end row-->
                        </form>
                    </div>

                    <div class="card-body" *ngIf="!isEdit">
                        <div class="row">
                            <div class="col-md-12 mt-4">
                                <!-- <form>
                  <div class="form-group form-inline">
                    Full text search: <input class="form-control ml-2" type="text" [formControl]="filter"/>
                </div>
                </form> -->

                                <table class="table table-striped">
                                    <thead>
                                        <tr>
                                            <th scope="col">No.</th>
                                            <th scope="col">Packing Name</th>
                                            <!-- <th scope="col">Breed Name</th> -->
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let item of masterlist; index as i">
                                            <th scope="row">{{item.id}}</th>
                                            <td>
                                                {{item.name}}
                                            </td>
                                            <!-- <td>
                        {{item.breedname}}
                      </td> -->

                                        </tr>
                                    </tbody>
                                </table>
                                <div class="spinner-border" style="text-align: center;" *ngIf="isSpinnerlist" role="status">
                                    <span class="sr-only">Loading...</span>
                                </div>
                                <p *ngIf="collectionSize==0" style="text-align: center;">No Data Available</p>

                                <div class="d-flex justify-content-between p-2">
                                    <ngb-pagination [(page)]="page" [pageSize]="pageSize" [collectionSize]="collectionSize" (pageChange)="refreshCountries()" [maxSize]="5" [rotate]="true" [boundaryLinks]="true"></ngb-pagination>

                                    <select class="custom-select" style="width: auto" [(ngModel)]="pageSize" (ngModelChange)="refreshCountries()">
                    <option [ngValue]="2">2 items per page</option>
                    <option [ngValue]="4">4 items per page</option>
                    <option [ngValue]="6">6 items per page</option>
                  </select>
                                </div>

                            </div>
                            <!--end col-->
                        </div>
                    </div>
                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row-->
    </div>
    <!--end container-->
</section>
<!--end section-->
<!-- Profile Setting End -->



<!-- Back to top -->
<a href="javascript: void(0);" class="btn btn-icon btn-soft-primary back-to-top" id="back-to-top" [ngxScrollTo]="'#home'">
    <i-feather name="arrow-up" class="icons"></i-feather>
</a>
<!-- Back to top -->
<!-- Hero Start -->
<section class="bg-profile d-table w-100 bg-primary" id="home" style="background: url('assets/images/account/bg.png') center center;">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="card public-profile border-0 rounded shadow" style="z-index: 1;">
                    <div class="card-body">
                        <div class="row align-items-center">
                            <div class="col-lg-2 col-md-3 text-md-left text-center">
                                <img src="{{ userpic }}" class="avatar avatar-large rounded-circle shadow d-block mx-auto" alt="">
                            </div>
                            <!--end col-->

                            <div class="col-lg-10 col-md-9">
                                <div class="row align-items-end">
                                    <div class="col-md-7 text-md-left text-center mt-4 mt-sm-0">
                                        <h3 class="title mb-0">{{ name }}</h3>
                                        <small class="text-muted h6 mr-2">
                      {{ isadmin }} <span *ngIf="isadmin==1">,</span> {{ isgrower }} <span *ngIf="isbuyer==1">,</span>
                      {{ isbuyer }} <span *ngIf="isvendor==1">,</span> {{ isvendor }}
                    </small>
                                        <p *ngIf="isEdit">
                                            <input type="file" name="profileimg" id="upload" (change)="uploadprofile($event)" hidden />
                                            <label class="profilepic" for="upload">Choose Picture</label>
                                            <!-- <a href="javascript:void(0)" class="btn btn-primary mt-2 profilepic">    Change Picture</a> -->
                                        </p>

                                        <!-- <ul class="list-inline mb-0 mt-3">
                        <li class="list-inline-item mr-2"><a href="javascript:void(0)" class="text-muted"
                            title="Instagram">
                            <i-feather name="instagram" class="fea icon-sm mr-2"></i-feather>
                            krista_joseph
                          </a></li>
                        <li class="list-inline-item"><a href="javascript:void(0)" class="text-muted" title="Linkedin">
                            <i-feather name="linkedin" class="fea icon-sm mr-2"></i-feather>
                            Krista Joseph
                          </a></li>
                      </ul> -->
                                    </div>
                                    <!--end col-->
                                    <div class="col-md-5 text-md-right text-center">
                                        <ul class="list-unstyled social-icon social mb-0 mt-4">
                                            <li class="list-inline-item">
                                                <a routerLink="/vendorprofile" class="rounded" placement="bottom" ngbTooltip="Profile">
                                                    <i-feather name="user" class="fea icon-sm fea-social"></i-feather>
                                                </a>
                                            </li>
                                            <li class="list-inline-item ml-1">
                                                <a routerLink="/chat" class="rounded" placement="bottom" ngbTooltip="Messages">
                                                    <i-feather name="message-circle" class="fea icon-sm fea-social"></i-feather>
                                                </a>
                                            </li>

                                            <li class="list-inline-item ml-1">
                                                <a routerLink="/growersubscription" class="rounded" placement="bottom" ngbTooltip="Subscription">
                                                    <i-feather name="credit-card" class="fea icon-sm fea-social"></i-feather>
                                                </a>
                                            </li>
                                            <li class="list-inline-item ml-1">
                                                <a routerLink="/vendorprofile" class="rounded" placement="bottom" ngbTooltip="Settings">
                                                    <i-feather name="tool" class="fea icon-sm fea-social"></i-feather>
                                                </a>
                                            </li>
                                        </ul>

                                    </div>

                                </div>

                            </div>

                        </div>

                    </div>
                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row-->
    </div>
    <!--ed container-->
</section>
<!--end section-->
<!-- Hero End -->

<!-- Profile Start -->
<section class="section mt-60">
    <div class="container">
        <div class="row">

            <!--end col-->

            <div class="col-lg-12 col-12">
                <div class="card border-0 rounded shadow">

                    <div class="border-bottom pb-4 card-body">
                        <h5 style="float: left;">Profile Details</h5>
                        <a (click)="isedit(isEdit)" style="float: right;" class="btn btn-primary btn-sm"> Edit Profile </a>

                    </div>
                    <!-- <div class="card-body" *ngIf="isEdit">
            

          </div> -->
                    <div class="card-body" *ngIf="isEdit">

                        <ul ngbNav #nav="ngbNav" [(activeId)]="active" (navChange)="tabChange($event)" class="nav-pills nav-justified">

                            <li [ngbNavItem]="0" [destroyOnHide]="false" [disabled]="oneli">
                                <a ngbNavLink class="rounded">
                                    <div class="text-center pt-1 pb-1">
                                        <h4 class="title font-weight-normal mb-0">Personal Detail</h4>
                                    </div>
                                </a>
                                <ng-template ngbNavContent>
                                    <div class="spinner-border" *ngIf="userSpinner" role="status">
                                        <span class="sr-only">Loading...</span>
                                    </div>
                                    <form [formGroup]="registergrowerForm">
                                        <div class="row mt-4">
                                            <div class="col-md-6">
                                                <div class="form-group position-relative">
                                                    <label>Name</label>
                                                    <i-feather name="user" class="fea icon-sm icons"></i-feather>
                                                    <input type="text" class="form-control pl-5" placeholder="Name" formControlName="name" [ngClass]="{ 'is-invalid': submitted && f.name.errors }">
                                                    <div *ngIf="submitted && f.name.errors" class="invalid-feedback">
                                                        <div *ngIf="f.name.errors.required">Name is required</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <!--end col-->
                                            <div class="col-md-6">
                                                <div class="form-group position-relative">
                                                    <label>Company Name</label>
                                                    <i-feather name="user-check" class="fea icon-sm icons"></i-feather>
                                                    <input type="text" class="form-control pl-5" placeholder="Company name" formControlName="companyname">
                                                </div>
                                            </div>
                                            <!--end col-->


                                            <div class="col-md-6">
                                                <div class="form-group position-relative">
                                                    <label>State<span class="text-danger">*</span></label>
                                                    <select (change)="getsuburbs($event,0)" formControlName="stateid" class="form-control custom-select" [ngClass]="{ 'is-invalid': submitted && f.stateid.errors }">
                            <option value="">Select</option>
                            <option *ngFor="let item of statelist" value="{{ item.id }}">{{ item.statename }}</option>
                          </select>
                                                    <div *ngIf="submitted && f.stateid.errors" class="invalid-feedback">
                                                        <div *ngIf="f.stateid.errors.required">State is required</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="form-group position-relative">
                                                    <label>City<span class="text-danger">*</span></label>
                                                    <select formControlName="cityid" class="form-control custom-select" [ngClass]="{ 'is-invalid': submitted && f.cityid.errors }">
                            <option value="">Select</option>
                            <option *ngFor="let item of citylist" value="{{ item.id }}">{{ item.cityname }}
                            </option>
                          </select>
                                                    <div *ngIf="submitted && f.cityid.errors" class="invalid-feedback">
                                                        <div *ngIf="f.cityid.errors.required">City is required</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="form-group position-relative">
                                                    <label>Suburb<span class="text-danger">*</span></label>
                                                    <select formControlName="suburbid" class="form-control custom-select" [ngClass]="{ 'is-invalid': submitted && f.suburbid.errors }">
                            <option value="">Select</option>
                            <option *ngFor="let item of suburbslist" value="{{ item.id }}">{{ item.suburbname }}
                            </option>
                          </select>
                                                    <div *ngIf="submitted && f.suburbid.errors" class="invalid-feedback">
                                                        <div *ngIf="f.suburbid.errors.required">Suburb is required</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="form-group position-relative">
                                                    <label>Post code<span class="text-danger">*</span></label>
                                                    <input type="text" class="form-control" placeholder="Post code" formControlName="postcode" [ngClass]="{ 'is-invalid': submitted && f.postcode.errors }">
                                                    <div *ngIf="submitted && f.postcode.errors" class="invalid-feedback">
                                                        <div *ngIf="f.postcode.errors.required">Post code is required</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <!-- <div class="col-md-6">
                        <div class="form-group position-relative">
                          <label>Buyer Type<span class="text-danger">*</span></label>
                          <select formControlName="retai_whole" class="form-control custom-select"
                            [ngClass]="{ 'is-invalid': submitted && f.retai_whole.errors }">
                            <option value="Retailars">Retailars</option>
                            <option value="Wholesaller">Wholesaller</option>
                            
                          </select>
                          <div *ngIf="submitted && f.retai_whole.errors" class="invalid-feedback">
                            <div *ngIf="f.retai_whole.errors.required">Suburb is required</div>
                          </div>
                        </div>
                      </div> -->
                                            <div class="col-md-6">
                                                <div class="form-group position-relative">
                                                    <label>Email <span class="text-danger">*</span></label>
                                                    <i-feather name="mail" class="fea icon-sm icons"></i-feather>
                                                    <input type="email" class="form-control pl-5" placeholder="Email" formControlName="email" [ngClass]="{ 'is-invalid': submitted && f.email.errors }">
                                                    <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                                                        <div *ngIf="f.email.errors.required">Email is required</div>
                                                        <div *ngIf="f.email.errors.email">Email must be a valid email address</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="form-group position-relative">
                                                    <label>Mobile Number<span class="text-danger">*</span></label>
                                                    <input type="text" class="form-control" placeholder="Mobile Number" formControlName="phone" [ngClass]="{ 'is-invalid': submitted && f.phone.errors }">
                                                    <div *ngIf="submitted && f.phone.errors" class="invalid-feedback">
                                                        <div *ngIf="f.phone.errors.required">Post code is required</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <!-- <div class="col-md-6">
                        <div class="form-group position-relative">
                          <label>Password <span class="text-danger">*</span></label>
                          <i-feather name="key" class="fea icon-sm icons"></i-feather>
                          <input type="password" formControlName="password" class="form-control pl-5"
                            placeholder="Password" [ngClass]="{ 'is-invalid': submitted && f.password.errors }">
                          <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                            <div *ngIf="f.password.errors.required">Password is required</div>
                            <div *ngIf="f.password.errors.minlength">Password must be at least 6 characters</div>
                          </div>
                        </div>
                      </div> -->
                                            <!--end col-->

                                        </div>
                                        <!--end row-->

                                        <!--end row-->

                                        <!--end form-->
                                    </form>
                                    <button (click)='tabChange(1)' style="float: right;" class="btn btn-secondary mt-5">
                    <span>Next</span>
                    <!-- <div class="spinner-border" *ngIf="isSpinner" role="status">
                      <span class="sr-only">Loading...</span>
                    </div> -->
                  </button>
                                </ng-template>

                            </li>

                            <li [ngbNavItem]="1" [id]="0" [destroyOnHide]="false" [disabled]="twoli">
                                <a ngbNavLink class="rounded">
                                    <div class="text-center pt-1 pb-1">
                                        <h4 class="title font-weight-normal mb-0">Product</h4>
                                    </div>
                                </a>
                                <ng-template ngbNavContent>
                                    <div class="row productinfo" *ngIf="!iscrop">
                                        <div class="col-lg-8 col-sm-6 p-3">
                                            Add Product information
                                        </div>
                                        <div class="col-lg-4 col-sm-6 p-3 text-right">
                                            <button (click)='addcrop(iscrop)' class="btn btn-primary ">
                        <span>Add Product</span>
                      </button>
                                        </div>
                                    </div>

                                    <div *ngIf="iscrop" class="list-group">
                                        <form [formGroup]="productForm">
                                            <div class="row mt-4">
                                                <input type="hidden" class="form-control" formControlName="id">
                                                <div class="col-md-6">
                                                    <div class="form-group position-relative">
                                                        <label>Category<span class="text-danger">*</span></label>
                                                        <select formControlName="categoryid" class="form-control custom-select" [ngClass]="{ 'is-invalid': submitted && p.categoryid.errors }">
                              <option value="">Select</option>
                              <option *ngFor="let item of categorylist" value="{{ item.id }}">{{ item.name }}
                              </option>
                            </select>
                                                        <div *ngIf="submitted && p.categoryid.errors" class="invalid-feedback">
                                                            <div *ngIf="p.categoryid.errors.required">Category is required</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-6">
                                                    <div class="form-group position-relative">
                                                        <label>Product Name</label>

                                                        <input type="text" class="form-control" placeholder="Product.Name" formControlName="name" [ngClass]="{ 'is-invalid': submitted && p.name.errors }">
                                                        <div *ngIf="submitted && p.name.errors" class="invalid-feedback">
                                                            <div *ngIf="p.name.errors.required">Product Name is required</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-6">
                                                    <div class="form-group position-relative">
                                                        <label>Description</label>

                                                        <input type="text" class="form-control" placeholder="Description" formControlName="description">
                                                    </div>
                                                </div>
                                                <div class="col-md-6">
                                                    <div class="form-group position-relative">
                                                        <label>Price</label>
                                                        <input type="number" class="form-control" placeholder="Price" formControlName="price" [ngClass]="{ 'is-invalid': submitted && p.price.errors }">
                                                        <div *ngIf="submitted && p.price.errors" class="invalid-feedback">
                                                            <div *ngIf="p.price.errors.required">Price is required</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-6">
                                                    <div class="form-group position-relative">
                                                        <label>Picture</label>
                                                        <input type="file" (change)="productimages($event)" class="form-control" placeholder="Picture" [ngClass]="{ 'is-invalid': submitted && p.imgurl.errors }">
                                                        <div *ngIf="submitted && p.imgurl.errors" class="invalid-feedback">
                                                            <div *ngIf="p.imgurl.errors.required">Picture is required</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-6">
                                                    <div class="form-group position-relative">
                                                        <label>Discount</label>
                                                        <input type="number" class="form-control" placeholder="Discount" formControlName="discount" [ngClass]="{ 'is-invalid': submitted && p.discount.errors }">
                                                        <div *ngIf="submitted && p.discount.errors" class="invalid-feedback">
                                                            <div *ngIf="p.discount.errors.required">Discount is required</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-6">
                                                    <div class="form-group position-relative">
                                                        <label>Promotional offer</label>
                                                        <input type="text" class="form-control" placeholder="Promotional offer" formControlName="promotional" [ngClass]="{ 'is-invalid': submitted && p.promotional.errors }">
                                                        <div *ngIf="submitted && p.promotional.errors" class="invalid-feedback">
                                                            <div *ngIf="p.promotional.errors.required">Offer is required</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-6">
                                                    <div class="form-group position-relative">
                                                        <label>Wholesale Price</label>
                                                        <input type="number" class="form-control" placeholder="Wholesale Price" formControlName="wholesaleprice" [ngClass]="{ 'is-invalid': submitted && p.wholesaleprice.errors }">
                                                        <div *ngIf="submitted && p.wholesaleprice.errors" class="invalid-feedback">
                                                            <div *ngIf="p.wholesaleprice.errors.required">Wholesale price is required</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-6">
                                                    <div class="form-group position-relative">
                                                        <label>Retail Price</label>
                                                        <input type="number" class="form-control" placeholder="Retail Price" formControlName="retailprice" [ngClass]="{ 'is-invalid': submitted && p.retailprice.errors }">
                                                        <div *ngIf="submitted && p.retailprice.errors" class="invalid-feedback">
                                                            <div *ngIf="p.retailprice.errors.required">Retail price is required</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>

                                    </div>

                                    <div class="row" style="overflow: auto;" *ngIf="!iscrop">
                                        <div class="col-md-12">
                                            <table class="table">
                                                <thead class="thead-light">
                                                    <tr>
                                                        <th scope="col">Name</th>
                                                        <th scope="col">Price</th>
                                                        <th scope="col">Discount</th>
                                                        <th scope="col">Offer</th>
                                                        <th scope="col">Wholesale price</th>
                                                        <th scope="col">Retail price</th>
                                                        <th scope="col">Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let item of productlist">
                                                        <th scope="row">{{ item.name }}</th>
                                                        <td>{{ item.price }}</td>
                                                        <td>{{ item.discount }}</td>
                                                        <td>{{ item.promotional }}</td>
                                                        <td>{{ item.wholesaleprice }}</td>
                                                        <td>{{ item.retailprice }}</td>
                                                        <td style="min-width: 160px;"> <button (click)='editproduct(item.id)' class="btn btn-primary btn-sm">
                              <span>Edit</span>
                            </button>
                                                            <button (click)='openmodeldelete(content,item.id)' class="btn btn-danger btn-sm ml-2">
                              <span>Delete</span>
                            </button>
                                                        </td>
                                                    </tr>

                                                </tbody>
                                            </table>
                                        </div>
                                    </div>

                                    <div *ngIf="iscrop">
                                        <button (click)='addcrop(iscrop)' style="float: right;" class="btn btn-primary mt-5">
                      <span>Done</span>
                    </button>
                                        <button (click)='submitproduct()' style="float: right;" class="btn btn-info mt-5 mr-2">
                      <span>Submit</span>
                    </button>
                                    </div>
                                    <div *ngIf="!iscrop">
                                        <button (click)='tabChange(2)' style="float: right;" class="btn btn-secondary mt-5">
                      <span>Next</span>
                    </button>
                                        <button (click)='tabChange(0)' style="float: right;" class="btn btn-info mt-5 mr-2">
                      <span>Previous</span>
                    </button>
                                    </div>


                                </ng-template>
                            </li>
                            <li [ngbNavItem]="2" [destroyOnHide]="false" [disabled]="threeli">
                                <a ngbNavLink class=" rounded">
                                    <div class="text-center pt-1 pb-1">
                                        <h4 class="title font-weight-normal mb-0">Interstate</h4>
                                    </div>
                                </a>
                                <ng-template ngbNavContent>
                                    <div class="mt-4 profiletablecheckbox">
                                        <div class="row">
                                            <div class="col-lg-4">
                                                <label style="font-size: 14px; font-weight: 700;">Are you a wholesaler?</label>
                                            </div>
                                            <div class="col-lg-8">
                                                <div class="check">
                                                    <label class="mr-3">
                            <!-- <button [ngClass]="sell_interstate==1 ? 'btn-primary' : 'btn-light'" (click)="modelnoopen(1)" class="btn btn-sm"> Local </button> -->
                            <input type="radio"  [(ngModel)]="iswholesaler"
                              name="iswholesaler" [value]="1" />
                            Yes
                          </label>
                                                    <label class="">
                            <!-- <button (click)="modelnoopen(0)"
                              [ngClass]="sell_interstate==0 ? 'btn-primary' : 'btn-light'" class="btn btn-sm"> National
                            </button> -->
                            <input type="radio" [(ngModel)]="iswholesaler" name="iswholesaler" [value]="0" />
                            No
                          </label>
                                                </div>
                                            </div>
                                        </div>
                                        <hr />
                                        <div class="row">
                                            <div class="col-lg-4">
                                                <label style="font-size: 14px; font-weight: 700;">Your presence is:</label>
                                            </div>
                                            <div class="col-lg-8">
                                                <div class="row check">
                                                    <label class="col-lg-4">
                            <input type="radio" [checked]="true"  [(ngModel)]="ispresence"
                              name="ispresence" [value]="1" />
                              Local 
                          </label>
                                                    <label class="col-lg-4">
                            <input type="radio" [checked]="ispresence==0" [(ngModel)]="ispresence" name="ispresence" [value]="0" />
                            National
                          </label>
                                                </div>
                                            </div>
                                        </div>




                                    </div>
                                    <!-- <button (click)="saveimg()">Save</button> -->
                                    <button (click)='onSubmit()' style="float: right;" class="btn btn-primary mt-5">
                    <span>Submit</span>
                    <div class="spinner-border" *ngIf="isSpinner" role="status">
                      <span class="sr-only">Loading...</span>
                    </div>
                  </button>
                                    <button (click)='tabChange(1)' style="float: right;" class="btn btn-info mt-5 mr-2">
                    <span>Previous</span>
                    <!-- <div class="spinner-border" *ngIf="isSpinner" role="status">
                      <span class="sr-only">Loading...</span>
                    </div> -->
                  </button>
                                </ng-template>
                            </li>




                        </ul>
                        <div [ngbNavOutlet]="nav" class="pt-2"></div>


                    </div>

                    <div class="card-body" *ngIf="!isEdit">
                        <h5 class="mt-4">Personal Details :</h5>
                        <div class="row">
                            <div class="col-lg-4 mt-3">
                                <div class="media align-items-center rounded shadow p-3">
                                    <i-feather name="mail" class="fea icon-ex-md text-muted mr-3"></i-feather>
                                    <div class="media-body text-truncate">
                                        <h6 class="text-primary mb-0">Email :</h6>
                                        <a href="javascript:void(0)" class="text-muted">{{ profiledetails.email }}</a>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 mt-3">
                                <div class="media align-items-center rounded shadow p-3">
                                    <i-feather name="users" class="fea icon-ex-md text-muted mr-3"></i-feather>
                                    <div class="media-body">
                                        <h6 class="text-primary mb-0">Name :</h6>
                                        <a href="javascript:void(0)" class="text-muted">{{ profiledetails.name }}</a>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 mt-3">
                                <div class="media align-items-center rounded shadow p-3">
                                    <i-feather name="home" class="fea icon-ex-md text-muted mr-3"></i-feather>
                                    <div class="media-body">
                                        <h6 class="text-primary mb-0">Company Name :</h6>
                                        <a href="javascript:void(0)" class="text-muted">{{ profiledetails.companyname }}</a>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 mt-3">
                                <div class="media align-items-center rounded shadow p-3">
                                    <i-feather name="map" class="fea icon-ex-md text-muted mr-3"></i-feather>
                                    <div class="media-body">
                                        <h6 class="text-primary mb-0">State :</h6>
                                        <a href="javascript:void(0)" class="text-muted">{{ profiledetails.statename }}</a>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 mt-3">
                                <div class="media align-items-center rounded shadow p-3">
                                    <i-feather name="map-pin" class="fea icon-ex-md text-muted mr-3"></i-feather>
                                    <div class="media-body">
                                        <h6 class="text-primary mb-0">City :</h6>
                                        <a href="javascript:void(0)" class="text-muted">{{ profiledetails.cityname }}</a>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 mt-3">
                                <div class="media align-items-center rounded shadow p-3">
                                    <i-feather name="map-pin" class="fea icon-ex-md text-muted mr-3"></i-feather>
                                    <div class="media-body">
                                        <h6 class="text-primary mb-0">Suburb :</h6>
                                        <a href="javascript:void(0)" class="text-muted">{{ profiledetails.suburbname }}</a>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 mt-3">
                                <div class="media align-items-center rounded shadow p-3">
                                    <i-feather name="map-pin" class="fea icon-ex-md text-muted mr-3"></i-feather>
                                    <div class="media-body">
                                        <h6 class="text-primary mb-0">Post code :</h6>
                                        <a href="javascript:void(0)" class="text-muted">{{ profiledetails.postcode }}</a>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 mt-3">
                                <div class="media align-items-center rounded shadow p-3">
                                    <i-feather name="phone" class="fea icon-ex-md text-muted mr-3"></i-feather>
                                    <div class="media-body">
                                        <h6 class="text-primary mb-0">Mobile Number :</h6>
                                        <a href="javascript:void(0)" class="text-muted">{{ profiledetails.phone }}</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- <div class="row">
              <div class="col-md-6 mt-4 pt-2 pt-sm-0">
                <h5>Experience :</h5>
                <div class="media key-feature align-items-center p-3 rounded shadow mt-4">
                  <img src="assets/images/job/Circleci.svg" class="avatar avatar-ex-sm" alt="">
                  <div class="media-body content ml-3">
                    <h4 class="title mb-0">Senior Web Developer</h4>
                    <p class="text-muted mb-0">3 Years Experience</p>
                    <p class="text-muted mb-0"><a href="javascript:void(0)" class="text-primary">CircleCi</a> @London,
                      UK
                    </p>
                  </div>
                </div>

                <div class="media key-feature align-items-center p-3 rounded shadow mt-4">
                  <img src="assets/images/job/Codepen.svg" class="avatar avatar-ex-sm" alt="">
                  <div class="media-body content ml-3">
                    <h4 class="title mb-0">Web Designer</h4>
                    <p class="text-muted mb-0">2 Years Experience</p>
                    <p class="text-muted mb-0"><a href="javascript:void(0)" class="text-primary">Codepen</a> @Washington
                      D.C, USA</p>
                  </div>
                </div>

                <div class="media key-feature align-items-center p-3 rounded shadow mt-4">
                  <img src="assets/images/job/Gitlab.svg" class="avatar avatar-ex-sm" alt="">
                  <div class="media-body content ml-3">
                    <h4 class="title mb-0">UI Designer</h4>
                    <p class="text-muted mb-0">2 Years Experience</p>
                    <p class="text-muted mb-0"><a href="javascript:void(0)" class="text-primary">Gitlab</a> @Perth,
                      Australia</p>
                  </div>
                </div>
              </div>
            </div> -->
                    </div>
                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row-->
    </div>
    <!--end container-->
</section>
<!--end section-->
<!-- Profile Setting End -->



<!-- ModalBreed Content Start -->

<!-- Modal Content End -->
<!-- Modal Content Start -->
<ng-template #content let-modal>
    <div class="modal-header">
        <h5 class="modal-title" id="modal-basic-title">Delete</h5>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
    </div>
    <div class="modal-body">
        <div class="bg-white rounded box-shadow">
            <p class="text-muted mb-0">Are you sure you want to delete? </p>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">Cancel</button>
        <button type="button" class="btn btn-outline-danger" (click)="deleteval()">Delete</button>

    </div>
</ng-template>
<!-- Modal Content End -->
<!-- Back to top -->
<a href="javascript: void(0);" class="btn btn-icon btn-soft-primary back-to-top" id="back-to-top" [ngxScrollTo]="'#home'">
    <i-feather name="arrow-up" class="icons"></i-feather>
</a>
<!-- Back to top -->
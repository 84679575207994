import { Injectable } from '@angular/core';
import {
    Router,
    CanActivate,
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
} from '@angular/router';
import { StorageService } from './storage.service';
@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
    constructor(public storageService: StorageService, private _router: Router) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const currentUser = localStorage.getItem('token');
        
        if (currentUser) {
            // logged in so return true
            return true;
        } else {
            this._router.navigateByUrl('/');
            this.storageService.clear();
            return false;
        }

        // not logged in so redirect to login page with the return url

    }
}
import { StorageService } from './../../service/storage.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/service/auth.service';
import { ToastrService } from 'ngx-toastr';
import { PostService } from 'src/app/service/post.service';
//Wizard

@Component({
  selector: 'app-fullregistration',
  templateUrl: './fullregistration.component.html',
  styleUrls: ['./fullregistration.component.scss']
})
export class FullregistrationComponent implements OnInit {
  navClass = 'nav-light';
  id: any;
  searchbycroptext: string;
  searchbybreedtext: string;
  registergrowerForm: FormGroup;
  submitted = false;
  isSpinner = false;
  isEdit = false;
  subamount = 0;
  urls = [];
  subscriptionlist = [];
  name: any;
  isadmin: any;
  isgrower: any;
  isbuyer: any;
  isvendor: any;
  certificatearray: any = [];
  growing_environment: any = []
  growing_medium: any = []
  availability_produce: any = []
  statelist: any = [];
  suburbslist: any = [];
  userid: any;
  cropinfo: any = [];
  tmpcropdata: any = [];
  growercertificate: any = [];
  tmppackingarray: any = []
  packingarray: any = [{ id: 1, name: 'Box' }, { id: 2, name: 'Loose' }, { id: 3, name: 'Punnet' }]
  //Filed
  growing_environment_id: any;
  growing_medium_id: any;
  availability_produce_id: any;
  sell_interstate: any;
  storage: any;

  imageurl: ['']
  subscriptioid: number;
  striptoken: any;
  isDatarequired = false;
  errormsg: string;
  active = 0;
  disabled = false;
  FileuploadformData: FormData;
  constructor(private formBuilder: FormBuilder, private _authService: AuthService, private _router: Router, private toastr: ToastrService, public activatedRoute: ActivatedRoute, public storageService: StorageService, public postservice: PostService) {
    // var urlval = this.activatedRoute.snapshot.paramMap.get('id');
    // var decodeval = atob(urlval);
    // this.id = decodeval;
    //alert(this.id)
  }


  ngOnInit(): void {
    this.registergrowerForm = this.formBuilder.group({
      name: ['', Validators.required],
      companyname: [''],
      stateid: ['', Validators.required],
      suburbid: ['', Validators.required],
      postcode: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.minLength(6)]],
      
    });
    this.getcropinfo();
    this.getstate();
    this.getcertificate();
    this.getgrowing_environment();
    this.getgrowing_medium();
    this.getavaiproduce();
    this.getsubscription();
    this.storageService.get('logininfo').then(
      (res) => {
        console.log(res);
        this.userid = res.id;
        if (res.id) {

          this.getuserdata();
          this.name = res.username;
          this.isadmin = res.admin == 1 ? 'Admin' : '';
          this.isgrower = res.grower == 1 ? 'Grower' : '';
          this.isbuyer = res.buyer == 1 ? 'Buyer' : '';
          this.isvendor = res.vendor == 1 ? 'Vender' : '';

        }
      }
    )

  }
  get f() { return this.registergrowerForm.controls; }

  isedit(val) {
    if (val == true) {
      this.isEdit = false;
    } else {
      this.isEdit = true;
    }

  }
  getsubc(event, d) {

    const newVal = event.target.value;
    const adddata = this.subscriptionlist[Number(newVal)];
    this.subscriptioid = adddata.id
    this.subamount = adddata.amount;
  }
  getuserdata() {
    let data = {
      userid: this.userid
    }
    this._authService.getuserdata(data)
      .subscribe((data: any) => {
        console.log(data);

        this.setFormvalue(data)

      });
  }
  setFormvalue(data) {
    this.getsuburbsselected(data.stateid);
    this.registergrowerForm.patchValue({
      name: data.name,
      companyname: data.companyname,
      email: data.email,
      postcode: data.postcode,
      stateid: data.stateid,
      suburbid: data.suburbid


    });
  }

  getstate() {
    this._authService.getstate()
      .subscribe((data: any) => {
        console.log(data);
        this.statelist = data.success;
      });

  }
  getsuburbsselected(event) {

    const newVal = event;
    let data = {
      state_id: newVal
    }
    this._authService.getsuburbs(data)
      .subscribe((data: any) => {
        console.log(data);
        this.suburbslist = data.success;
      });
  }
  getsuburbs(event) {
    const newVal = event.target.value;
    let data = {
      state_id: newVal
    }
    this._authService.getsuburbs(data)
      .subscribe((data: any) => {
        console.log(data);
        this.suburbslist = data.success;
      });
  }
  getsubscription() {

    this.postservice.getsubscription()
      .subscribe((data: any) => {
        console.log(data);
        this.subscriptionlist = data.success;
      });
  }
  getcertificate() {

    this.postservice.getcertificate()
      .subscribe((data: any) => {
        console.log(data);
        this.certificatearray = data.success;
      });
  }
  getgrowing_environment() {

    this.postservice.getgrowing_environment()
      .subscribe((data: any) => {
        console.log(data);
        this.growing_environment = data.success;
      });
  }
  getgrowing_medium() {

    this.postservice.getgrowing_medium()
      .subscribe((data: any) => {
        console.log(data);
        this.growing_medium = data.success;
      });
  }

  getavaiproduce() {
    this.postservice.getavaiproduce()
      .subscribe((data: any) => {
        console.log(data);
        this.availability_produce = data.success;
      });
  }
  getcropinfo() {
    //let tmpcropdata = [];
    this.postservice.getcropinfo("")
      .subscribe((data: any) => {
        console.log(data);
        this.cropinfo = data.success;
        for (let i = 0; i < this.cropinfo.length; i++) {
          const element = this.cropinfo[i];
          const statu = this.cropinfo[i].statu;
          if (statu == 0) {
            let data = {
              id: this.cropinfo[i].id,
              cropname: this.cropinfo[i].cropname,
              breedname: this.cropinfo[i].breedname,
              statu: this.cropinfo[i].statu,
              count: 0
            }
            this.tmpcropdata.push(data)
          }
        }
        if (this.tmpcropdata.length > 0) {
          for (let j = 0; j < this.tmpcropdata.length; j++) {
            const elementj = this.tmpcropdata[j];
            let count = 0;

            for (let z = 0; z < this.cropinfo.length; z++) {
              const elementz = this.cropinfo[z];
              if (elementj.id == elementz.id) {
                count++;
              }
            }
            elementj.count = count - 1;
          }
        }
        console.log(this.tmpcropdata);
        debugger
      });
  }
  onSelectFile(event, id) {
    if (event.target.files && event.target.files[0]) {
      var filesAmount = event.target.files.length;
      for (let i = 0; i < filesAmount; i++) {
        var reader = new FileReader();

        reader.onload = (event: any) => {
          console.log(event.target.result);
          this.urls.push(event.target.result);
        }

        reader.readAsDataURL(event.target.files[i]);
      }
      console.log(this.urls)

    }
  }
  certid = [];
  upload(event, id) {
    if (event.target.files.length > 0) {
      this.FileuploadformData = new FormData();
      const file = event.target.files[0];
     
      this.certid.push(id);
      for (let j = 0; j < this.certid.length; j++) {
        const certid = this.certid[j];
        this.FileuploadformData.append("certificateid[" + i + "]", this.certid[i]);
      }
      this.growercertificate.push(file);
      this.FileuploadformData.append("growerid", this.userid)
      for (var i = 0; i < this.growercertificate.length; i++) {

        this.FileuploadformData.append("myfile[" + i + "]", this.growercertificate[i]);
      }

      console.log(this.FileuploadformData);
      debugger

    }
  }
  saveimg() {
    let data = {
      id: 1,
      imgurl: this.FileuploadformData
    }
    this.postservice.imgupload(this.FileuploadformData)
      .subscribe((data: any) => {
        console.log(data);
        debugger
      })
  }

  upload1(file: File, id) {
    console.log(id);
    console.log(file);
    let data = {
      growerid: this.userid,
      certificateid: id,
      imageurl: file
    }
    if (file) {
      // Pushing the object into array
      this.growercertificate.push(data);
    } else {
      let removeIndex = this.growercertificate.findIndex(itm => itm.certificateid === id);
      if (removeIndex !== -1)
        this.growercertificate.splice(removeIndex, 1);
    }
    console.log(this.growercertificate);

    let dataval = {
      growercertificate: this.growercertificate
    }

    this.postservice.imgupload(dataval)
      .subscribe((data: any) => {
        console.log(data);
        debugger
      })

    //pick from one of the 4 styles of file uploads below
  }
  reset(id) {

    var control = document.getElementById("mcontrol")


    let removeIndex = this.growercertificate.findIndex(itm => itm.certificateid === id);
    if (removeIndex !== -1)
      this.growercertificate.splice(removeIndex, 1);
    console.log(this.growercertificate);
    debugger
  }
  onSubmit() {

    let data = {
      growerid:this.userid,
      name: this.registergrowerForm.value.name,
      companyname: this.registergrowerForm.value.companyname,
      email: this.registergrowerForm.value.email,
      postcode: this.registergrowerForm.value.postcode,
      stateid: this.registergrowerForm.value.stateid,
      suburbid: this.registergrowerForm.value.suburbid,
      growing_environment_id: this.growing_environment_id,//v
      growing_medium_id: this.growing_medium_id,//v
      availability_produce_id: this.availability_produce_id,//v
      sell_interstate: this.sell_interstate,//v
      storage: this.storage,//v
      croparray: this.tmppackingarray,//v
      certificatearray: '',
      subscriptionid: this.subscriptioid,
      stripamount: Number(this.subamount) * 100,
      //striptoken: this.striptoken
    }
    
    this.postservice.growerregistration(data)
    .subscribe((data: any) => {
      console.log(data);
      debugger  
    })
    return false
    let msg1 = ''
    let msg2 = ''
    if (data.name && data.email && data.postcode && data.stateid && data.suburbid && data.growing_environment_id && data.growing_medium_id && data.availability_produce_id && data.sell_interstate && data.storage && this.tmppackingarray.length > 0 && data.subscriptionid && data.stripamount > 0) {
      alert("OK")
    } else {
      if (!data.name && !data.email && !data.postcode && !data.stateid && !data.suburbid && !data.growing_environment_id && !data.growing_medium_id && !data.availability_produce_id && !data.sell_interstate && !data.storage && this.tmppackingarray.length == 0 && !data.subscriptionid && data.stripamount == 0) {
        msg1 = 'All filed are required.'
      } else if (!data.name) {
        msg1 = 'Name is required.'
      } else if (!data.email) {
        msg1 = 'Name is required.'
      } else if (!data.postcode) {
        msg1 = 'Name is required.'
      } else if (!data.stateid) {
        msg1 = 'Name is required.'
      } else if (!data.suburbid) {
        msg1 = 'Name is required.'
      } else if (!data.growing_environment_id) {
        msg1 = 'Growing environment is required.'
      }
      this.isDatarequired = true
    }

    // if (this.tmppackingarray.length == 0 && this.growing_environment_id == undefined && this.growing_medium_id == undefined && this.availability_produce_id == undefined && this.sell_interstate == undefined && this.subscriptioid == undefined && this.subamount == 0) {
    //   msg1 = 'Crop information is required, Growing environment is required, Growing medium is required, Availability produce is required, Cold stotage is required';
    //   this.isDatarequired = true
    // } else {
    //   this.isDatarequired = false
    // }
    this.errormsg = "- " + msg1
    console.log(data);
    debugger
  }
  stripdata($event) {
    console.log($event);
    debugger
    this.striptoken = $event;

    // this.postservice.sendstriprequest(data)
    // .subscribe((data: any) => {
    //   console.log(data);
    //   debugger  
    // })
  }

  packingselecte(values: any, pkid, cropid, breedid) {


    if (values.currentTarget.checked == true) {
      let cropList =
      {
        cropid: cropid,
        breedid: Number(breedid) != 0 ? Number(breedid) : 0,
        box: pkid === 1 ? pkid : 0,
        loos: pkid === 2 ? pkid : 0,
        punnet: pkid === 3 ? pkid : 0,
      }
      this.tmppackingarray.push(cropList)

    } else {

      let removeIndex = this.tmppackingarray.findIndex(itm => Number(itm.breedid) != 0 ? Number(itm.breedid) : cropid === (Number(breedid) != 0) ? Number(breedid) : cropid);
      if (removeIndex !== -1)
        this.tmppackingarray.splice(removeIndex, 1);

    }
    console.log(this.tmppackingarray)
    debugger

  }
  tabChange(evn) {
    let nextId = evn.nextId
    let activeId = evn.activeId
    if (nextId === 'ngb-nav-1') {
      alert('dd')
    }
    debugger
  }
}
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Component, OnInit, PipeTransform } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/service/auth.service';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { PostService } from 'src/app/service/post.service';
import { StorageService } from 'src/app/service/storage.service';

@Component({
  selector: 'app-buyerlist',
  templateUrl: './buyerlist.component.html',
  styleUrls: ['./buyerlist.component.css']
})
export class BuyerlistComponent implements OnInit {
  navClass = 'nav-light';
  id: any;
  
  isSpinnerlist = true;
  //Table code
  page = 1;
  pageSize = 4;
  collectionSize = 0;
  masterlist: any = [];
  allmasterlist: any = [];
  //end
  //Other
  userlist: any = [];
  userid: any;
  deletid: any;
  constructor(private formBuilder: FormBuilder, private _authService: AuthService, private _router: Router, private toastr: ToastrService, public activatedRoute: ActivatedRoute, public postservice: PostService,public storageService: StorageService,private modalService: NgbModal) {

  }
  refreshCountries() {
    this.masterlist = this.allmasterlist
      .map((country, i) => ({ id: i + 1, ...country }))
      .slice((this.page - 1) * this.pageSize, (this.page - 1) * this.pageSize + this.pageSize);
  }

  ngOnInit(): void {
    this.storageService.get('logininfo').then(
      (res) => {
        console.log(res);
        this.userid = res.id;
        if (res.id) {
          this.userid = res.id;
          this.getlist();
        }
      })
  }
  //Delete Code
 open(content, id) {
  this.deletid = id;
  this.modalService.open(content, { centered: true });
}
deleteval() {
  this.modalService.dismissAll();

  this.postservice.userdelete(this.deletid)
  .subscribe((data: any) => {
    console.log(data);
    this.getlist();
    this.toastr.success('User', data.message);

  })
}
//End

 
  getlist() {
    let data = {
      userid: 2
    };
    this._authService.getuseralllist(data)
      .subscribe((data: any) => {
        console.log(data);
        this.isSpinnerlist = false;
        //this.certificatelist = data.success;
        this.collectionSize = data.success.length;
        this.allmasterlist = data.success;
        this.masterlist = data.success
          .map((country, i) => ({ id: i + 1, ...country }))
          .slice((this.page - 1) * this.pageSize, (this.page - 1) * this.pageSize + this.pageSize);
      });
  }


 
}
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StorageService {
  constructor() { }

  // Store the value
  async store(storageKey: string, value: any) {
    const encryptedValue = btoa(escape(JSON.stringify(value)));
    if(encryptedValue!='' && encryptedValue!= null){
    await localStorage.setItem(storageKey,encryptedValue)
    }
    
  }

  // Get the value
  async getlocalData(storageKey: string) {
    const ret = await localStorage.get(storageKey);
    if(ret.value!='' && ret.value!= null){
      return JSON.parse(unescape(atob(ret.value)));
    } else {
      return '';
    }
  }

  async get(storageKey: string) {
    const ret = await localStorage.getItem(storageKey);
    if(ret!='' && ret!= null){
      return JSON.parse(unescape(atob(ret)));
    }else{
      return '';
    }
   
  }

  async removeStorageItem(storageKey: string) {
    await localStorage.remove(storageKey);
  }

  async keys() {
    const  keys  = await localStorage.keys();
    return keys
  }
  

  // Clear storage
  async clear() {
    await localStorage.clear();
  }
}
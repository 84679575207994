import { Component, HostListener, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Platform } from '@angular/cdk/platform';

@Component({
  selector: 'app-index-services',
  templateUrl: './index-services.component.html',
  styleUrls: ['./index-services.component.css']
})
export class IndexServicesComponent implements OnInit {
  isAndroid: boolean = false;
  isIOS: boolean = false
  deferredPrompt: any;
  isFooter = '1';
  showButton = false;
  androidhtml: string;
  ioshtml: string;
  logo: string="assets/addtohome.png";
  constructor(private modalService: NgbModal, private platform: Platform, ) { }
  @HostListener('window:beforeinstallprompt', ['$event'])
  onbeforeinstallprompt(e) {
    console.log(e);
    // Prevent Chrome 67 and earlier from automatically showing the prompt
    e.preventDefault();
    // Stash the event so it can be triggered later.
    this.deferredPrompt = e;
    this.showButton = true;
    let displayMode = 'browser';
    const mqStandAlone = '(display-mode: standalone)';
  }


  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: false,
    pullDrag: false,
    navSpeed: 700,
    navText: ['', ''],
    responsive: {
      0: {
        items: 1
      },
      600: {
        items: 2
      },
      900: {
        items: 3
      }
    },
    nav: false
  };
  /**
    * Open modal for show the video
    * @param content content of modal
    */
  openWindowCustomClass(content) {
  this.modalService.open(content, { windowClass: 'dark-modal', size: 'lg', centered: true });
  }
  ngOnInit(): void {

      this.androidhtml = '<img class="mr-1" alt="logo" width="24" height="24" src="' + this.logo + '" >Add Independent\'s Market to Home screen';
      this.ioshtml = '<img class="mr-1" alt="logo" width="24" height="24" src="' + this.logo + '" >To install this web app on your device tap the Menu button and then "Add to Home screen" button';

      this.initPwaPrompt();
      var isFooter = localStorage.getItem('footer');
      if (window.matchMedia('(display-mode: standalone)').matches) {
        this.isFooter = '0';
      }
      else {
        this.isFooter = '1';
      }
    
  }
  public initPwaPrompt() {
    if (this.platform.ANDROID || this.platform.isBrowser || this.platform.FIREFOX || this.platform.EDGE || this.platform.BLINK) {
      this.isAndroid = true;
      this.isIOS = false
    }
    if (this.platform.IOS || this.platform.SAFARI) {
      this.isAndroid = false;
      this.isIOS = true;
    }
  }
  addToHomeScreen() {
    // hide our user interface that shows our A2HS button
    this.showButton = false;
    // Show the prompt
    this.deferredPrompt.prompt();
    // Wait for the user to respond to the prompt
    this.deferredPrompt.userChoice
      .then((choiceResult) => {
        if (choiceResult.outcome === 'accepted') {
          console.log('User accepted the prompt');
          this.close();
        } else {
          console.log('User dismissed the prompt');
        }
        this.deferredPrompt = null;

      });
  }
  close() {
    this.isFooter = '0';
    localStorage.setItem('footer', '0');
  }

}

import { StorageService } from './../../service/storage.service';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/service/auth.service';
import { ToastrService } from 'ngx-toastr';
import { PostService } from 'src/app/service/post.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Location } from '@angular/common'

//Wizard

@Component({
  selector: 'app-buyerregistration',
  templateUrl: './buyerregistration.component.html',
  styleUrls: ['./buyerregistration.component.scss']
})
export class BuyerregistrationComponent implements OnInit {
  navClass = 'nav-light';
  id: any;
  profiledetails = {
    name: "",
    companyname: "",
    email: "",
    phone: "",
    postcode: "",
    statename: "",
    cityname: "",
    suburbname: "",
    retai_whole: ""
  }
  searchbycroptext: string;
  searchbybreedtext: string;
  registergrowerForm: FormGroup;
  submitted = false;
  isSpinner = false;
  isEdit = false;
  subamount = 0;
  urls = [];
  subscriptionlist = [];
  name: any;
  isadmin: any;
  isgrower: any;
  isbuyer: any;
  isvendor: any;
  certificatearray: any = [];
  growing_environment: any = [];
  growing_medium: any = [];
  availability_produce: any = [];
  statelist: any = [];
  suburbslist: any = [];
  citylist: any = [];
  userid: any;
  cropinfo: any = [];
  tmpcropdata: any = [];
  growercertificate: any = [];
  tmppackingarray: any = [];
  packingarray: any = [{ id: 1, name: 'Box' }, { id: 2, name: 'Loose' }, { id: 3, name: 'Punnet' }];
  //Filed
  growing_environment_id: any;
  growing_medium_id: any;
  availability_produce_id: any;
  sell_interstate: any;
  storage: any;

  imageurl: [''];
  subscriptioid: number;
  striptoken: any;
  isDatarequired = false;
  errormsg: string;
  active = 0;
  disabled = false;
  FileuploadformData: FormData;
  oneli = false;
  twoli = true;
  threeli = true;
  forli = true;
  croplist: any = [];
  breedlist: any = [];
  stateselectedarray: any = [];
  isbreedSpinner = true;
  cropid: any;
  breedcount: number = 0;
  iscrop = false;
  isCropSpinner = true;
  issavecropSpinner: boolean = false;
  userpic = "assets/images/user/user.png";
  userSpinner = false;
  productlist: any = [];
  usertype: string;
  messagetxt: string
  stateid: string;
  isSubscribe: boolean = false;
  constructor(private formBuilder: FormBuilder, private _authService: AuthService, private _router: Router, private toastr: ToastrService, public activatedRoute: ActivatedRoute, public storageService: StorageService, public postservice: PostService, private modalService: NgbModal, private cdr: ChangeDetectorRef, private location: Location) {
    var urlval = this.activatedRoute.snapshot.paramMap.get('id');
    this.usertype = this.activatedRoute.snapshot.paramMap.get('type');
    this.stateid = this.activatedRoute.snapshot.paramMap.get('stateid');
    if (this.usertype == null) {
      this.usertype = '1'
    }
    this.id = urlval
    // var decodeval = atob(urlval);
    // this.id = decodeval;


    // this.activatedRoute.paramMap.subscribe( params => {
    //   this.id = params.get('id');    
    // });
    // alert(this.id)

  }


  ngOnInit(): void {
    this.registergrowerForm = this.formBuilder.group({
      name: ['', Validators.required],
      companyname: [''],
      stateid: ['', Validators.required],
      cityid: ['', Validators.required],
      suburbid: ['', Validators.required],
      postcode: ['', Validators.required],
      phone: ['', Validators.required],
      retai_whole: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      //password: ['', [Validators.required, Validators.minLength(6)]],

    });

    this.getcroplist();

    this.storageService.get('logininfo').then(
      (res) => {
        console.log(res);
        this.userid = res.id;
        if (res.id) {
          this.getsubscriptionbyuser();
          this.getuserdata();
          this.getstate();
          this.growers_profile();
          this.getcropinfos();
          this.getcertificate();
          this.getproduct();
          // this.name = res.username;
          // this.isadmin = res.admin == 1 ? 'Admin' : '';
          // this.isgrower = res.grower == 1 ? 'Grower' : '';
          // this.isbuyer = res.buyer == 1 ? 'Buyer' : '';
          // this.isvendor = res.vendor == 1 ? 'Vender' : '';

        }
      }
    );

  }
  get f() { return this.registergrowerForm.controls; }

  isback(): void {
    //usertype
    this._router.navigateByUrl('/search/' + this.usertype + '/' + this.stateid);

    //  this.location.back()
  }
  isedit(val) {
    if (val == true) {
      this.isEdit = false;
    } else {
      this.isEdit = true;
    }

  }

  contact(contactGrower) {
    this.modalService.open(contactGrower, { centered: true });

  }
  gosubscribe(){
    this._router.navigateByUrl('/growersubscription');
  }
  
  getsubc(event, d) {

    const newVal = event.target.value;
    const adddata = this.subscriptionlist[Number(newVal)];
    this.subscriptioid = adddata.id;
    this.subamount = adddata.amount;
  }
  getsubscriptionbyuser() {
    let data = {
      id: this.userid
    };
    this.userSpinner = true
    this._authService.getsubscriptionbyuser(data)
      .subscribe((data: any) => {
        console.log(data.success);
        debugger
        if(data.success.length>0){
          this.isSubscribe = true;
        }
        
        
      });
  }
  getuserdata() {
    let data = {
      userid: this.id ? this.id : this.userid
    };
    this.userSpinner = true
    this._authService.getuserdata(data)
      .subscribe((data: any) => {
        console.log(data);

        this.userSpinner = false;

        this.setFormvalue(data);

      });
  }
  setFormvalue(data) {
    this.profiledetails = data
    this.userpic = data.image ? data.image : 'assets/images/user/user.png';
    this.getcity(data.stateid, 1);
    this.getsuburbs(data.stateid, 1);
    this.name = data.name;
    this.isadmin = data.admin == 1 ? 'Admin' : '';
    this.isgrower = data.grower == 1 ? 'Grower' : '';
    this.isbuyer = data.buyer == 1 ? 'Buyer' : '';
    this.isvendor = data.vendor == 1 ? 'Vender' : '';
    this.registergrowerForm.patchValue({
      name: data.name,
      companyname: data.companyname,
      email: data.email,
      postcode: data.postcode,
      phone: data.phone,
      stateid: data.stateid,
      cityid: data.cityid,
      suburbid: data.suburbid,
      retai_whole: data.retai_whole


    });
  }
  getcroplist() {
    this.postservice.getcrop()
      .subscribe((data: any) => {
        console.log(data);
        this.isCropSpinner = false;
        this.croplist = data.success;
        this.cdr.detectChanges();
      });
  }

  getstate() {
    let data = {
      id: this.userid
    }
    this.postservice.getsateselected(data)
      .subscribe((data: any) => {
        console.log(data);
        this.statelist = data.success;
      });
  }

  getcity(event, type) {
    let newVal1
    if (type == 0) {
      newVal1 = event.target.value;
    } else {
      newVal1 = event;
    }

    const newVal = event;
    let data = {
      state_id: newVal1
    };
    this._authService.getcity(data)
      .subscribe((data: any) => {
        console.log(data);

        this.citylist = data.success;
      });
  }



  getsuburbs(event, type) {
    let newVal
    if (type == 0) {
      newVal = event.target.value;
    } else {
      newVal = event;
    }

    let data = {
      state_id: newVal
    };
    this._authService.getsuburbs(data)
      .subscribe((data: any) => {
        console.log(data);
        debugger
        this.suburbslist = data.success;
      });
  }

  getcertificate() {
    let data = {
      id: this.id ? this.id : this.userid
    }
    //getcertificate_byid
    if (this.id) {
      this.postservice.getcertificate_byid(data)
        .subscribe((data: any) => {
          console.log(data);

          this.certificatearray = data.success;
        });

    } else {
      this.postservice.getcertificate_buyerid(data)
        .subscribe((data: any) => {
          console.log(data);

          this.certificatearray = data.success;
        });

    }

  }


  growers_profile() {
    let data = {
      id: this.userid
    }
    this.postservice.growers_profile(data)
      .subscribe((data: any) => {
        console.log(data);
        const storage = data.success.storage;
        const sell_interstate = data.success.sell_interstate;
        if (storage != 0) {
          this.storage = storage
        }
        this.sell_interstate = sell_interstate



      });
  }
  getcropinfos() {
    //let tmpcropdata = [];
    this.tmpcropdata = [];
    let data = {
      id: this.id ? this.id : this.userid

    }
    this.postservice.getcropinfo(data)
      .subscribe((data: any) => {
        console.log(data);
        this.cropinfo = data.success;
        for (let i = 0; i < this.cropinfo.length; i++) {
          const element = this.cropinfo[i];
          const statu = this.cropinfo[i].statu;
          if (statu == 0) {
            let data = {
              id: this.cropinfo[i].id,
              cropname: this.cropinfo[i].cropname,
              breedname: this.cropinfo[i].breedname,
              statu: this.cropinfo[i].statu,
              count: 0
            };
            this.tmpcropdata.push(data);
          }
        }
        if (this.tmpcropdata.length > 0) {
          for (let j = 0; j < this.tmpcropdata.length; j++) {
            const elementj = this.tmpcropdata[j];
            let count = 0;

            for (let z = 0; z < this.cropinfo.length; z++) {
              const elementz = this.cropinfo[z];
              if (elementj.id == elementz.id) {
                count++;
              }
            }
            elementj.count = count - 1;
          }
        }
        console.log(this.tmpcropdata);
        debugger;
      });
  }


  certiselect(event, id) {
    if (event.target.checked == true) {
      let data = {
        crtiid: id,
        userid: this.userid,
        chkvalue: 1
      };
      let removeIndex = this.growercertificate.findIndex(itm => itm.crtiid === id);
      if (removeIndex !== -1) {
        this.growercertificate.splice(removeIndex, 1);
      }
      this.growercertificate.push(data);
      console.log(this.growercertificate);
      debugger;
    } else {
      let data = {
        crtiid: id,
        userid: this.userid,
        chkvalue: 0
      };
      let removeIndex = this.growercertificate.findIndex(itm => itm.crtiid === id);
      if (removeIndex !== -1) {
        this.growercertificate.splice(removeIndex, 1);
      }
      this.growercertificate.push(data);

    }
    console.log(this.growercertificate);
    debugger
  }
  stateselect(event, id) {
    if (event.target.checked == true) {
      let data = {
        stateid: id,
        userid: this.userid,
        chkvalue: 1
      };
      let removeIndex = this.stateselectedarray.findIndex(itm => itm.stateid === id);
      if (removeIndex !== -1) {
        this.stateselectedarray.splice(removeIndex, 1);
      }
      this.stateselectedarray.push(data);
      console.log(this.stateselectedarray);
      debugger;
    } else {
      let data = {
        stateid: id,
        userid: this.userid,
        chkvalue: 0
      };
      let removeIndex = this.stateselectedarray.findIndex(itm => itm.stateid === id);
      if (removeIndex !== -1) {
        this.stateselectedarray.splice(removeIndex, 1);
      }
      this.stateselectedarray.push(data);

    }
    console.log(this.stateselectedarray);
    debugger
  }
  uploadprofile(event) {
    if (event.target.files.length > 0) {
      this.FileuploadformData = new FormData();
      const file = event.target.files[0];
      debugger
      this.FileuploadformData.append("growerid", this.userid);
      this.FileuploadformData.append("profilepic", file);
      this.postservice.profile_imgupload(this.FileuploadformData)
        .subscribe((data: any) => {
          console.log(data);
          debugger;
        });



    }
  }

  packingselect(values: any, breedid, pkid) {

    console.log(this.tmppackingarray);
    //debugger;
    if (values.currentTarget.checked == true) {
      if (this.tmppackingarray.length > 0) {
        let objIndex = this.tmppackingarray.findIndex(itm => Number(itm.breedid) == Number(breedid));
        if (objIndex !== -1) {
          //Log object to Console.
          console.log("Before update: ", this.tmppackingarray[objIndex])
          // debugger
          //Update object's name property.
          if (pkid == 1) {
            this.tmppackingarray[objIndex].box = 1
          }
          if (pkid == 2) {
            this.tmppackingarray[objIndex].loos = 1
          }
          if (pkid == 3) {
            this.tmppackingarray[objIndex].punnet = 1
          }


          //Log object to console again.
          console.log("After update: ", this.tmppackingarray[objIndex])
          //debugger


        } else {
          let cropList =
          {
            growerid: this.userid,
            cropid: this.cropid,
            breedid: Number(breedid) != 0 ? Number(breedid) : 0,
            box: pkid === 1 ? 1 : 0,
            loos: pkid === 2 ? 1 : 0,
            punnet: pkid === 3 ? 1 : 0,
          };
          debugger
          this.tmppackingarray.push(cropList);
        }
      } else {
        let cropList =
        {
          growerid: this.userid,
          cropid: this.cropid,
          breedid: Number(breedid) != 0 ? Number(breedid) : 0,
          box: pkid === 1 ? 1 : 0,
          loos: pkid === 2 ? 1 : 0,
          punnet: pkid === 3 ? 1 : 0,
        };
        debugger
        this.tmppackingarray.push(cropList);

      }
    } else {

      let removeIndex = this.tmppackingarray.findIndex(itm => Number(itm.breedid) == Number(breedid));
      if (removeIndex !== -1) {
        if ((this.tmppackingarray[removeIndex].box == 0 && this.tmppackingarray[removeIndex].loos == 0) ||
          (this.tmppackingarray[removeIndex].box == 0 && this.tmppackingarray[removeIndex].punnet == 0) ||
          (this.tmppackingarray[removeIndex].loos == 0 && this.tmppackingarray[removeIndex].punnet == 0) ||
          (this.tmppackingarray[removeIndex].loos == 0 && this.tmppackingarray[removeIndex].box == 0) ||
          (this.tmppackingarray[removeIndex].punnet == 0 && this.tmppackingarray[removeIndex].box == 0) ||
          (this.tmppackingarray[removeIndex].punnet == 0 && this.tmppackingarray[removeIndex].loos == 0)) {
          //this.tmppackingarray.splice(removeIndex, 1);
          if (pkid == 1) {
            this.tmppackingarray[removeIndex].box = 0
          }
          if (pkid == 2) {
            this.tmppackingarray[removeIndex].loos = 0
          }
          if (pkid == 3) {
            this.tmppackingarray[removeIndex].punnet = 0
          }
          //debugger
        } else {
          if (pkid == 1) {
            this.tmppackingarray[removeIndex].box = 0
          }
          if (pkid == 2) {
            this.tmppackingarray[removeIndex].loos = 0
          }
          if (pkid == 3) {
            this.tmppackingarray[removeIndex].punnet = 0
          }


        }

      }

      //  let removeIndex = this.tmppackingarray.findIndex(itm => Number(itm.breedid) != 0 ? Number(itm.breedid) : this.cropid === (Number(breedid) != 0) ? Number(breedid) : this.cropid);
      // if (removeIndex !== -1)
      // this.tmppackingarray.splice(removeIndex, 1);
      console.log(this.tmppackingarray);
      debugger;
    }


  }

  tabChange(evn) {
    let nextId = evn.nextId;
    let activeId = evn.activeId;
    let data = {
      growerid: this.userid,
      name: this.registergrowerForm.value.name,
      companyname: this.registergrowerForm.value.companyname,
      email: this.registergrowerForm.value.email,
      phone: this.registergrowerForm.value.phone,
      postcode: this.registergrowerForm.value.postcode,
      stateid: this.registergrowerForm.value.stateid,
      suburbid: this.registergrowerForm.value.suburbid,
      growing_environment_id: this.growing_environment_id,//v
      growing_medium_id: this.growing_medium_id,//v
      availability_produce_id: this.availability_produce_id,//v
      sell_interstate: this.sell_interstate,//v
      storage: this.storage,//v
      croparray: this.tmppackingarray,//v
      certificatearray: '',
    };

    // this.twoli = true;
    // this.threeli = true;
    debugger;
    if (evn == 1) {
      if (this.registergrowerForm.invalid) {
        this.toastr.error('All filed are required.');
        return;
      }
      this.active = 1;
    } else if (evn == 2) {
      // if (data.croparray == 0) {
      //   this.toastr.error('Select Crop information');
      //   return;
      // }

      this.active = 2;
    } else if (evn == 3) {
      debugger
      if (data.sell_interstate === 0 || data.sell_interstate === 1) {

      } else {
        this.toastr.error('Select filed');
        return;
      }
      this.active = 3;
    } else if (evn == 0) {
      this.active = 0;
    }

    // if (!data.name || !data.email || !data.postcode || !data.stateid || !data.suburbid) {
    //   this.toastr.error('All filed are required.');
    // }else{
    //  // this.twoli=false;
    // }

  }


  modelopen(content, val) {
    this.sell_interstate = val
    debugger
    this.modalService.open(content, { centered: true });

  }
  modelnoopen(val) {
    this.sell_interstate = val
    debugger
  }
  modelmsgsend() {
    // this.sell_interstate = val
    debugger

    if (this.messagetxt) {
      let data = {
        message: this.messagetxt,
        fromid: this.userid,
        toid: this.id,
        status: 0,
      }
      this.postservice.sendmessage(data)
        .subscribe((data: any) => {
          console.log(data);
          debugger
          this.modalService.dismissAll();
          this.cdr.detectChanges();
        });
    }
  }

  openmodelbreed(content) {
    this.modalService.open(content, { centered: true });
  }

  addcrop(iscrop) {
    if (iscrop == true) {
      this.iscrop = false;
      this.getcropinfos();
    } else {
      this.iscrop = true;

    }

  }

  modelbreedopen(content, id, $event) {
    this.tmppackingarray = [];
    let checked = $event.target.checked;
    this.cropid = id

    let data = {
      id: id,
      growerid: this.userid
    };
    this.postservice.getidcropbreed(data)
      .subscribe((data: any) => {
        console.log(data);
        if (data.success.length > 0) {

          for (let i = 0; i < data.success.length; i++) {
            const growerpkid = data.success[i].growerpkid;
            if (growerpkid != 0) {
              let cropList =
              {
                growerid: data.success[i].growerid,
                cropid: data.success[i].growerpkid,
                breedid: data.success[i].id,
                box: Number(data.success[i].Box),
                loos: Number(data.success[i].Loose),
                punnet: Number(data.success[i].Punnet),
              };
              this.tmppackingarray.push(cropList);
            }

          }

        }

        this.breedlist = data.success;
        this.isbreedSpinner = false;
        this.cdr.detectChanges();

      });
    this.modalService.open(content, { centered: true });


  }

  checkedval(id, pkid) {
    debugger
    if (pkid == 1) {
      let Index = this.tmppackingarray.findIndex(itm => Number(itm.breedid) == id && Number(itm.boxid) == pkid);
      if (Index !== -1) {
        return true
      }
    } else if (pkid == 1) {
      let Index = this.tmppackingarray.findIndex(itm => Number(itm.breedid) == id && Number(itm.loos) == pkid);
      if (Index !== -1) {
        return true
      }

    } else if (pkid == 1) {
      let Index = this.tmppackingarray.findIndex(itm => Number(itm.breedid) == id && Number(itm.punnet) == pkid);
      if (Index !== -1) {
        return true
      }

    }



    // for (let i = 0; i < this.tmppackingarray.length; i++) {
    //   const breedid = this.tmppackingarray[i].breedid;
    //   const boxid = this.tmppackingarray[i].box;
    //   const loosid = this.tmppackingarray[i].loos;
    //   const punnetid = this.tmppackingarray[i].punnet;
    //   let box = pkid === 1 ? pkid : 0;
    //   let loos = pkid === 2 ? pkid : 0;
    //   let punnet = pkid === 3 ? pkid : 0;

    //   if (breedid == id && pkid == boxid) {
    //     return true
    //   } else if (breedid == id && pkid == loosid) {
    //     return true
    //   } else if (breedid == id && pkid == punnetid) {
    //     return true
    //   } else {
    //     return false
    //   }

    // }

  }

  savebreed(iscrop) {
    this.issavecropSpinner = true;
    this.tmppackingarray
    let data = {
      croparray: this.tmppackingarray
    }
    this.postservice.savecrope(data)
      .subscribe((data: any) => {
        console.log(data);
        debugger
        this.issavecropSpinner = false;
        this.modalService.dismissAll();
        this.cdr.detectChanges();
      });

  }

  closebreed() {
    this.modalService.dismissAll();

    // let removeIndex = this.tmppackingarray.findIndex(itm => Number(itm.cropid) == this.cropid);
    // if (removeIndex !== -1)
    //   this.tmppackingarray.splice(removeIndex, 1);

    // console.log(this.tmppackingarray)
    //debugger
  }




  async onSubmit() {
    this.isSpinner = true;
    let data = {
      growerid: this.userid,
      name: this.registergrowerForm.value.name,
      companyname: this.registergrowerForm.value.companyname,
      email: this.registergrowerForm.value.email,
      phone: this.registergrowerForm.value.phone,
      postcode: this.registergrowerForm.value.postcode,
      stateid: this.registergrowerForm.value.stateid,
      cityid: this.registergrowerForm.value.cityid,
      suburbid: this.registergrowerForm.value.suburbid,
      retai_whole: this.registergrowerForm.value.retai_whole,
      sell_interstate: this.sell_interstate,//v

    };

    console.log(data);
    //debugger
    await this.buyercertificate();
    await this.buyerstateadd();
    await this.postservice.buyerregistration(data)
      .subscribe((data: any) => {
        console.log(data);
        this.isSpinner = false;
        if (data.status == true) {
          this.isEdit = false;
          this.active = 0;
          this.ngOnInit();
          this.toastr.success(data.message);
        }
      });


  }
  buyercertificate() {
    this.isSpinner = true;
    for (var i = 0; i < this.growercertificate.length; i++) {
      let data = {
        userid: this.userid,
        certificateid: this.growercertificate[i]['crtiid'],
        chkvalue: this.growercertificate[i]['chkvalue']
      }
      this.postservice.addbuyercertificate(data)
        .subscribe((data: any) => {
          console.log(data);
          this.isSpinner = false;
          this.active = 0;
          //this.ngOnInit();
          debugger;
        });
    }
  }
  buyerstateadd() {
    // this.isSpinner = true;
    for (var i = 0; i < this.stateselectedarray.length; i++) {
      let data = {
        userid: this.userid,
        stateid: this.stateselectedarray[i]['stateid'],
        chkvalue: this.stateselectedarray[i]['chkvalue']
      }
      this.postservice.addbuyerstate(data)
        .subscribe((data: any) => {
          console.log(data);
          // this.isSpinner = false;
          //this.active = 0;
          //this.ngOnInit();
          // debugger;
        });
    }
  }

  getproduct() {
    let data = {
      id: this.id
    }
    this.postservice.getproductlist(data)
      .subscribe((data: any) => {
        console.log(data);
        this.productlist = data.success;
      });
  }

}
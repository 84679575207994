import { GrowersubscriptionComponent } from './grower/growersubscription/growersubscription.component';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { RouterModule } from '@angular/router';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { ScrollToModule } from '@nicky-lenaers/ngx-scroll-to';
import { NgbNavModule } from '@ng-bootstrap/ng-bootstrap';
import { SwiperModule } from 'ngx-swiper-wrapper';
import { SWIPER_CONFIG } from 'ngx-swiper-wrapper';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';
import { NgxTypedJsModule } from 'ngx-typed-js';
import { NgxYoutubePlayerModule } from 'ngx-youtube-player';
import { FlatpickrModule } from 'angularx-flatpickr';
import { CountToModule } from 'angular-count-to';
import { NgxMasonryModule } from 'ngx-masonry';
import { ScrollspyDirective } from './shared/scrollspy.directive'

import { LoginComponent } from './auth/login/login.component';
import { RegistrationComponent } from './auth/registration/registration.component';
import { MasterPageComponent } from './core/components/master-page/master-page.component';
import { AuthCoverLoginComponent } from './auth/auth-cover-login/auth-cover-login.component';
import { AuthCoverRePasswordComponent } from './auth/auth-cover-re-password/auth-cover-re-password.component';
import { AuthCoverSignupComponent } from './auth/auth-cover-signup/auth-cover-signup.component';
import { AuthLoginComponent } from './auth/auth-login/auth-login.component';
import { AuthLoginThreeComponent } from './auth/auth-login-three/auth-login-three.component';
import { AuthRePasswordComponent } from './auth/auth-re-password/auth-re-password.component';
import { AuthRePasswordThreeComponent } from './auth/auth-re-password-three/auth-re-password-three.component';
import { AuthSignupComponent } from './auth/auth-signup/auth-signup.component';
import { AuthSignupThreeComponent } from './auth/auth-signup-three/auth-signup-three.component';
import { EmailPasswordResetComponent } from './email/email-password-reset/email-password-reset.component';
import { IndexServicesComponent } from './core/components/index-services/index-services.component';
import { PageAboutusComponent } from './core/components/page-aboutus/page-aboutus.component';
import { PageComingsoonComponent } from './core/components/page-comingsoon/page-comingsoon.component';
import { PageContactOneComponent } from './core/components/page-contact-one/page-contact-one.component';
import { PageErrorComponent } from './core/components/page-error/page-error.component';
import { PageMaintenanceComponent } from './core/components/page-maintenance/page-maintenance.component';
import { PagePrivacyComponent } from './core/components/page-privacy/page-privacy.component';
import { PageTermsComponent } from './core/components/page-terms/page-terms.component';
import { FeatherModule } from 'angular-feather';

import { allIcons } from 'angular-feather/icons';
import { HeaderComponent } from './shared/header/header.component';
import { FooterComponent } from './shared/footer/footer.component';
import { SwitcherComponent } from './shared/switcher/switcher.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HttpClientModule } from '@angular/common/http';
import { AuthService } from './service/auth.service';
//New Addes code
import { ToastrModule, ToastNoAnimation, ToastNoAnimationModule } from 'ngx-toastr';
import { FullregistrationComponent } from './auth/fullregistration/fullregistration.component';
import { SearchComponent } from './core/components/search/search.component';
import { CertificateComponent } from './master/certificate/certificate.component';
import { PostService } from './service/post.service';
import { CropComponent } from './master/crop/crop.component';
import { BreedComponent } from './master/breed/breed.component';
import { PackingComponent } from './master/packing/packing.component';
import { SubscriptionComponent } from './master/subscription/subscription.component';
import { Growing_environmentComponent } from './master/growing_environment/growing_environment.component';
import { StripComponent } from './master/strip/strip.component';
import { Growing_mediumComponent } from './master/growing_medium/growing_medium.component';
import { Availability_produceComponent } from './master/availability_produce/availability_produce.component';
// Import the stripe library
import { NgxStripeModule } from 'ngx-stripe';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { GrowerregistrationComponent } from './grower/growerregistration/growerregistration.component';
import { BuyerregistrationComponent } from './buyer/buyerregistration/buyerregistration.component';
import { VendorprofileComponent } from './vendor/vendorprofile/vendorprofile.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from 'src/environments/environment';
import { VendorlistComponent } from './vendor/vendorlist/vendorlist.component';
import { BuyerlistComponent } from './buyer/buyerlist/buyerlist.component';
import { GrowerlistComponent } from './grower/growerlist/growerlist.component';
import { DashboardComponent } from './dashboard/dashboard.component';
 
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { SerarchprofileComponent } from './serarchprofile/serarchprofile.component';
import { ChatComponent } from './chat/chat.component';
import { SuburbComponent } from './master/suburb/suburb.component';


const DEFAULT_SWIPER_CONFIG: SwiperConfigInterface = {
  direction: 'horizontal',
  slidesPerView: 'auto'
};

@NgModule({
  declarations: [			
    AppComponent,
    HeaderComponent,
    FooterComponent,
    LoginComponent,
    RegistrationComponent,
    MasterPageComponent,
    AuthCoverLoginComponent,
    AuthCoverRePasswordComponent,
    AuthCoverSignupComponent,
    AuthLoginComponent,
    AuthLoginThreeComponent,
    AuthRePasswordComponent,
    AuthRePasswordThreeComponent,
    AuthSignupComponent,
    AuthSignupThreeComponent,
    EmailPasswordResetComponent,
    IndexServicesComponent,
    PageAboutusComponent,
    PageComingsoonComponent,
    PageContactOneComponent,
    PageErrorComponent,
    PageMaintenanceComponent,
    PagePrivacyComponent,
    PageTermsComponent,
    SwitcherComponent,
    ScrollspyDirective,
    FullregistrationComponent,//new Added
    SearchComponent,//new Added
    CertificateComponent,//new Added
    CropComponent,//new Added
    BreedComponent,//new Added
    PackingComponent,//new Added
    SubscriptionComponent, //new Added
    Growing_environmentComponent,//new Added
    Growing_mediumComponent, //new Added
    Availability_produceComponent, //new Added
    StripComponent, //new Added
    GrowersubscriptionComponent,//new Added
    GrowerregistrationComponent,//new Added
    BuyerregistrationComponent,//new Added
    VendorprofileComponent,//new Added
    VendorlistComponent,//new Added
    BuyerlistComponent,//new Added
    GrowerlistComponent,//new Added
    DashboardComponent,
    SerarchprofileComponent,//new Added
    ChatComponent, SuburbComponent,//new Added
   ],
  imports: [
    InfiniteScrollModule,
    NgxStripeModule.forRoot("pk_test_51I6cSNBYpgSDE2ab3W9jz9BTa55VEylsbhLX21GSgBsgIkzNU2LrPFQeSQesedvE0U8z4el3t7uLP9CnVUfRDG9V00Ehn9r0a6"),
    Ng2SearchPipeModule,
    // NgxStripeModule.forRoot("pk_test_nDR7IWEIGLp4a1SBtqKH5eyg"),
    ToastNoAnimationModule.forRoot(),
    ToastrModule.forRoot({
      positionClass: 'toast-bottom-right',
    }), // ToastrModule added
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    RouterModule,
    CarouselModule,
    FeatherModule.pick(allIcons),
    ScrollToModule.forRoot(),
    RouterModule.forRoot([], { relativeLinkResolution: 'legacy' }),
    NgxYoutubePlayerModule,
    NgbModule,
    NgbNavModule,
    FormsModule,
    ReactiveFormsModule,
    SwiperModule,
    NgxTypedJsModule,
    FlatpickrModule.forRoot(),
    CountToModule,
    HttpClientModule,
    NgxMasonryModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),
    
  ],
  exports: [
    FeatherModule,
    ScrollspyDirective
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
  providers: [
    AuthService,
    PostService,
    {
      provide: SWIPER_CONFIG,
      useValue: DEFAULT_SWIPER_CONFIG
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  StripeService,
  StripeCardComponent
} from 'ngx-stripe';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import {
  StripeElementsOptions,
  PaymentRequestPaymentMethodEvent,
  PaymentIntent,
  PaymentRequestShippingAddressEvent,
  StripeCardElementOptions,
} from '@stripe/stripe-js';
import { PostService } from 'src/app/service/post.service';

@Component({
  selector: 'app-strip',
  templateUrl: './strip.component.html',
  styleUrls: ['./strip.component.css']
})
export class StripComponent implements OnInit {
  paymentForm: FormGroup;
  stripeCardValid: boolean = false;
  @Output() IsstripObj = new EventEmitter<any>();

  @ViewChild(StripeCardComponent) card: StripeCardComponent;

  cardOptions: StripeCardElementOptions = {
    style: {
      base: {
        iconColor: '#666EE8',
        color: '#31325F',
        fontWeight: 300,
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSize: '18px',
        '::placeholder': {
          color: '#CFD7E0'
        }
      }
    }
  };

  get validForm() {
    return this.paymentForm.valid;
  }

  elementsOptions: StripeElementsOptions = {
    locale: 'en'
  };
  paymentRequestOptions = {
    country: 'IN',
    currency: 'inr',
    total: {
      label: 'Demo Total',
      amount: 1099,
    },
    requestPayerName: true,
    requestPayerEmail: true,
  };

  constructor(
    private http: HttpClient,
    private fb: FormBuilder,
    private stripeService: StripeService,
    public postservice: PostService
  ) { }
  handler:any = null;
  ngOnInit() {
    this.paymentForm = this.fb.group({
      name: ['']
    });
   // this.loadStripe()
  }

  onChange({ type, event }) {
    if (type === 'change') {
      this.stripeCardValid = event.complete;
    }
  }
  back(){
    this.IsstripObj.emit("");
  }

  buy() {
   
    this.stripeService
      .createToken(this.card.element)
      .subscribe((result) => {
        console.log(result);
        if (result.token) {
         // alert(result.token.id)
          // Use the token
          this.IsstripObj.emit(result.token.id);

          console.log(result.token.id);
        
         

        } else if (result.error) {
         // alert("result.error.message")
          // Error creating the token
          console.log(result.error.message);
        }
      });
      
     
  }
  
 

}

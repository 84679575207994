<!-- Hero Start -->
<section class="bg-profile d-table w-100 bg-primary" id="home"
  style="background: url('assets/images/account/bg.png') center center;">
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <div class="card public-profile border-0 rounded shadow" style="z-index: 1;">
          <div class="card-body">
            <div class="row align-items-center">
              <div class="col-lg-2 col-md-3 text-md-left text-center">
                <img src="assets/images/user/user.png" class="avatar avatar-large rounded-circle shadow d-block mx-auto"
                  alt="">
              </div>
              <!--end col-->

              <div class="col-lg-10 col-md-9">
                <div class="row align-items-end">
                  <div class="col-md-7 text-md-left text-center mt-4 mt-sm-0">
                    <h3 class="title mb-0">{{ name }}</h3>
                    <small class="text-muted h6 mr-2">
                      {{ isadmin }} <span *ngIf="isadmin==1">,</span> {{ isgrower }} <span *ngIf="isbuyer==1">,</span>
                      {{ isbuyer }} <span *ngIf="isvendor==1">,</span> {{ isvendor }}
                    </small>
                    <!-- <ul class="list-inline mb-0 mt-3">
                        <li class="list-inline-item mr-2"><a href="javascript:void(0)" class="text-muted"
                            title="Instagram">
                            <i-feather name="instagram" class="fea icon-sm mr-2"></i-feather>
                            krista_joseph
                          </a></li>
                        <li class="list-inline-item"><a href="javascript:void(0)" class="text-muted" title="Linkedin">
                            <i-feather name="linkedin" class="fea icon-sm mr-2"></i-feather>
                            Krista Joseph
                          </a></li>
                      </ul> -->
                  </div>
                  <!--end col-->
                  <div class="col-md-5 text-md-right text-center">
                    <ul class="list-unstyled social-icon social mb-0 mt-4">
                      <li class="list-inline-item"><a routerLink="/profile" class="rounded" placement="bottom"
                          ngbTooltip="Profile">
                          <i-feather name="user" class="fea icon-sm fea-social"></i-feather>
                        </a></li>
                      <li class="list-inline-item ml-1"><a routerLink="/profile" class="rounded" placement="bottom"
                          ngbTooltip="Messages">
                          <i-feather name="message-circle" class="fea icon-sm fea-social"></i-feather>
                        </a></li>
                     
                      <li class="list-inline-item ml-1"><a routerLink="/growersubscription" class="rounded" placement="bottom"
                          ngbTooltip="Subscription">
                          <i-feather name="credit-card" class="fea icon-sm fea-social"></i-feather>
                        </a></li>
                      <li class="list-inline-item ml-1"><a routerLink="/profile" class="rounded"
                          placement="bottom" ngbTooltip="Settings">
                          <i-feather name="tool" class="fea icon-sm fea-social"></i-feather>
                        </a></li>
                    </ul>

                   
                  </div>

                </div>

              </div>

            </div>

          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--ed container-->
</section>
<!--end section-->
<!-- Hero End -->

<!-- Profile Start -->
<section class="section mt-60">
  <div class="container">
    <div class="row">

      <!--end col-->

      <div class="col-lg-12 col-12">
        <div class="card border-0 rounded shadow">

          <div class="border-bottom pb-4 card-body">
            <h5 style="float: left;"><span *ngIf="!issubactive"> Your subscription will expire on {{ subenddate | date: 'dd/MM/yyyy'}}</span></h5>


          </div>


          <div class="card-body" *ngIf="isEdit">
            <div class="row align-items-center">

              <div class="col-md-4 col-12 mt-4 pt-2" *ngFor="let item of subscriptionlist">
                <div [ngClass]="subscriptionid===item.id ? 'active' : ''" class="card  pricing-rates business-rate shadow bg-light rounded text-center border-0">
                  <div class="card-body py-5">
                    <h2 class="title text-uppercase mb-4">{{ item.name }}</h2>
                    <div class="d-flex justify-content-center mb-4">
                      <span class="h4 mb-0 mt-2">$</span>
                      <span class="price h1 mb-0">{{ item.amount }}</span>
                      <!-- <span class="h4 align-self-end mb-1">{{ item.month }}/mo</span> -->
                    </div>
                    <!-- <ul class="list-unstyled mb-0 pl-0">
                      <li class="h6 text-muted mb-0"><span class="text-primary h5 mr-2"><i
                            class="uim uim-check-circle"></i></span>{{ item.month }} Months</li>
                    </ul> -->
                    <a href="javascript:void(0)" [ngClass]="issubactive ? '' : 'disabled'" (click)="isedit(isEdit,item)" class="btn btn-primary mt-4">{{ subscriptionid===item.id?'Activated ':'Subscription' }}</a>
                  </div>
                </div>
              </div>
              <!--end col-->

              <!--end col-->
            </div>

          </div>

          <div class="card-body" *ngIf="!isEdit">
            <app-strip (IsstripObj)="stripdata($event)"></app-strip>
      
          </div>

        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- Profile Setting End -->



<!-- Back to top -->
<a href="javascript: void(0);" class="btn btn-icon btn-soft-primary back-to-top" id="back-to-top"
  [ngxScrollTo]="'#home'">
  <i-feather name="arrow-up" class="icons"></i-feather>
</a>
<!-- Back to top -->
import { StorageService } from './../../service/storage.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/service/auth.service';
import { ToastrService } from 'ngx-toastr';
import { PostService } from 'src/app/service/post.service';
@Component({
  selector: 'app-growersubscription',
  templateUrl: './growersubscription.component.html',
  styleUrls: ['./growersubscription.component.scss']
})
export class GrowersubscriptionComponent implements OnInit {
  [x: string]: any;
  navClass = 'nav-light';
  userid: any;
  name: any;
  isadmin: any;
  isgrower: any;
  isbuyer: any;
  isvendor: any;
  subscriptionlist: any = [];
  isEdit = true;
  stripselectdata: any;
  substartdate:any;
  subenddate:any
  subscriptionid:any
  issubactive =true;
  constructor(private formBuilder: FormBuilder, private _authService: AuthService, private _router: Router, private toastr: ToastrService, public activatedRoute: ActivatedRoute, public storageService: StorageService, public postservice: PostService) { }

  ngOnInit() {
    this.getsubscription();
    this.storageService.get('logininfo').then(
      (res) => {
        console.log(res);
        this.userid = res.id;
        if (res.id) {
          this.getactiveplan();
          this.name = res.username;
          this.isadmin = res.admin == 1 ? 'Admin' : '';
          this.isgrower = res.grower == 1 ? 'Grower' : '';
          this.isbuyer = res.buyer == 1 ? 'Buyer' : '';
          this.isvendor = res.vendor == 1 ? 'Vender' : '';

        }
      }
    )

    

  }
  getactiveplan(){
    let val ={
      id:this.userid
    }
    this.postservice.getactivesubscriptionplan(val)
    .subscribe((data: any) => {
      if(data){
      let activeplan = data.success
      this.substartdate = activeplan.startdate
      this.subenddate =activeplan.enddate
      this.subscriptionid =activeplan.subscriptionid
      this.issubactive=false;
     
      console.log(data);
      }
    
     //debugger  
    })
  }
  getsubscription() {

    this.postservice.getsubscription()
      .subscribe((data: any) => {
        console.log(data);
        this.subscriptionlist = data.success;
      });
  }
  isedit(val, item) {
    this.stripselectdata = item;
    if (val == true) {
      this.isEdit = false;
    } else {
      this.isEdit = true;
    }

  }
  stripdata($event) {
    console.log($event);

    if ($event) {
      this.striptoken = $event;
      this.pay();
    } else {
      this.isEdit = true;
    }

  }
  back() {
    this.isEdit = true;
  }

  pay() {
    console.log(this.stripselectdata);
    let month = this.stripselectdata.month;


    var d = new Date();
    var datestring = d.getFullYear() + "-"+ (d.getMonth() + 1)  + "-"+ d.getDate(); 
    var e = new Date(d.setMonth(d.getMonth()+month));
    var expdate = e.getFullYear() + "-"+ (e.getMonth() + 1)  + "-"+ e.getDate(); 

    debugger
    let data = {
      growerid: this.userid,
      stripamount: Number(this.stripselectdata.amount) * 100,
      striptoken: this.striptoken,
      startdate: datestring,
      subscriptionid:this.stripselectdata.id,
      enddate:expdate 
    }
    //subscriptionamount
   
    console.log(data)
    
    this.postservice.sendstriprequest(data)
    .subscribe((data: any) => {
      console.log(data);
      this.toastr.success('Subscription ', data.message);

      debugger  
    })
    debugger

  }
  
}

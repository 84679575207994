<!-- Hero Start -->

<!--end section-->
<!-- Hero End -->

<!-- Company Detail Start -->
<section class="section">
    <div class="container">
        <!--Search-->
        <div *ngIf="!IsAll" class="row justify-content-center">
            <div class="col-12 text-center">
                <div class="section-title mb-4 pb-2">
                    <h1 class="heading mb-3">
                        <ngx-typed-js [strings]="['Grower', 'Buyer', 'Vendor']" [shuffle]="true" [typeSpeed]="80" [loop]="true">
                            Search for <span class="element text-primary typing"></span>
                        </ngx-typed-js>
                    </h1>
                    <!-- <p class="para-desc mx-auto text-muted">Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p> -->
                    <div class="rounded p-4 mt-4 bg-white">
                        <div class="row text-left">
                            <div class="col-lg-5 col-md-4">
                                <!-- <div class="form-group mb-0">
                  <input type="text" class="form-control" required placeholder="Keywords">
                </div> -->

                                <div class="form-group mt-4 mb-0">
                                    <select class="form-control custom-select" [(ngModel)]="usertype">
                                      <option value="">Type</option>
                                      <option *ngIf="isbuyer || isvendor" value="1">Grower</option>
                                      <option *ngIf="isgrower || isvendor" value="2">Buyer</option>
                                      <option *ngIf="!isvendor" value="3">Vendor</option>
                                      <option *ngIf="isgrower || isvendor" value="4">Wholesaler</option>
                                    </select>
                                </div>

                            </div>
                            <!--end col-->

                            <div class="col-lg-7 col-md-8">
                                <div class="row align-items-center">

                                    <div class="col-md-8 mt-4">
                                        <div class="form-group mb-0">
                                            <select [(ngModel)]="stateval" class="form-control custom-select">
                        <option value="" selected="">State</option>
                        <option *ngFor="let item of statelist" value="{{ item.id }}">{{ item.statename }}</option>

                      </select>
                                        </div>
                                    </div>

                                    <div class="col-md-4 mt-4">
                                        <input type="submit" id="search" (click)="searchpage()" name="search" class="searchbtn btn btn-primary btn-block p" value="Search">
                                    </div>
                                    <!--end col-->
                                </div>
                                <!--end row-->
                            </div>
                            <!---end col-->
                        </div>
                        <!--end row-->
                    </div>
                </div>
            </div>
            <!--end col-->
        </div>
        <!--End Search-->

        <!-- <div class="flex-shrink-0 mb-md-0 text-left" *ngIf="!IsAll">
      <h4 class="mb-0 text-left">Dashboard</h4>
    </div> -->
        <div class="row text-center" *ngIf="!IsAll">
            <div class="spinner-border m-auto" *ngIf="IsSpinnerall" role="status">
                <span class="sr-only">Loading...</span>
            </div>
            <div class="w-100">
                <div class="col-lg-12">
                    <div class="row dashboard-section">

                        <div *ngIf="isbuyer==1 ||isvendor==1" class="col-lg-3 col-sm-6 mt-3" (click)="showhideGrower(IsGrower,1)">
                            <a href="javascript: void(0);" class="text-dark">
                                <div class="key-feature p-3 rounded shadow">
                                    <div class="content">
                                        <h3 class="mb-0">{{ growerscount }}</h3>
                                        <p class="mb-0">Total Grower's</p>
                                    </div>
                                </div>
                            </a>
                        </div>
                        <div *ngIf="isgrower==1 || isvendor==1" class="col-lg-3 col-sm-6 mt-3" (click)="showhideGrower(IsGrowerRailsWhol,6)">
                            <a href="javascript: void(0);" class="text-dark">
                                <div class="key-feature p-3 rounded shadow">
                                    <div class="content">
                                        <h3 class="mb-0">{{ wholecount }}</h3>
                                        <p class="mb-0">Total Wholesaler</p>
                                    </div>
                                </div>
                            </a>
                        </div>
                        <div *ngIf="isgrower==1 || isvendor==1" class="col-lg-3 col-sm-6 mt-3" (click)="showhideGrower(IsGrowerRailsWhol,7)">
                            <a href="javascript: void(0);" class="text-dark">
                                <div class="key-feature p-3 rounded shadow">
                                    <div class="content">
                                        <h3 class="mb-0">{{ ratilscount }}</h3>
                                        <p class="mb-0">Total Retails</p>
                                    </div>
                                </div>
                            </a>
                        </div>

                        <div *ngIf="isgrower==1" class="col-lg-3 col-sm-6 mt-3" (click)="showhideGrower(IsStateGrower,2)">
                            <a href="javascript: void(0);" class="text-dark">
                                <div class="key-feature p-3 rounded shadow">
                                    <div class="content">
                                        <h3 class="mb-0">{{ staategrowerscount }}</h3>
                                        <p class="mb-0">Total State Market Listed</p>
                                    </div>
                                </div>
                            </a>
                        </div>
                        <div *ngIf="isbuyer==1 || isvendor==1" class="col-lg-3 col-sm-6 mt-3" (click)="showhideGrower(IsStateGrower,2)">
                            <a href="javascript: void(0);" class="text-dark">
                                <div class="key-feature p-3 rounded shadow">
                                    <div class="content">
                                        <h3 class="mb-0">{{ staategrowerscount }}</h3>
                                        <p class="mb-0">Total State wise Grower's</p>
                                    </div>
                                </div>
                            </a>
                        </div>

                        <div *ngIf="isbuyer==1" class="col-lg-3 col-sm-6 mt-3" (click)="showhideGrower(IsStateGrower,3)">
                            <a href="javascript: void(0);" class="text-dark">
                                <div class="key-feature p-3 rounded shadow">
                                    <div class="content">
                                        <h3 class="mb-0">{{ productscount }}</h3>
                                        <p class="mb-0">Total Produce</p>
                                    </div>
                                </div>
                            </a>
                        </div>
                        <div *ngIf="!isvendor" class="col-lg-3 col-sm-6 mt-3" (click)="showhideGrower(IsGrowing,4)">
                            <a href="javascript: void(0);" class="text-dark">
                                <div class="key-feature p-3 rounded shadow">
                                    <div class="content">
                                        <h3 class="mb-0">{{ vendercount }}</h3>
                                        <p class="mb-0">Total Vendors </p>
                                    </div>
                                </div>
                            </a>
                        </div>
                        <!-- <div class="col-lg-3 col-sm-6 mt-3" (click)="showhideGrower(IsGrowing,4)">
              <a href="javascript: void(0);" class="text-dark">
                <div class="key-feature p-3 rounded shadow">
                  <div class="content">
                    <h3 class="mb-0">Growing</h3>
                    <p class="mb-0">List of Growing </p>
                  </div>
                </div>
              </a>
            </div> -->
                        <!-- <div class="col-lg-3 col-sm-6 mt-3" *ngFor="let item of seasonalwhole">
              <a href="javascript: void(0);" (click)="showhideGrowersw(IsGrowersw,item.id,1)" class="text-dark">
                <div class="key-feature p-3 rounded shadow">
                  <div class="content">
                    <h3 class="mb-0"> {{item.couts}} </h3>
                    <p class="mb-0">Total {{item.name}} Grower</p>
                  </div>
                </div>
              </a>
            </div> -->

                    </div>
                </div>

                <div *ngIf="isbuyer==1" class="col-lg-12">
                    <h4 class="mt-lg-5 mt-4 text-left">Certificate :</h4>

                    <div class="row Certificate">
                        <div class="col-lg-3 col-sm-6 mt-3" *ngFor="let item of certificatelist">
                            <a href="javascript: void(0);" (click)="showhideGrowersw(IsCertificate,item.certificateid,2)" class="text-dark">
                                <div class="key-feature p-3 rounded shadow">
                                    <div class="content">
                                        <h3 class="mb-0">{{ item.count }}</h3>
                                        <p class="text-muted mb-0">{{ item.name }}</p>
                                    </div>
                                </div>
                            </a>
                        </div>
                    </div>

                </div>
            </div>
        </div>
        <!--TAbles-->
        <div *ngIf="IsGrower">
            <div class="card-body">
                <div class="row tablelist" style="overflow: auto;">
                    <h5 class="col-lg-8">Grower list:</h5>
                    <div class="col-lg-4 search">
                        <div class="form-group mb-0">
                            <input type="text" [(ngModel)]="searchText" autocomplete="off" class="form-control" required placeholder="Search">
                        </div>
                    </div>
                    <div class="col-md-12 mt-2">
                        <div class="card">
                            <div class="card-body">
                                <ng-container *ngIf="( bgrowerlist_all | filter : searchText) as result">

                                    <div class="daynamicdata" *ngFor="let item of result | filter:searchText;">
                                        <div class="row">
                                            <div class="col-lg-4">
                                                <div class="d-flex">
                                                    <div class="mr-3">
                                                        <img src="{{ item.image?item.image:'assets/images/user/user.png' }}" alt="" width="66">
                                                    </div>
                                                    <div>
                                                        <h3 class="m-0">{{ item.name }} </h3>
                                                        <p class="m-0"> <i class="uil uil-suitcase-alt mr-2"> </i>{{item.companyname}}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-4">
                                                <p class="m-0"> <i class="uil uil-building mr-2"></i>{{item.statename}}</p>
                                                <p class="m-0"> <i class="uil uil-map mr-2"> </i>{{item.cityname}}</p>
                                            </div>
                                            <div class="col-lg-4">
                                                <p class="m-0"> <i class="uil uil-map-pin-alt mr-2"></i>{{item.postcode}}</p>
                                            </div>
                                            <div class="col-lg-12">
                                                <hr>
                                            </div>
                                        </div>
                                    </div>
                                    <p *ngIf="result.length === 0" style="text-align: center;">No Data Available</p>
                                </ng-container>
                            </div>
                        </div>

                        <!-- <table class="table table-striped">
              <thead class="thead-light">
                <tr>
                  <th class="text-nowrap" scope="col">Name</th>
                  <th class="text-nowrap" scope="col">Company Name</th>
                  <th class="text-nowrap" scope="col">State</th>
                  <th class="text-nowrap" scope="col">City</th>
                  <th class="text-nowrap" scope="col">Postcode</th>
                </tr>
              </thead>
              <tbody>
                <tr class="bg-white" *ngFor="let item of masterlist; index as i">
                  <td class="text-nowrap"> <strong>{{item.name}}</strong></td>
                  <td class="text-nowrap"> {{item.companyname}} </td>
                  <td class="text-nowrap"> {{item.statename}} </td>
                  <td class="text-nowrap"> {{item.cityname}} </td>
                  <td class="text-nowrap"> {{item.postcode}} </td>

                </tr>
              </tbody>
            </table>
            <div class="spinner-border" style="text-align: center;" *ngIf="isSpinnerlist" role="status">
              <span class="sr-only">Loading...</span>
            </div>
   

            <div class="d-flex justify-content-between p-2">
              <ngb-pagination [collectionSize]="collectionSize" [(page)]="page" [pageSize]="pageSize"
                (pageChange)="refreshCountries()">
              </ngb-pagination>

              <select class="custom-select" style="width: auto" [(ngModel)]="pageSize"
                (ngModelChange)="refreshCountries()">
                <option [ngValue]="10">10 items per page</option>
                <option [ngValue]="20">20 items per page</option>
                <option [ngValue]="30">30 items per page</option>
              </select>
            </div> -->
                        <div class="d-flex justify-content-between p-2">
                            <ngb-pagination [(page)]="bgpage" [pageSize]="bgpageSize" [collectionSize]="bgrowerlistcount" (pageChange)="refreshbuyergrowerlist()" [maxSize]="5" [rotate]="true" [boundaryLinks]="true"></ngb-pagination>

                            <select class="custom-select" style="width: auto" [(ngModel)]="bgpageSize" (ngModelChange)="refreshbuyergrowerlist()">
                    <option [ngValue]="2">2 items per page</option>
        
                    <option [ngValue]="4">4 items per page</option>
                    <option [ngValue]="6">6 items per page</option>
                </select>
                        </div>

                        <a (click)="back()" style="float: right;" class="btn btn-primary mt-2"> Back </a>
                    </div>
                    <!--end col-->
                </div>
            </div>
        </div>

        <div *ngIf="IsStateGrower">
            <div class="card-body">
                <div class="row" style="overflow: auto;">
                    <h5 class="ml-3">State wise Grower's list:</h5>
                    <div class="striped-data col-md-12 mt-2">
                        <table class="table table-striped">
                            <thead class="thead-light">
                                <tr>
                                    <th class="text-nowrap" scope="col">State Name</th>
                                    <th class="text-nowrap" scope="col">Total Grower's</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr class="bg-white" *ngFor="let item of statewiselist_growlist; index as i">
                                    <td class="text-nowrap"> <strong>{{item.statename}}</strong></td>
                                    <td class="text-nowrap"> {{item.gcount}} </td>
                                </tr>
                            </tbody>
                        </table>
                        <div class="spinner-border" style="text-align: center;" *ngIf="isSpinnerlist" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                        <p *ngIf="staategrowerscount==0" style="text-align: center;">No Data Available</p>

                        <div class="d-flex justify-content-between p-2">
                            <ngb-pagination [(page)]="spage" [pageSize]="spageSize" [collectionSize]="staategrowerscount" (pageChange)="refreshstatewiselist()" [maxSize]="5" [rotate]="true" [boundaryLinks]="true"></ngb-pagination>

                            <select class="custom-select" style="width: auto" [(ngModel)]="spageSize" (ngModelChange)="refreshstatewiselist()">
                <option [ngValue]="2">2 items per page</option>
                <option [ngValue]="3">3 items per page</option>
                <option [ngValue]="4">4 items per page</option>
              </select>
                        </div>
                        <a (click)="back()" style="float: right;" class="btn btn-primary"> Back </a>
                    </div>
                    <!--end col-->
                </div>
            </div>
        </div>

        <div *ngIf="IsProduce">
            <div class="card-body">
                <div class="row" style="overflow: auto;">
                    <h5 class="ml-3">Produce list:</h5>
                    <div class="striped-data col-md-12 mt-2">
                        <table class="table table-striped">
                            <thead class="thead-light">
                                <tr>
                                    <th class="text-nowrap" scope="col">Crop Name</th>
                                    <th class="text-nowrap" scope="col">Breed Name</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr class="bg-white" *ngFor="let item of growerwisecroplist; index as i">
                                    <td class="text-nowrap"> <strong>{{item.cropname}}</strong></td>
                                    <td class="text-nowrap"> {{item.breedname}} </td>
                                </tr>
                            </tbody>
                        </table>
                        <div class="spinner-border" style="text-align: center;" *ngIf="isSpinnerlist" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                        <p *ngIf="productscount==0" style="text-align: center;">No Data Available</p>

                        <div class="d-flex justify-content-between p-2">
                            <ngb-pagination [(page)]="propage" [pageSize]="propageSize" [collectionSize]="productscount" (pageChange)="refreshCrop()" [maxSize]="5" [rotate]="true" [boundaryLinks]="true"></ngb-pagination>

                            <select class="custom-select" style="width: auto" [(ngModel)]="propageSize" (ngModelChange)="refreshCrop()">
                                <option [ngValue]="2">2 items per page</option>
                                <option [ngValue]="3">3 items per page</option>
                                <option [ngValue]="4">4 items per page</option>
              </select>
                        </div>
                        <a (click)="back()" style="float: right;" class="btn btn-primary"> Back </a>
                    </div>
                    <!--end col-->
                </div>
            </div>
        </div>

        <div *ngIf="IsGrowing">
            <div class="card-body">
                <div class="row tablelist" style="overflow: auto;">
                    <h5 class="col-lg-8">Vendor list:</h5>
                    <div class="col-lg-4 search">
                        <div class="form-group mb-0">
                            <input type="text" [(ngModel)]="searchText" autocomplete="off" class="form-control" required placeholder="Search">
                        </div>
                    </div>
                    <div class="col-md-12 mt-2">
                        <div class="card">
                            <div class="card-body">
                                <ng-container *ngIf="( vendorlist | filter : searchText) as result">

                                    <div class="daynamicdata" *ngFor="let item of result">
                                        <div class="row">
                                            <div class="col-lg-4">
                                                <div class="d-flex">
                                                    <div class="mr-3">
                                                        <img src="{{ item.image?item.image:'assets/images/user/user.png' }}" alt="" width="66">
                                                    </div>
                                                    <div>
                                                        <h3 class="m-0">{{ item.name }}</h3>
                                                        <p class="m-0"> <i class="uil uil-suitcase-alt mr-2"> </i>{{item.companyname}}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-4">
                                                <p class="m-0"> <i class="uil uil-building mr-2"></i>{{item.statename}}</p>
                                                <p class="m-0"> <i class="uil uil-map mr-2"> </i>{{item.cityname}}</p>
                                            </div>
                                            <div class="col-lg-4">
                                                <p class="m-0"> <i class="uil uil-map-pin-alt mr-2"></i>{{item.postcode}}</p>
                                            </div>
                                            <div class="col-lg-12">
                                                <hr>
                                            </div>
                                        </div>
                                    </div>
                                    <p *ngIf="result.length === 0" style="text-align: center;">No Result Available</p>
                                </ng-container>
                            </div>
                        </div>
                        <div class="d-flex justify-content-between p-2">
                            <ngb-pagination [(page)]="vpage" [pageSize]="vpageSize" [collectionSize]="vendercount" (pageChange)="refreshvendorlist()" [maxSize]="5" [rotate]="true" [boundaryLinks]="true"></ngb-pagination>

                            <select class="custom-select" style="width: auto" [(ngModel)]="vpageSize" (ngModelChange)="refreshvendorlist()">
                                <option [ngValue]="2">2 items per page</option>
                                <option [ngValue]="4">4 items per page</option>
                                <option [ngValue]="6">6 items per page</option>
                            </select>
                        </div>
                        <a (click)="back()" style="float: right;" class="btn btn-primary mt-2"> Back </a>
                    </div>
                    <!--end col-->
                </div>
            </div>
        </div>

        <div *ngIf="IsGrowersw">

            <div class="card-body">
                <div class="row" style="overflow: auto;">
                    <h5 class="ml-3">Grower list:</h5>
                    <div class="striped-data col-md-12 mt-2">
                        <table class="table table-striped">
                            <thead class="thead-light">
                                <tr>
                                    <th class="text-nowrap" scope="col">Name</th>
                                    <th class="text-nowrap" scope="col">State</th>
                                    <th class="text-nowrap" scope="col">City</th>
                                    <th class="text-nowrap" scope="col">Postcode</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr class="bg-white" *ngFor="let item of growingswlist; index as i">
                                    <td class="text-nowrap"> <strong>{{item.name}}</strong></td>
                                    <td class="text-nowrap"> {{item.statename}} </td>
                                    <td class="text-nowrap"> {{item.cityname}} </td>
                                    <td class="text-nowrap"> {{item.postcode}} </td>

                                </tr>
                            </tbody>
                        </table>
                        <div class="spinner-border" style="text-align: center;" *ngIf="isSpinnerlist" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                        <p *ngIf="collectionSizesw==0" style="text-align: center;">No Data Available</p>

                        <div class="d-flex justify-content-between p-2">
                            <ngb-pagination [(page)]="page" [pageSize]="pageSize" [collectionSize]="collectionSizesw" (pageChange)="refreshseawhol()" [maxSize]="5" [rotate]="true" [boundaryLinks]="true"></ngb-pagination>

                            <select class="custom-select" style="width: auto" [(ngModel)]="pageSize" (ngModelChange)="refreshseawhol()">
                <option [ngValue]="10">10 items per page</option>
                <option [ngValue]="20">20 items per page</option>
                <option [ngValue]="30">30 items per page</option>
              </select>
                        </div>
                        <a (click)="back()" style="float: right;" class="btn btn-primary"> Back </a>
                    </div>
                    <!--end col-->
                </div>
            </div>
        </div>
        <div *ngIf="IsCertificate">
            <div class="card-body">
                <div class="row tablelist" style="overflow: auto;">
                    <h5 class="col-lg-8">Grower list:</h5>
                    <div class="col-lg-4 search">
                        <div class="form-group mb-0">
                            <input type="text" [(ngModel)]="searchText" autocomplete="off" class="form-control" required placeholder="Search">
                        </div>
                    </div>
                    <div class="col-md-12 mt-2">
                        <div class="card">
                            <div class="card-body">
                                <div class="daynamicdata" *ngFor="let item of crtificateg | filter:searchText;">
                                    <div class="row">
                                        <div class="col-lg-4">
                                            <div class="d-flex">
                                                <div class="mr-3">
                                                    <img src="{{ item.image?item.image:'assets/images/user/user.png' }}" alt="" width="66">
                                                </div>
                                                <div>
                                                    <h3 class="m-0">{{ item.username }}</h3>
                                                    <p class="m-0"> <i class="uil uil-suitcase-alt mr-2"> </i>{{item.companyname}}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-4">
                                            <p class="m-0"> <i class="uil uil-building mr-2"></i>{{item.statename}}</p>
                                            <p class="m-0"> <i class="uil uil-map mr-2"> </i>{{item.cityname}}</p>
                                        </div>
                                        <div class="col-lg-4">
                                            <p class="m-0"> <i class="uil uil-map-pin-alt mr-2"></i>{{item.postcode}}</p>
                                        </div>
                                        <div class="col-lg-12">
                                            <hr>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <p *ngIf="collectionSize==0" style="text-align: center;">No Data Available</p>
                        <!-- <table class="table table-striped">
              <thead class="thead-light">
                <tr>
                  <th class="text-nowrap" scope="col">Name</th>
                  <th class="text-nowrap" scope="col">Company Name</th>
                  <th class="text-nowrap" scope="col">State</th>
                  <th class="text-nowrap" scope="col">City</th>
                  <th class="text-nowrap" scope="col">Postcode</th>
                </tr>
              </thead>
              <tbody>
                <tr class="bg-white" *ngFor="let item of masterlist; index as i">
                  <td class="text-nowrap"> <strong>{{item.name}}</strong></td>
                  <td class="text-nowrap"> {{item.companyname}} </td>
                  <td class="text-nowrap"> {{item.statename}} </td>
                  <td class="text-nowrap"> {{item.cityname}} </td>
                  <td class="text-nowrap"> {{item.postcode}} </td>

                </tr>
              </tbody>
            </table>
            <div class="spinner-border" style="text-align: center;" *ngIf="isSpinnerlist" role="status">
              <span class="sr-only">Loading...</span>
            </div>
   

            <div class="d-flex justify-content-between p-2">
              <ngb-pagination [collectionSize]="collectionSize" [(page)]="page" [pageSize]="pageSize"
                (pageChange)="refreshCountries()">
              </ngb-pagination>

              <select class="custom-select" style="width: auto" [(ngModel)]="pageSize"
                (ngModelChange)="refreshCountries()">
                <option [ngValue]="10">10 items per page</option>
                <option [ngValue]="20">20 items per page</option>
                <option [ngValue]="30">30 items per page</option>
              </select>
            </div> -->
                        <a (click)="back()" style="float: right;" class="btn btn-primary mt-2"> Back </a>
                    </div>
                    <!--end col-->
                </div>
            </div>
        </div>
        <div *ngIf="IsGrowerRailsWhol">

            <div class="card-body">
                <div class="row tablelist" style="overflow: auto;">
                    <h5 class="col-lg-8">Grower list:</h5>
                    <div class="col-lg-4 search">
                        <div class="form-group mb-0">
                            <input type="text" [(ngModel)]="searchText" autocomplete="off" class="form-control" required placeholder="Search">
                        </div>
                    </div>
                    <div class="col-md-12 mt-2">
                        <div class="card">
                            <div class="card-body" *ngIf="IsGrowerRails">
                                <ng-container *ngIf="( reails_whollist | filter : searchText) as result">
                                    <div class="daynamicdata" *ngFor="let item of result | filter:searchText;">
                                        <div class="row" *ngIf="item.retai_whole==0">
                                            <div class="col-lg-4">
                                                <div class="d-flex">
                                                    <div class="mr-3">
                                                        <img src="{{ item.image?item.image:'assets/images/user/user.png' }}" alt="" width="66">
                                                    </div>
                                                    <div>
                                                        <h3 class="m-0">{{ item.name }}</h3>
                                                        <p class="m-0"> <i class="uil uil-suitcase-alt mr-2"> </i>{{item.companyname}}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-4">
                                                <p class="m-0"> <i class="uil uil-building mr-2"></i>{{item.statename}}</p>
                                                <p class="m-0"> <i class="uil uil-map mr-2"> </i>{{item.cityname}}</p>
                                            </div>
                                            <div class="col-lg-4">
                                                <p class="m-0"> <i class="uil uil-map-pin-alt mr-2"></i>{{item.postcode}}</p>
                                            </div>
                                            <div class="col-lg-12">
                                                <hr>
                                            </div>
                                        </div>
                                    </div>
                                    <p *ngIf="result.length === 0" style="text-align: center;">No Data Available</p>
                                </ng-container>
                            </div>
                            <div class="card-body" *ngIf="IsGrowerWhole">
                                <ng-container *ngIf="( wholesales_whollist | filter : searchText) as result">

                                    <div class="daynamicdata" *ngFor="let item of result | filter:searchText;">
                                        <div class="row" *ngIf="item.retai_whole==1">
                                            <div class="col-lg-4">
                                                <div class="d-flex">
                                                    <div class="mr-3">
                                                        <img src="{{ item.image?item.image:'assets/images/user/user.png' }}" alt="" width="66">
                                                    </div>
                                                    <div>
                                                        <h3 class="m-0">{{ item.name }}</h3>
                                                        <p class="m-0"> <i class="uil uil-suitcase-alt mr-2"> </i>{{item.companyname}}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-4">
                                                <p class="m-0"> <i class="uil uil-building mr-2"></i>{{item.statename}}</p>
                                                <p class="m-0"> <i class="uil uil-map mr-2"> </i>{{item.cityname}}</p>
                                            </div>
                                            <div class="col-lg-4">
                                                <p class="m-0"> <i class="uil uil-map-pin-alt mr-2"></i>{{item.postcode}}</p>
                                            </div>
                                            <div class="col-lg-12">
                                                <hr>
                                            </div>
                                        </div>
                                    </div>
                                    <p *ngIf="result.length === 0" style="text-align: center;">No Data Available</p>
                                </ng-container>
                            </div>
                        </div>


                        <div class="d-flex justify-content-between p-2" *ngIf="IsGrowerRails">
                            <ngb-pagination [(page)]="rpage" [pageSize]="rpageSize" [collectionSize]="retailerscount" (pageChange)="refreshgrowerrailslist()" [maxSize]="5" [rotate]="true" [boundaryLinks]="true"></ngb-pagination>
                            <select class="custom-select" style="width: auto" [(ngModel)]="rpageSize" (ngModelChange)="refreshgrowerrailslist()">
                                <option [ngValue]="2">2 items per page</option>
                                <option [ngValue]="4">4 items per page</option>
                                <option [ngValue]="6">6 items per page</option>
                                </select>
                        </div>

                        <div class="d-flex justify-content-between p-2" *ngIf="IsGrowerWhole">
                            <ngb-pagination [(page)]="wpage" [pageSize]="wpageSize" [collectionSize]="wholeslistcount" (pageChange)="refreshgrowerwholelist()" [maxSize]="5" [rotate]="true" [boundaryLinks]="true"></ngb-pagination>

                            <select class="custom-select" style="width: auto" [(ngModel)]="wpageSize" (ngModelChange)="refreshgrowerwholelist()">
                                <option [ngValue]="2">2 items per page</option>
                    
                                <option [ngValue]="4">4 items per page</option>
                                <option [ngValue]="6">6 items per page</option>
                            </select>
                        </div>

                        <a (click)="back()" style="float: right;" class="btn btn-primary mt-2"> Back </a>
                    </div>
                    <!--end col-->
                </div>
            </div>
        </div>
    </div>



</section>

<!-- Back to top -->
<a href="javascript: void(0);" class="btn btn-icon btn-soft-primary back-to-top" id="back-to-top" [ngxScrollTo]="'#home'">
    <i-feather name="arrow-up" class="icons"></i-feather>
</a>
<!-- Back to top -->
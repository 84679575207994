import { Component, OnInit, PipeTransform } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/service/auth.service';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { PostService } from 'src/app/service/post.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'app-breed',
  templateUrl: './breed.component.html',
  styleUrls: ['./breed.component.css']
})
export class BreedComponent implements OnInit {
  navClass = 'nav-light';
  id: any;
  //Form code
  breedForm: FormGroup;
  submitted = false;
  isSpinner = false;
  isEdit = false;
  isactivecheck: false;
  isSpinnerlist = true;
  //Table code
  page = 1;
  pageSize = 4;
  collectionSize = 0;
  masterlist: any = [];
  allmasterlist: any = [];
  //end
  //Other
  croplist: any = [];
  deletid: any;
  constructor(private formBuilder: FormBuilder, private _authService: AuthService, private _router: Router, private toastr: ToastrService, public activatedRoute: ActivatedRoute, public postservice: PostService,private modalService: NgbModal) {

  }
  refreshCountries() {
    this.masterlist = this.allmasterlist
      .map((country, i) => ({ id: i + 1, ...country }))
      .slice((this.page - 1) * this.pageSize, (this.page - 1) * this.pageSize + this.pageSize);
  }

  ngOnInit(): void {
    this.breedForm = this.formBuilder.group({
      breedname: ['', Validators.required],
      cropid: ['', Validators.required],
      isactive: ['1'],
    });
    this.getlist();
    this.getcroplist();
  }
  get f() { return this.breedForm.controls; }
  open(content, id) {
    this.deletid = id;
    this.modalService.open(content, { centered: true });
  }
  deleteval() {
    this.modalService.dismissAll();

    this.postservice.breeddestroy(this.deletid)
    .subscribe((data: any) => {
      console.log(data);
      this.getlist();
      this.toastr.success('Breed', data.message);

    })
  }

  isedit(val) {
    if (val == true) {
      this.isEdit = false;
    } else {
      this.isEdit = true;
    }

  }
  getcroplist() {
    this.postservice.getcrop()
      .subscribe((data: any) => {
        console.log(data);
        this.croplist = data.success;
      });
  }
  getlist() {
    this.postservice.getbreed()
      .subscribe((data: any) => {
        console.log(data);
        this.isSpinnerlist = false;
        //this.certificatelist = data.success;
        this.collectionSize = data.success.length;
        this.allmasterlist = data.success;
        this.masterlist = data.success
          .map((country, i) => ({ id: i + 1, ...country }))
          .slice((this.page - 1) * this.pageSize, (this.page - 1) * this.pageSize + this.pageSize);
      });
  }


  onSubmit() {
    // stop here if form is invalid
    this.submitted = true;
    if (this.breedForm.invalid) {
      return;
    }
    // return false;
    this.isSpinner = true;
    this.postservice.breedAdd(this.breedForm.value)
      .subscribe((data: any) => {
        console.log(data);
        this.isSpinner = false;
        if (data.status == true) {
          this.submitted = false;
          this.ngOnInit();
          this.toastr.success('Breed', data.message);
        } else {
          this.toastr.error('Breed', data.message);

        }
      }, error => {
        this.isSpinner = false;
        this.toastr.error('Breed', 'Please try again later.');
      })

    // display form values on success


  }
}
<!-- Start Products -->
<section class="section">
    <div class="container">
        <div class="row">
            <div [ngClass]="!IsFilter ? 'd-block' : ''" class="dashboardview col-lg-3 col-md-4 col-12">
                <div class="card border-0 sidebar sticky-bar">
                    <div class="card-body p-0">
                        <!-- SEARCH -->
                        <div class="widget">
                            <div id="search2" class="widget-search mb-0">
                                <h4 class="widget-title">FILTERS</h4>
                            </div>
                        </div>
                        <!-- SEARCH -->

                        <!-- CATAGORIES -->
                        <div class="widget mb-4 pt-2">
                            <h4 class="widget-title">States</h4>
                            <ul class="list-unstyled mt-2 mb-0 blog-catagories">
                                <li *ngFor="let item of statelist"><a class="d-flex justify-content-between" href="jvascript:void(0)"><span class="d-flex align-items-center"><input class="mr-2" id="state_{{item.id}}" type="checkbox"
                  (change)="filter($event,item.id,1)">{{ item.statename }}</span><span class="text-right">{{ item.gcount }}</span></a></li>

                            </ul>
                        </div>
                        <div class="widget mb-4" *ngIf="this.urlid==='1' || this.urlid==='2'">
                            <h4 class="widget-title">Produce</h4>
                            <ul class="list-unstyled mt-2 mb-0 blog-catagories">
                                <li *ngFor="let item of croplist"><a class="d-flex justify-content-between" href="jvascript:void(0)"><span class="d-flex align-items-center"><input class="mr-2" type="checkbox"
                  (change)="modelbreedopen(breedcontent, item.id,$event)">{{ item.cropname }}</span>
                  <!-- <span class="text-right">{{ item.counts }}</span> -->
                </a>
                                </li>
                            </ul>
                        </div>
                        <div class="widget mb-4" *ngIf="this.urlid==='1'">
                            <h4 class="widget-title">Certificates</h4>
                            <ul class="list-unstyled mt-2 mb-0 blog-catagories">
                                <li *ngFor="let item of certilist"><a class="d-flex justify-content-between" href="jvascript:void(0)"><span class="d-flex align-items-center"><input class="mr-2" type="checkbox"
                  (change)="filter($event,item.id,2)">{{ item.certificate }}</span><span class="text-right">{{ item.counts }}</span></a></li>
                            </ul>
                        </div>
                        <div class="widget mb-4" *ngIf="this.urlid==='1'">
                            <h4 class="widget-title">Growing Types</h4>
                            <ul class="list-unstyled mt-2 mb-0 blog-catagories">
                                <li *ngFor="let item of gmlist"><a class="d-flex justify-content-between" href="jvascript:void(0)"><span class="d-flex align-items-center"><input class="mr-2" type="checkbox"
                  (change)="filter($event,item,3)">{{ item.name }}</span><span class="text-right">{{ item.counts }}</span></a></li>

                            </ul>
                        </div>
                        <div class="widget mb-4" *ngIf="this.urlid==='1'">
                            <h4 class="widget-title">Availability produce</h4>
                            <ul class="list-unstyled mt-2 mb-0 blog-catagories">
                                <li *ngFor="let item of avplist"><a class="d-flex justify-content-between" href="jvascript:void(0)"><span class="d-flex align-items-center"><input class="mr-2" type="checkbox"
                  (change)="filter($event,item.id,4)">{{ item.name }}</span><span class="text-right">{{ item.counts }}</span></a></li>

                            </ul>
                        </div>
                        <div class="widget mb-4" *ngIf="this.urlid==='2'">
                            <h4 class="widget-title">Buyer type</h4>
                            <ul class="list-unstyled mt-2 mb-0 blog-catagories">
                                <li><a class="d-flex justify-content-between" href="jvascript:void(0)"><span class="d-flex align-items-center"><input class="mr-2" type="checkbox"
                  (change)="filter($event,0,5)" (click)="retailecheck($event.target.checked)">Retailers</span><span class="text-right">{{ ratailcount }}</span></a></li>
                                <li><a class="d-flex justify-content-between" href="jvascript:void(0)"><span class="d-flex align-items-center"><input class="mr-2" type="checkbox"
                    (change)="filter($event,1,5)" (click)="wholesalercheck($event.target.checked)" id="wholesellerId" [checked]="wholesallerchecked">Wholesaler</span><span class="text-right">{{ wholecount }}</span></a></li>

                            </ul>
                        </div>


                    </div>
                </div>
            </div>
            <!--end col-->

            <div *ngIf="IsFilter" class="col-lg-9 col-md-8 col-12 pt-2 mt-sm-0 pt-sm-0">
                <div class="row align-items-center">
                    <div class="col-lg-7 col-md-7 mb-2 mt-2">
                        <div class="section-title">
                            <h5 class="mb-0 loginbtn d-flex">Showing Totals {{ masterlist.length }} results
                                <div class="ml-2">
                                    <div class="spinner-border m-auto" *ngIf="IsSpinnerall" role="status">
                                        <span class="sr-only">Loading...</span>
                                    </div>
                                </div>
                            </h5>

                        </div>
                    </div>
                    <!--end col-->
                    <div class="col-lg-5 col-md-5 mt-sm-0 pt-2 pt-sm-0">
                        <div class="form-group d-flex form-group mb-0 align-items-center">
                            <a (click)="isback()" style="float: right;" class="btn btn-primary btn-sm mr-3">Back </a>
                            <input type="text" autocomplete="off" [(ngModel)]="searchText" class="form-control" required placeholder="Search">
                        </div>
                    </div>
                    <!--end col-->
                </div>
                <!--end row-->
                <ng-container *ngIf="( masterlist | filter : searchText) as result">
                    <div class="clear searchlisting mt-3" *ngFor="let item of result | filter:searchText;">
                        <div class="card w-100">
                            <div class="card-body">
                                <div class="daynamicdata">
                                    <div class="row">
                                        <div class="col-lg-8">
                                            <div class="d-flex">
                                                <div class="mr-3 mt-2">
                                                    <img src="{{ item.image?item.image:userpic }}" alt="" width="130"></div>
                                                <div>
                                                    <h3 class="m-0">{{ item.name }}</h3>
                                                    <p class="m-0"> <i class="uil uil-suitcase-alt mr-2"> </i>{{item.companyname}}</p>
                                                    <p class="m-0"> <i class="uil uil-building mr-2"></i>{{item.statename}}</p>
                                                    <p class="m-0"> <i class="uil uil-map mr-2"> </i>{{item.cityname}}</p>
                                                    <p class="m-0"> <i class="uil uil-map-pin-alt mr-2"></i>{{item.postcode}}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 text-right">
                                            <input type="submit" (click)="goprofilepage(item.id)" class="searchbtn btn btn-outline-primary  btn-sm" value="View details">
                                        </div>
                                    </div>
                                </div>
                                <p *ngIf="result.length === 0" style="text-align: center;">No Data Available</p>

                            </div>
                        </div>
                    </div>
                </ng-container>
                <!--end row-->
            </div>
            <!--end col-->
        </div>
        <!--end row-->
    </div>
    <div class="bottomfixed">
        <!-- <button type="submit" (click)="filterhide(IsFilter)" class="btn btn-primary">FILTER</button> -->
        <div class="row" *ngIf="!IsFilter">
            <div class="col-6 pr-0">
                <button type="submit" (click)="filterhide(IsFilter)" class="btn btn-primary">CLOSE</button>
            </div>
            <div class="col-6 pl-0">
                <button type="submit" (click)="getallfilter()" class="btn btn-primary">APPLY</button>
            </div>
        </div>
        <div class="row" *ngIf="IsFilter">
            <div class="col-12 pr-0">
                <button type="submit" (click)="filterhide(IsFilter)" class="btn btn-primary"><i class="uil uil-filter mr-2"></i>FILTER</button>
            </div>
        </div>
    </div>
    <!--end container-->
</section>
<!--end section-->
<!-- End Products -->
<!-- ModalBreed Content Start -->
<ng-template #breedcontent let-modal>
    <div class="modal-header">
        <h5 class="modal-title" id="modal-basic-title">Breed</h5>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
    </div>
    <div class="modal-body">
        <div class="bg-white rounded box-shadow">
            <div class="spinner-border" *ngIf="isbreedSpinner" role="status">
                <span class="sr-only">Loading...</span>
            </div>
            <table class="table">
                <thead class="thead-light">
                    <tr>
                        <th scope="col">Breed Name</th>
                        <th style="text-align: center;" *ngFor="let pk of packingarray ; let i=index;" scope="col">{{ pk.name }}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <div *ngIf="breedlist.length>0;then content else other_content">here is ignored</div>
                    <ng-template #content>
                        <tr *ngFor="let item of breedlist">
                            <th scope="row">{{ item.breedname }}</th>
                            <td style="text-align: center;"> <input class="chkcropinput" (change)="packingselect($event,item.id,1)" type="checkbox"></td>
                            <td style="text-align: center;"> <input class="chkcropinput" (change)="packingselect($event,item.id,2)" type="checkbox"></td>
                            <td style="text-align: center;"> <input class="chkcropinput" (change)="packingselect($event,item.id,3)" type="checkbox"></td>
                        </tr>
                    </ng-template>
                    <ng-template #other_content>
                        <tr>
                            <th scope="row">-</th>
                            <td style="text-align: center;"> <input class="chkcropinput" (change)="packingselect($event,0,1)" type="checkbox"></td>
                            <td style="text-align: center;"> <input class="chkcropinput" (change)="packingselect($event,0,2)" type="checkbox"></td>
                            <td style="text-align: center;"> <input class="chkcropinput" (change)="packingselect($event,0,3)" type="checkbox"></td>
                        </tr>
                    </ng-template>

                </tbody>
            </table>

        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline-dark" (click)="closebreed()">Cancel</button>
        <button type="button" class="btn btn-outline-primary d-flex align-items-center justify-content-center" (click)="savebreed(iscrop)">Search
      <div class="spinner-border" *ngIf="issavecropSpinner" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </button>

    </div>
</ng-template>
<!-- Modal Content End -->

<!-- Back to top -->
<a href="javascript: void(0);" class="btn btn-icon btn-soft-primary back-to-top" id="back-to-top" [ngxScrollTo]="'#home'">
    <i-feather name="arrow-up" class="icons"></i-feather>
</a>
<!-- Back to top -->
<div class="col-md-12" [formGroup]="paymentForm">
  <div class="form-group">
        <label>Name</label>
        <input class="form-control" type="text" formControlName="name">
      </div> 
  <div class="form-group">
    <ngx-stripe-card [options]="cardOptions" (on)="onChange($event)" [elementsOptions]="elementsOptions">
    </ngx-stripe-card>
  </div>
  <button class="btn btn-success" (click)="buy()" [disabled]="!validForm">Pay Now</button>
  <a href="javascript:void(0)" (click)="back()"  class="btn btn-muted ml-2">Back</a>

</div>
<!-- <ngx-stripe-card-group  [elementsOptions]="elementsOptions">
  <ngx-stripe-card-number [options]="cardOptions"></ngx-stripe-card-number>
  <ngx-stripe-card-expiry [options]="cardOptions"></ngx-stripe-card-expiry>
  <ngx-stripe-card-cvc [options]="cardOptions"></ngx-stripe-card-cvc>
  <button type="submit" (click)="buy()">
    PAY
  </button>
</ngx-stripe-card-group> -->